<template>
    <div>
      <navbar-component></navbar-component>
        <div class="cs_rate" >
            <el-card style="max-width: 600px;" v-loading="loading">
                <div v-if="recipient">
                    <div v-if="recipient.cs_rating_status == 0">
                        <p style="color: #003300;">
                            Click the button below to open the Citizen/Client Satisfaction form.
                        </p>
                    </div>
                    <div v-if="recipient.cs_rating_status == 1">
                        <p style="color: #003300;">
                            Click the button below if you are done with the Citizen/Client Satisfaction form.
                        </p>
                    </div>
                    <div v-if="recipient.cs_rating_status == 2">
                        <p style="color: #003300; font-weight: bold;">
                            Citizen/Client Satisfaction form submitted! You may now proceed with claiming your requested documents.</p>
                        <p style="font-size: medium;">
                        Thank you for taking the time to complete the survey. 
                        Your feedback is valuable and helps us improve our services. </p>
                        <el-button type="primary" style="width: 100%; cursor: pointer;" @click="goTo('/')" icon="el-icon-caret-left"> Go Back</el-button>
                    </div>
                    
                    <div>
                        <el-button :loading="btnLoading" v-if="recipient.cs_rating_status == 0" @click="redirectToCSForm()" style="width: 100%; font-size:20px; box-shadow: #6062667a 4px 4px 7px 0px;" type="success" icon="el-icon-s-flag">{{!btnLoading ? 'Take the survey' : 'In progress...'}}</el-button>
                        <el-button v-if="recipient.cs_rating_status == 1" @click="doneWithCSForm()" style="width: 100%; font-size:20px; box-shadow: #6062667a 4px 4px 7px 0px;" type="success" icon="el-icon-s-flag">Finish</el-button>
                        <br><br>
                        <span v-if="recipient.cs_rating_status == 1 || btnLoading" style="font-size: 12px; font-style: italic;">If you are not redirected automatically to the Citizen/Client Satisfaction Form, please click this link: 
                            <a style="cursor: pointer; text-decoration: underline; margin-top: 15px;" class="link_button" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdWayEIeHsQwWAg45DlavFcK6mJH9eM10-zQ5vRMKkiszFOwQ/viewform?usp=pp_url&entry.147586094=Baybay&entry.1144483737=Registrar">Citizen/Client Satisfaction Form 
                            </a>
                        </span>
                    </div>
                </div>
                <div v-if="!recipient">
                    <el-alert
                        title="Invalid link for Citizen/Client Satisfaction form!"
                        type="warning"
                        description="Please contact Office of the University Registrar for assistance."
                        :closable=false
                        show-icon>
                    </el-alert>
                </div>
            </el-card>
            
        </div>
    </div>
</template>
  
<script>
import NavbarComponent from '@/components/common/NavbarComponent.vue'
import { Recipient } from '@/api_services/recipient'

export default {
    name: 'CSRateComponent',
    components: {
        NavbarComponent
    },
    data() {
        return {
            loading: true,
            btnLoading: false,
            recipient: null,
            form:{ 
                request_code: null
            },
        };
    },
    created: function() {
        this.checkLink()
        this.form.request_code = this.$router.currentRoute.query.recipient_rqst_code
    },
    methods: {
        checkLink(){
            // this.loading = !this.$router.currentRoute.query.recipient_rqst_code ? true : false 
            Recipient.findRecipientCode({recipient_rqst_code: this.$router.currentRoute.query.recipient_rqst_code}).then(result => {
                this.loading = false
                // console.log(result.data.data)
                this.recipient = result.data.data
            })
            .catch( error => {
                this.loading = false
                this.recipient = null
                console.log(error)
            })
        },
        redirectToCSForm(){
            this.btnLoading = true
            this.$alert('When you are finished, return to this tab and click the "Finish" button.', {
                confirmButtonText: 'Proceed to Form',
                cancelButtonText: 'Cancel',
                showClose: false
            }).then(( ) => {
                this.newTabLoad()
                window.open('https://docs.google.com/forms/d/e/1FAIpQLSdWayEIeHsQwWAg45DlavFcK6mJH9eM10-zQ5vRMKkiszFOwQ/viewform?usp=pp_url&entry.147586094=Baybay&entry.1144483737=Registrar', '_blank');
            }).catch(error => {
                console.log(error)
            });

        },
        async newTabLoad(){
            await this.timeOut(15000)
            Recipient.surveyInProgress({recipient_rqst_code: this.$router.currentRoute.query.recipient_rqst_code}).then(result => {
                this.recipient = result.data.data
            })
            .catch( error => {
                console.log(error)
            })
            console.log("in progress: " + this.$router.currentRoute.query.recipient_rqst_code)
        },
        doneWithCSForm(){
            Recipient.surveyDone({recipient_rqst_code: this.$router.currentRoute.query.recipient_rqst_code}).then(result => {
                this.recipient = result.data.data
            })
            .catch( error => {
                console.log(error)
            })
            console.log("done: " + this.$router.currentRoute.query.recipient_rqst_code)
        },
        timeOut(time) {
            return new Promise(resolve => {
                setTimeout(resolve, time);
            });
        },
        goTo(path) {
            this.$router.push({path: path})
        },
    }
}
</script>

<style scoped>
.cs_rate{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 70px;
}
</style>
  
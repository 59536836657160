<template>
    <div>
      
      <div class="configuration">
        <el-card v-loading="loading" style="max-width: 600px; min-width: 400px;">
          <h2>Portal Configuration</h2>
          <el-form :model="configurationForm" class="demo-ruleForm">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="Close on weekends?">
                  <el-switch v-model="configurationForm.is_unavailable_weekends">
                  </el-switch>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="Close on holidays?">
                  <el-switch v-model="configurationForm.is_unavailable_holidays">
                  </el-switch>
                  <br>
                  <span style="font-size: 12px; color: #818181;">
                    <a style="font-size: inherit; color: #ffffff; 
                      background-color: #006e00; padding: 5px; 
                      cursor: pointer; border: 
                      solid 1px transparent; 
                      border-radius: 8px;
                      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);" 
                      @click="goTo('excludeddates')">
                      Update Excluded Dates / Holidays
                    </a> 
                  </span>
                </el-form-item>
                
              </el-col>
           
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="Maintenance?">
                  <el-switch v-model="configurationForm.is_on_maintenance">
                  </el-switch>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="Text indicated for regular unavailability:">
                  <el-input v-model="configurationForm.is_unavailable_text" type="text" placeholder="Daily TC/HD, CAV and TOR" style="width: 100%;"></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="Text indicated for maintenance:">
                  <el-input v-model="configurationForm.is_maintenance_text" type="text" placeholder="Daily TC/HD, CAV and TOR" style="width: 100%;"></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="Daily limit for TC/HD, CAV and TOR:">
                  <el-input v-model="configurationForm.tc_hd_cav_tor_daily_limit" type="number" placeholder="Daily TC/HD, CAV and TOR" style="width: 100%;"></el-input>
                </el-form-item>
              </el-col>
            
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="Daily limit for Certifications:" >
                  <el-input v-model="configurationForm.certification_daily_limit" type="number" placeholder="Daily TC/HD, CAV and TOR" style="width: 100%;"></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="Daily limit for Expedite:" >
                  <el-input v-model="configurationForm.expedite_daily_limit" type="number" placeholder="Expedite limit" style="width: 100%;"></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item>
                  <div style="text-align: center; margin-top: 20px;">
                    <el-button style="width: 100%;" type="primary" @click="saveSettings()">Update</el-button>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

        </el-card>
      </div>
    </div>
  </template>
    
    <script>
    import { Setting } from '@/api_services/setting'
    export default {
      name: 'PortalConfiguration',
      data() {
        return {
          form:{},
          configurationForm: {
            is_unavailable_weekends: null,
            is_unavailable_holidays: null,
            is_on_maintenance: null,
            is_unavailable_text: null,
            tc_hd_cav_tor_daily_limit: null,
            certification_daily_limit: null,
            expedite_daily_limit: null,
            is_maintenance_text: null
          },
          loading: false
        };
      },
      mounted() {

      },
      created: function() {
        this.getSettings()
      },
      methods: {
        getSettings() {
          this.loading = true

          Setting.getAllSetting({
          }).then((result) => {
            this.configurationForm.is_unavailable_weekends = result.data.is_unavailable_weekends == 1 ? true : false
            this.configurationForm.is_unavailable_holidays = result.data.is_unavailable_holidays == 1 ? true : false
            this.configurationForm.is_on_maintenance = result.data.is_on_maintenance == 1 ? true : false
            this.configurationForm.is_unavailable_text = result.data.is_unavailable_text
            this.configurationForm.tc_hd_cav_tor_daily_limit = result.data.tc_hd_cav_tor_daily_limit
            this.configurationForm.certification_daily_limit = result.data.certification_daily_limit
            this.configurationForm.is_maintenance_text = result.data.is_maintenance_text
            this.configurationForm.expedite_daily_limit = result.data.expedite_daily_limit
            this.loading = false
          }).catch((error) => {

          });
        },
        saveSettings(){
          this.$confirm('Are you sure you want to update ?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
              this.loading = true
              Setting.updateSettings(this.configurationForm)
                .then((response) => {
                  // this.$emit("saved", response.data.data);
                  this.$message({
                    type: 'success',
                    message: 'Configuration Saved!'
                  });
                  this.loading = false
                }).catch((error) => {
                  this.$message({
                    message: error.data,
                    type: "error",
                    duration: 3000,
                  });
                });
              
            }).catch(() => {
                  
            });
        },
        goTo(name) {
          this.$router.push({name: name})
        }
      }
    }
    </script>
    
    <style scoped>
    .configuration{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      margin-bottom: 70px;
    }
    </style>
    
    
    
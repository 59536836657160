<template>
    <div class="Configure" style="padding: 20px;">
      <el-card shadow="none">
        <h2>Fees</h2>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-card >
              <div>
                <el-row :gutter="20">
                  <h3 style="color: #007a00;">Documents</h3>
                  <el-table
                    v-loading="loading_doc"
                    :data="request_types"
                    style="width: 100%" size="mini">
                    <el-table-column
                      label="Document Request Name"
                      width="500">
                      <template slot-scope="scope">
                        <span><b>{{ scope.row.request_name }}</b></span><br>
                        <span><i style="color: rgb(123, 123, 123);">{{ scope.row.description }}</i></span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Fixed Rate"
                      prop="reg_fee">
                    </el-table-column>
                    <!-- <el-table-column
                      label="Per page fee"
                      prop="per_page_fee">
                    </el-table-column>
                    <el-table-column
                      label="Doc stamp"
                      prop="is_with_doc_stamp">
                    </el-table-column> -->
                    <el-table-column align="right">
                      <template slot="header">
                        <el-button
                          type="success"
                          size="mini"
                          @click="handleEditDocs(null)" icon="el-icon-plus"></el-button>
                      </template>
                      <template slot-scope="scope">
                        <el-button
                          type="warning"
                          size="mini"
                          @click="handleEditDocs(scope.row)" icon="el-icon-edit"></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-row>
              </div>
            </el-card><br>
            <el-card>
              <div>
                <el-row :gutter="20">
                  <h3 style="color: #007a00;">Others</h3>
                  <el-table
                    v-loading="loading_others"
                    :data="other_types"
                    style="width: 100%" size="mini">
                    <el-table-column
                      label="Other Fees"
                      prop="request_name"
                      width="300">
                    </el-table-column>
                    <el-table-column
                      label="Fee"
                      prop="reg_fee">
                    </el-table-column>
                    <el-table-column align="right">
                      <template slot="header">
                        <el-button
                          type="success"
                          size="mini"
                          @click="handleEditOthers(null)" icon="el-icon-plus"></el-button>
                      </template>
                      <template slot-scope="scope">
                        <el-button
                          type="warning"
                          size="mini"
                          @click="handleEditOthers(scope.row)" icon="el-icon-edit"></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-row>
              </div>
            </el-card><br>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-card>
              <div>
                <el-row :gutter="20">
                  <h3 style="color: #007a00;">Certificates</h3>
                  <el-table
                    v-loading="loading_cert"
                    :data="certification_types"
                    style="width: 100%" size="mini">
                    <el-table-column
                      label="Certificate Name"
                      prop="request_name"
                      width="500">
                    </el-table-column>
                    <el-table-column
                      label="Fixed Rate"
                      prop="reg_fee">
                    </el-table-column>
                    
                    <!-- <el-table-column
                      label="Per page fee"
                      prop="per_page_fee">
                    </el-table-column>
                    <el-table-column
                      label="Doc stamp"
                      prop="is_with_doc_stamp">
                    </el-table-column> -->
                    <el-table-column align="right">
                      <template slot="header">
                        <el-button
                          type="success"
                          size="mini"
                          @click="handleEditCert(null)" icon="el-icon-plus"></el-button>
                        </template>
                        <template slot-scope="scope">
                        <el-button
                          type="warning"
                          size="mini"
                          @click="handleEditCert(scope.row)" icon="el-icon-edit"></el-button>
                        </template>
                    </el-table-column>
                  </el-table>
                </el-row>
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
  
          </el-col>
        </el-row>

        <el-dialog title="Edit Fee" width="40%" :visible.sync="showDialogDoc" :destroy-on-close.sync="destroyOnClose" v-if="showDialogDoc">
          <edit-docfee-component @update="update" :selectedDoc="selectedDoc"></edit-docfee-component>
        </el-dialog>
      </el-card>
    </div>
  
  </template>
  
  <style>
    .right {
      text-align: right;
    }
  
    .add-btn {
      margin-bottom: 1rem;
    }
  </style>
  
  <script>
  
  import EditDocfeeComponent from './EditDocfeeComponent.vue';
  import { RequestType } from '@/api_services/requesttype'
  
  export default {
    components: {
        EditDocfeeComponent
    },
    name: 'Configure',
    data() {
      return {
        destroyOnClose: true,
        showDialogDoc: false,
        request_types: null,
        certification_types: null,
        other_types: null,
        selectedDoc: null,
        selectedCert: null,
        selectedOthers: null,
        loading_doc: false,
        loading_cert: false,
        loading_others: false,
      }
    },
    
    created() {
      this.getRequestTypes()
      this.getCertTypes()
      this.getStampTypes()
    },
    methods: {
      handleEditDocs(row) {
        this.showDialogDoc = true
        this.selectedDoc = Object.assign({}, row)
        // console.log(this.selectedDoc.request_name)
      },
      handleEditCert(row) {
        this.showDialogDoc = true
        this.selectedDoc = Object.assign({}, row)
        // console.log(this.selectedDoc.request_name)
      },
      handleEditOthers(row) {
        this.showDialogDoc = true
        this.selectedDoc = Object.assign({}, row)
        // console.log(this.selectedDoc.request_name)
      },
      handleDelete(index, row) {
        console.log(index, row);
      },
      addApplicantForm() {
        this.showDialog = true;
      },
      getRequestTypes() {
        this.loading_doc = true
        RequestType.get({
          params:{ all: true, doc_type: 'DOC' },
        })
        .then((result) => {
            this.loading_doc = false
            this.request_types = result.data.data
        })
        .catch((error) => {
            this.loading = false;
        });
      },
      getCertTypes() {
        this.loading_cert = true
        RequestType.get({
          params:{ all: true, doc_type: 'CERT' },
        })
        .then((result) => {
            this.loading_cert = false
            this.certification_types = result.data.data
        })
        .catch((error) => {
            this.loading_cert = false;
        });
      },
      getStampTypes() {
        this.loading_others = true
        RequestType.get({
          params:{ all: true, doc_type: 'others' },
        })
        .then((result) => {
          this.loading_others = false
            this.other_types = result.data.data
        })
        .catch((error) => {
            this.loading_others = false;
        });
      },
      update(){
        this.getRequestTypes()
        this.getCertTypes()
        this.getStampTypes()
      }
    }
  }
  </script>
  
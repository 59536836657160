<template>
  <div>
      <el-card>
        <span style="color: #009d00; font-weight: bolder;">Document Evaluation / Processing</span><br>
          <el-button style="float: right;" @click="refresh()" type="success" size="small" :loading="loading"><i v-if="!loading" class="el-icon-refresh"></i> Refresh</el-button><br><br>

        <el-table v-if="activeTab === 'third'" :data="recipients" :empty-text="empty" style="width: 100%" stripe border size="mini" v-loading="loading">
          <el-table-column
            label="ID"
            fixed
            width="60px"
            >
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed
            width="180px"
            label="Request Code">
            <template slot-scope="scope">
              <b style="font-size: 15px;">{{ scope.row.recipient_rqst_code }}</b><br><span style="padding: 1px 4px 1px 4px; background-color: green; color: white;" v-if="scope.row.is_expedite">expedite</span><br>
              <div style="color:#909399; margin-top: -5px">Date Payment Verified: <br></div><div style="color:#909399;margin-top: -10px"><b>{{ scope.row.verified_payment_track_date }}</b> ({{ scope.row.verified_payment_track_time}})</div>
            </template>
            
          </el-table-column>
          <el-table-column
            fixed
            label="Day(s) on Queue" width="80">
            <template slot-scope="scope">
              <div :style="{ 'background-color': getBackgroundColor(scope.row.recipient_payment.payment_date), 'text-align': 'center', 'color': 'white' }">
                {{ calculateDaysInQueue(scope.row.recipient_payment.payment_date) }}
              </div>
            </template>
            
          </el-table-column>
          <el-table-column
            fixed
            label="Day(s) on Process" width="80">
            <template slot-scope="scope">
              <div v-if="scope.row.date_start_process" :style="{ 'background-color': getBackgroundColor(scope.row.date_start_process), 'text-align': 'center', 'color': 'white' }">
                {{ scope.row.date_start_process ? calculateDaysInQueue(scope.row.date_start_process) : "Not yet on queue" }}
              </div>
            </template>
            
          </el-table-column>
          <el-table-column
            label="Request(s)">

            <template slot-scope="scope">
              <div v-for="(document_requests, index) in scope.row.document_requests" :key="index">
                <span v-if="document_requests.request_type.doc_type != 'EXPEDITE_FEE' ">
                  <li>  {{ document_requests.request_type.request_name }} <span v-if="document_requests.others_request_description">: {{ document_requests.others_request_description }}</span> ({{ document_requests.no_copy }}) 
                  </li>
                </span>
                <!-- <hr> -->
              </div>
            </template>
            
          </el-table-column>
          <el-table-column
            label="Name" width="180">
            <template slot-scope="scope">
              <b>{{ scope.row.last_name }}, {{ scope.row.first_name }} {{ scope.row.middle_name }} {{ scope.row.ext_name }}</b>
            </template>
          </el-table-column>
          <el-table-column
            label="Degree Program">
            <template slot-scope="scope">
              <span v-if="scope.row.course">{{ scope.row.course.code }} {{ !scope.row.course.major ? "" : "major in " + scope.row.course.major }}</span>
              <span v-if="!scope.row.course">No course selected</span>
            </template>
          </el-table-column>
          
          <el-table-column
            label="Total Bill"
            width="140px"
            >
            <template slot-scope="scope">
              <span>{{ formatCurrency(parseFloat(scope.row.recipient_payment.amount)) }}</span><br>
              <b>{{ scope.row.recipient_payment.payment_method ? scope.row.recipient_payment.payment_method : "Not selected yet"  }}</b><br>
              <span style="color: #909399; font-size: 10px;">OR: {{ scope.row.recipient_payment.or_number ? scope.row.recipient_payment.or_number : "---"}}</span>
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            label="Operations"
            width="200">
            <template slot-scope="scope">
              <el-button style="width: 100%; padding: 4px; margin-bottom: 5px;" @click="handleClick(scope.row)" type="warning" size="small"><i class="el-icon-view"></i> View</el-button><br>
              <div v-if="scope.row.processingBy" style="border: #009d00 solid 1px; padding-left: 2px; width: 100%; text-align: center;">
                <span style="font-size: 9px;"><i class="el-icon-s-custom"></i> <strong>{{ scope.row.processingBy.name }}</strong></span>
              </div>
              <el-button style="width: 100%; padding: 4px; margin-bottom: 5px;" v-if="!scope.row.processingBy" @click.native.prevent="handleAcceptClick(scope.row)" type="info" size="small"><i class="el-icon-s-promotion"></i>Start process</el-button><br>
              <el-button style="width: 100%; padding: 4px; margin-bottom: 5px;" v-if="scope.row.processingBy" @click.native.prevent="verify(scope.row)" type="success" size="small"><i class="el-icon-bell"></i> For release / Pick up</el-button><br>
              <el-button style="width: 100%; padding: 4px; margin-bottom: 5px;" v-if="!scope.row.processingBy" @click.native.prevent="revert(scope.row)" type="info" size="small"><i class="el-icon-caret-left"></i> Revert</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="justify-content: center; align-items: center;display: flex;">
          <el-pagination
            class="margin-top-20"
            background
            center
            layout="prev, pager, next"
            :total="meta.total"
            :page-size="meta.per_page"
            @current-change="pageChanged"
            :current-page="meta.page"
          >
          </el-pagination>
        </div>
        
        <el-dialog title="Request Details" width="75%" :visible.sync="showDialog" :destroy-on-close.sync="destroyOnClose" v-if="showDialog">
          <view-request-component @update="refresh()" :selected="selected"></view-request-component>
        </el-dialog>

      </el-card>
  </div>
</template>

<script>
import ViewRequestComponent from '@/views/private/pages/dashboard/ViewRequestComponent.vue';
import { Recipient } from '@/api_services/recipient';
import { ExcludedDate } from '@/api_services/excludeddate'
import moment from "moment";

export default {
  props: {
    activeTab: String,
  },
  name: 'Incomming',
  components: {
    ViewRequestComponent
  },
  data() {
    return {
      recipients: null,
      meta: {
        page: 1,
        pages: 1,
        total: 211,
        per_page: 15,
      },
      form:{},
      showDialog: false,
      selected: null,
      loading: false,
      destroyOnClose: true,
      empty: "No available request(s) for assessment in queue.",
      excluded_dates: null,
      holidays: []
      // showVerifyBtn: false
    };
  },
  created: function() {
    // this.getRecipients()
    // console.log(this.$store.state.savedUser.id)
  },
  watch: {
    activeTab(newTab, oldTab) {
      if (newTab === 'third') {
        this.loading = true
        this.meta.page = 1
        this.getRecipients();
      }
    },
  },
  methods: {
    refresh(){
      this.loading = true
      this.getRecipients();
    },
    formatCurrency(value) {
      return value.toLocaleString('en-PH', {
        style: 'currency',
        currency: 'PHP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    // calculate days without weekends and holidays
    calculateDaysInQueue(datePlaced) {
      const currentDate = new Date();
      const placedDate = new Date(datePlaced);
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const totalDaysInQueue = Math.floor((currentDate - placedDate) / millisecondsPerDay);
      const weekends = Math.floor((totalDaysInQueue + placedDate.getDay()) / 7) * 2;
      let daysInQueue = totalDaysInQueue - weekends;

      // console.log(this.holidays)
      const holidays = this.holidays
      holidays.forEach(holiday => {
        const holidayTime = holiday.getTime();
        if (placedDate.getTime() <= holidayTime && holidayTime <= currentDate.getTime()) {
          daysInQueue--;
        }
      });

      if(daysInQueue < 0){
        daysInQueue = 0
      } 

      let s_date = moment(currentDate).format("MMMM D, YYYY");
      let l_date = moment(placedDate).format("MMMM D, YYYY");
      if(daysInQueue == 0 && s_date !== l_date){
        daysInQueue = 1;
      }

      return daysInQueue;
    },
    getBackgroundColor(datePlaced) {
      const daysInQueue = this.calculateDaysInQueue(datePlaced);
      if(daysInQueue >= 12){
        return '#ff5252';
      } else if(daysInQueue >= 7){
        return '#ffae00';
      } else {
        return '#67c23a';
      }
      // return daysInQueue >= 7 ? '#f45152' : '#67c23a';
    },
    getExcludedDates(){
      ExcludedDate.get({
        params:{ },
      })
      .then((result) => {
          this.excluded_dates = result.data.data
          const holidays = []
          this.excluded_dates.forEach(function(dates){
            holidays.push(new Date(moment(dates.date).format("Y-MM-D")));
          })
          this.holidays = holidays
          // console.log(this.holidays)
      })
      .catch((error) => {
      });
    },
    handleAcceptClick(recipient){
      this.form.id = recipient.id
      this.form.step = 4
      this.form.user_id = this.$store.state.savedUser.id
      // console.log(this.form.user_id)
      let form = Object.assign({}, this.form);
      
      this.$confirm('Confirm Start of Evaluation / Processing request(s) of <strong>'+ recipient.first_name + ' ' + recipient.last_name +'\'s</strong> requests?', {
          confirmButtonText: 'Confirm Evaluation / Processing',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(() =>{

        //recode this ========================================================
        
        Recipient.updateDetails(form)
        .then(result => {
          if (result) {
            this.$message({
              message: "Document process started.",
              type: 'success',
              duration: 1500
            });
          } 
        })
        .catch( error => {
          console.log(error)
        })
        this.getRecipients()
      })
      .catch(_ => {

      });
    },
    revert(recipient){
      // console.log(id)
      this.form.id = recipient.id
      this.form.step = 98
      this.form.user_id = this.$store.state.savedUser.id
      
      let form = Object.assign({}, this.form);

      this.$prompt('Please input reason for <strong style="color:#e79500;">revert</strong> for:<br> <strong>'+ recipient.first_name + ' ' + recipient.last_name +'</strong>', {
          confirmButtonText: 'Confirm Revert',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(({ value }) => {
        if(!value){
          this.$message({
            type: 'error',
            message: 'Remarks required'
          });
          return
        }
        this.$message({
          type: 'success',
          message: 'Document request reverted'
        });
        form.revert_remarks = value
        Recipient.updateDetails(form)
        .then(result => {
          if (result) {
            this.$message({
              message: "Recipient Reverted",
              type: 'success',
              duration: 1500
            });
          } 
        })
        .catch( error => {
          console.log(error)
        })
        this.getRecipients()
      }).catch(() => {

      });
    },
    verify(recipient){
      // console.log(id)
      this.form.id = recipient.id
      this.form.step = 5
      this.form.user_id = this.$store.state.savedUser.id
      let form = Object.assign({}, this.form);
      
      this.$confirm('Confirm <strong style="color:green;">ready for Release / Pick up request(s)</strong> of <strong>'+ recipient.first_name + ' ' + recipient.last_name +'</strong>', {
          confirmButtonText: 'Confirm Ready for Release / Pick up',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          type: 'success'
        }).then(() =>{
        Recipient.updateDetails(form)
        .then(result => {
          if (result) {
            this.$message({
              message: "Documents ready for Release / Pick up.",
              type: 'success',
              duration: 1500
            });
          } 
        })
        .catch( error => {
          console.log(error)
        })
        this.getRecipients()
      })
      .catch(_ => {

      });
    },
    getRecipients() {
      // this.meta.page = 1;
      this.getExcludedDates()

      Recipient.get({
        params: { step: 4, page: this.meta.page},
      })
        .then((result) => {
          this.recipients = result.data.data;
          console.log(this.recipients)
          this.recipients.forEach(function(recipient){
            recipient.created_at = moment(recipient.created_at).format("MMMM D, YYYY")

            if(recipient.date_request_verified){
              recipient.date_request_verified = moment(recipient.date_request_verified).format("MMMM D, YYYY")
            } else {
              recipient.date_request_verified = null
            }

            if(recipient.recipient_payment.payment_date){
              recipient.recipient_payment.payment_date = moment(recipient.recipient_payment.payment_date).format("MMMM D, YYYY")
            } else {
              recipient.recipient_payment.payment_date = null
            }

            if(recipient.date_start_process){
              recipient.date_start_process = moment(recipient.date_start_process).format("MMMM D, YYYY")
            } else {
              recipient.date_start_process = null
            }
            
            recipient.verified_payment_track = recipient.request_tracks.filter(item => item.status == 4)[0];
            recipient.verified_payment_track_date = recipient.verified_payment_track ? moment(recipient.verified_payment_track.created_at).format("MMMM D, YYYY") : '--'
            recipient.verified_payment_track_time = recipient.verified_payment_track ? moment(recipient.verified_payment_track.created_at).format("hh:mm A") : '--'
          })
          // console.log(this.recipients);
          this.meta = {
            page: result.data.meta.current_page,
            total: result.data.meta.total,
            per_page: result.data.meta.per_page,
            pages: result.data.meta.last_page,
          };
          this.loading = false;
          // console.log(this.recipients);
        })
        .catch((error) => {
          this.loading = false;
          this.recipients = [];
        });
    },
    handleClick(recipient){
      this.showDialog = true
      this.selected = Object.assign({}, recipient)
    },
    pageChanged(val){
      this.meta.page = val;
      this.getRecipients();
    },

  }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <el-row style="margin-bottom: 10px;">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <span style="color: red;">*</span><span style="font-size: 13px; color: #7d7d7d; "><strong> - <i>Required</i></strong></span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div style="float: right">
                    <el-button v-if="recipient.step == 0" style="padding: 5px;"
                        type="success"
                        @click="editRequests()"
                        icon="el-icon-edit-outline" > Change Request(s)
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-table
        :data="documents"
        :show-header="false"
        style="width: 100%;" border>
            <el-table-column
                style="width: 100%;">
                <template slot-scope="scope">
                    <el-row>
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <div style="float: left">
                                <el-button v-if="recipient.step == 0" style="padding: 1px;" type="danger" icon="el-icon-delete" @click="removeRequest(scope.row)"> Remove</el-button>
                            </div>
                        </el-col>
                    </el-row>
                    <document-form-component :document.sync="documents[scope.$index]"></document-form-component>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right; margin-top: 15px; margin-bottom: 15px;">
            <el-card shadow="none">
                <b> <span style="color: black; font-size: 20px;">*</span> TOTAL: {{ formatCurrency(parseFloat(this.recipient.recipient_payment.amount)) }} </b><br>
                <span style="color: black; font-size: 20px;">*</span><span style="font-size: 13px; color: #949494;"> <i>Estimated amount. The final bill will be reflected after verification of your request(s) from the University Registrar.</i></span>
            </el-card>
        </div>
        <div>
            <el-dialog title="Change Request(s)" width="95%" append-to-body :visible.sync="showList" v-if="showList">
                <div>
                    <span style="font-weight: bold; color: #ff0000; font-size: 18px;"><i>Note:</i></span><br>
                    <span style="font-weight: bold; font-size: 15px;"><i>Re-selecting will override your current request(s)</i></span>
                    
                        <requests-lists-component :form.sync="documents" @updateMergedSelection="handleMergedSelectionUpdate"></requests-lists-component>
                    
                    <div style="margin: auto; text-align: center">
                        <el-button
                        :loading="loading"
                        class="request-button"
                        type="success"
                        @click="submit()"
                        block
                        :disabled="!checked"
                        >SAVE SELECTION</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
        
    </div>
  </template>
  
  <script>
  
  import DocumentFormComponent from '@/views/form/DocumentFormComponent.vue'
  import { DocumentRequest } from '@/api_services/documentrequest';
  import RequestsListsComponent from '@/views/form/RequestsListsComponent.vue'

  export default {
    name: 'DocumentsComponent',
    props: ['recipient', 'check'],
    components: { DocumentFormComponent, RequestsListsComponent },
    data() {
          return {
            documents: [],
            showList: false,
            checked: false,
            loading: false,
            form: {}
          }
      },
    created: function() {
        this.documents = this.recipient.document_requests
        if (!this.documents) this.documents = []
        // console.log(this.documents)

        this.documents.forEach((doc, index) => {
            doc.index = index + 1
        });
      },
    watch: {
        check: function() {
           this.validateForm()
        },
        documents: {
            deep: true,
            handler() {
                this.validateForm()
            }
        }
    },
    methods: {
        formatCurrency(value) {
            return value.toLocaleString('en-PH', {
                style: 'currency',
                currency: 'PHP',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },
        validateForm() {
            let valid = true
            this.documents.forEach(function(document){
                if (!document.valid) valid = false
            })
            this.valid = valid
            this.$emit('update', { documents: this.documents, valid: this.valid})
        },
        removeRequest(request){
            let form = Object.assign({}, request)
            form.recipient_id = this.recipient.id
            if(this.recipient.document_requests.length == 1){
                this.$alert('Must have at least 1 request', 'Action not allowed', {
                    confirmButtonText: 'OK',
                });
                return
            }

            this.$confirm("<strong>Confirm removing this request: <span style='color:red;'>"+ request.request_type.request_name +"</span>?</strong><br><br> <i>Please take note that removing a request is irrevestable.</i>", {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                dangerouslyUseHTMLString: true,
            }).then(() => {
                // console.log(form)
                DocumentRequest.removeRequest(form)
                .then(result => {
                    this.$emit("reload");
                    this.$message({
                        message: "Request removed successfully.",
                        type: 'success',
                        duration: 1500
                    });
                })
                    .catch( error => {
                    console.log(error)
                })
            })
            .catch(_ => {

            });
        },
        editRequests(){
            this.showList = true
        },
        handleMergedSelectionUpdate(mergedSelection) {
            this.mergedSelection = mergedSelection
            this.checked = this.mergedSelection.length > 0 ?  true : false
            // console.log(this.mergedSelection);
        },
        submit(){
            this.form = {
                all_selected : this.mergedSelection,
                recipient_id : this.recipient.id
            }

            // console.log(this.form)
            if(!this.checked){
                this.$alert('Please Select a Document to Request', 'Selection Required', {
                    confirmButtonText: 'OK',
                });
                return;
            }
            let all_list = ""
            this.form.all_selected.forEach((list, index) => {
                all_list = all_list + " " + (index + 1) + ".) " + list.request_name + " - " + list.count + "<br>"
            });

            this.$confirm("<strong>Confirm re-selecting Request(s)?</strong><br>" + all_list + " <br> <i>Please take note that this will override your current request(s).</i>", {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                dangerouslyUseHTMLString: true,
            }).then(() => {

                DocumentRequest.reselectRequests(this.form)
                .then(result => {
                    if (result) {
                        this.checked = false
                        this.showList = false
                        this.$message({
                            message: "Request(s) updated",
                            type: 'success',
                            duration: 1500
                        });
                        this.$emit("reload");
                    } 
                }).catch( error => {
                    console.log(error)
                })
                
            })
            .catch(_ => {

            });
            
            // console.log("submit")
        },
    }
  }
  </script>

<style scoped>
    .recipient {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 70px;
    }
    .request-button {
        min-width: 40%;
        margin-top: 20px;
    }
</style>
<template>
    <div> 
        <el-row :gutter="20" style="padding:10px">
          <el-card shadow="none">
            <el-tabs v-model="activeName" @tab-click="handleClickTab" :tab-position="tabPosition">
                <el-tab-pane name="first" active v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
                  <template slot="label">
                    <span>
                      Incoming <i class="el-icon-receiving"></i>
                    </span>
                  </template>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <incomming-component :active-tab="activeName"></incomming-component>
                  </el-col>
                </el-tab-pane>
                <el-tab-pane name="second" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin' || $store.state.savedUser && $store.state.savedUser.role === 'accounting'">
                  <template slot="label">
                    <span>
                      Payment <i class="el-icon-wallet"></i> 
                    </span>
                  </template>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <payment-component :active-tab="activeName"></payment-component>
                  </el-col>
                </el-tab-pane>
                <el-tab-pane name="third" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
                  <template slot="label">
                    <span>
                      Processing <i class="el-icon-tickets"></i> 
                    </span>
                  </template>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <assessment-component :active-tab="activeName"></assessment-component>
                  </el-col>
                </el-tab-pane>
                <el-tab-pane name="fourth" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
                  <template slot="label">
                    <span>
                      Releasing <i class="el-icon-takeaway-box"></i> 
                    </span>
                  </template>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <releasing-component :active-tab="activeName"></releasing-component>
                  </el-col>
                </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-row>
        <el-dialog title="Request Details" width="75%" :visible.sync="showDialog">
          <view-request-component ></view-request-component>
        </el-dialog>
    </div>
</template>

<script>

import * as moment from 'moment';
import ViewRequestComponent from './ViewRequestComponent.vue';
import IncommingComponent from './IncommingComponent.vue';
import PaymentComponent from './PaymentComponent.vue';
import AssessmentComponent from './AssessmentComponent.vue';
import ReleasingComponent from './ReleasingComponent.vue';


export default {
  name: 'Dashboard',
  components: {
    ViewRequestComponent, IncommingComponent, PaymentComponent, AssessmentComponent, ReleasingComponent
  },
  data() {
    return {
        showDialog: false,
        activeName: null, 
        input1: null,
        input2: null,
        tabPosition: 'left'
    }
    
  },
  created: function() {
    // this.getSchedules()
    // this.getAnalytics()
    if(this.$store.state.savedUser.role === 'accounting'){
      this.activeName = 'second'
    } else {
      this.activeName = 'first'
    }
  },
  methods: {
      handleClick(){
        this.showDialog = true
      },
      handleClickTab(tab){
        // console.log(tab)
      }
    
  }
}
</script>

<style scoped>
  .dashboard-icon {
        position: absolute;
    top: 15px;
    right: 15px;
    font-size: 80px;
    opacity: .6;
  }
  .demo-input-label {
    display: inline-block;
    width: 130px!important;
  }
</style>
<template>
    <div>
      <div style="justify-content: center; align-items: center;display: flex;">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="Search:">
            <el-input
              size="small"
              v-model="searchForm.term"
              placeholder="Code / Name / Email"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="info"
              @click="onSearch"
              size="small"
              :loading="loading"
              icon="el-icon-search"
              >Search</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              type="warning"
              @click="clearSearch"
              size="small"
              :loading="loading"
              icon="el-icon-delete-solid"
              >Clear Search</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-tag type="info">Total: {{ meta.total }}</el-tag>
          </el-form-item>
        </el-form>
      </div>
        <el-table v-if="activeTab === 'first'" :data="recipients" :empty-text="empty" stripe border style="width: 100%" size="mini" v-loading="loading">
          <el-table-column
            fixed
            label="Request Code"
            >
            <template slot-scope="scope">
              <b style="font-size: 15px;">{{ scope.row.recipient_rqst_code }}</b> <span style="padding: 1px 4px 1px 4px; background-color: green; color: white;" v-if="scope.row.is_expedite">expedite</span><br>
              <div style="color:#909399">
                <b>Date Filed: </b><span>{{ scope.row.created_at }}</span><br>
                <!-- <b>Date Request Verified: </b><span>{{ scope.row.date_request_verified ? scope.row.date_request_verified : 'Unverified'}}</span><br>
                <b>Date Payment Verified: </b><span>{{ scope.row.recipient_payment.payment_date ? scope.row.recipient_payment.payment_date : 'Unverified'}}</span><br>
                <b>Date Start Process: </b><span>{{ scope.row.date_start_process }}</span><br> -->
                <b>Date Claimed: </b><span>{{ scope.row.recipient_payment.payment_date }}</span>
              </div>
              
            </template>
            
          </el-table-column>
          <el-table-column
            label="Request(s)"
            >

            <template slot-scope="scope">
              <div v-for="(document_requests, index) in scope.row.document_requests" :key="index">
                <span v-if="document_requests.request_type.doc_type != 'EXPEDITE_FEE' "><li>  {{ document_requests.request_type.request_name }} ({{ document_requests.no_copy }})</li></span>
              </div>
            </template>
            
          </el-table-column>
          
          <!-- <el-table-column
            prop="student_no"
            label="Student Number"
            width="150">
          </el-table-column> -->
          <el-table-column
            label="Name"
            >
            <template slot-scope="scope">
              <span>{{ scope.row.last_name }}, {{ scope.row.first_name }} {{ scope.row.middle_name }} {{ scope.row.ext_name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Degree Program"
            >
            <template slot-scope="scope">
              <span v-if="scope.row.course_id">{{ scope.row.course.code }} {{ !scope.row.course.major ? "" : "major in " + scope.row.course.major }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Operations"
            width="100px">
            <template slot-scope="scope">
              <el-button style="width: 100%; padding: 4px" @click="handleClick(scope.row)" type="warning" size="small">View</el-button><br>
              <!-- <el-button style="width: 100%; padding: 4px" @click.native.prevent="verify(scope.row)" type="success" size="small">Verify</el-button><br>
              <el-button style="width: 100%; padding: 4px" @click.native.prevent="revert(scope.row.id)" type="info" size="small">Revert</el-button><br>
              <el-button style="width: 100%; padding: 4px" @click.native.prevent="reject(scope.row.id)" type="danger" size="small">Reject</el-button><br> -->
            </template>
          </el-table-column>
        </el-table>
        <div style="justify-content: center; align-items: center;display: flex;">
          <el-pagination
            class="margin-top-20"
            background
            center
            layout="prev, pager, next"
            :total="meta.total"
            :page-size="meta.per_page"
            @current-change="pageChanged"
            :current-page="meta.page"
          >
          </el-pagination>
        </div>
        
        <el-dialog title="Request Details" width="75%" :visible.sync="showDialog" :destroy-on-close.sync="destroyOnClose" v-if="showDialog">
          <view-request-component :selected="selected"></view-request-component>
        </el-dialog>

    </div>
</template>
  
  <script>
  import ViewRequestComponent from '@/views/private/pages/dashboard/ViewRequestComponent.vue';
  import { Recipient } from '@/api_services/recipient';
  import moment from "moment";
  
  export default {
    props: {
      activeTab: String,
    },
    name: 'CompletedRequestsComponent',
    components: {
      ViewRequestComponent
    },
    data() {
      return {
        recipients: null,
        meta: {
          page: 1,
          pages: 1,
          total: 211,
          per_page: 10,
        },
        searchForm:{},
        showDialog: false,
        selected: null,
        loading: false,
        destroyOnClose: true,
        empty: "No completed document request(s).",
      };
    },
    created: function() {
      if (!this.recipients){this.getRecipients()}
      this.debouncedSearch  = this.debounce(this.getRecipients, 300);
    },
    watch: {
      activeTab(newTab, oldTab) {
        if (newTab === 'first') {
          this.loading = true
          this.getRecipients();
        }
      },
      'searchForm.term': function(newTerm) {
        this.debouncedSearch(newTerm);
      }
    },
    methods: {
      debounce(func, wait) {
        let timeout;
        return function(...args) {
          const later = () => {
            clearTimeout(timeout);
            func.apply(this, args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      },
      getRecipients() {
        this.loading = true;
        console.log('Searching for completed:', this.searchForm.term);
        // this.meta.page = 1;
        Recipient.get({
          params: { step: 7, page: this.meta.page, term: this.searchForm.term, searchAll: 1},
        })
          .then((result) => {
            this.recipients = result.data.data;
            // console.log(this.recipients);

            this.recipients.forEach(function(recipient){
              recipient.created_at = moment(recipient.created_at).format("MMMM D, YYYY")
              recipient.date_start_process = moment(recipient.date_start_process).format("MMMM D, YYYY")

              if(recipient.date_request_verified){
                recipient.date_request_verified = moment(recipient.date_request_verified).format("MMMM D, YYYY")
              } else {
                recipient.date_request_verified = null
              }

              if(recipient.recipient_payment.payment_date){
                recipient.recipient_payment.payment_date = moment(recipient.recipient_payment.payment_date).format("MMMM D, YYYY")
              } else {
                recipient.recipient_payment.payment_date = null
              }
              
            })

            // console.log(this.recipients)
            this.meta = {
              page: result.data.meta.current_page,
              total: result.data.meta.total,
              per_page: result.data.meta.per_page,
              pages: result.data.meta.last_page,
            };
            this.loading = false;
            // console.log(this.recipients);
          })
          .catch((error) => {
            this.loading = false;
            this.recipients = [];
          });
      },
      handleClick(recipient){
        this.showDialog = true
        this.selected = Object.assign({}, recipient)
      },
      pageChanged(val){
        this.loading = true;
        this.meta.page = val
        this.getRecipients()
      },
      onSearch(){
        this.meta.page = 1;
        this.getRecipients()
      },
      clearSearch(){
        this.meta.page = 1;
        this.loading = true;
        this.searchForm = {}
        this.getRecipients()
      }

    }
  }
  </script>
  
  <style scoped>
  
  </style>
  
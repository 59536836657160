<template>
    <div> 
        <el-card>
            <div>
                <el-row :gutter="20">
                    
                    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-input
                            style="width: 20%; margin-right: 10px;"
                            v-model="searchForm.term"
                            placeholder="Filter Request Code / Name / Email"
                            size="small"
                            ></el-input>

                            <el-date-picker
                            style=" margin-right: 10px;"
                            v-model="searchForm.date"
                            type="date"
                            placeholder="Filter date filed"
                            size="small">
                            </el-date-picker>

                            <el-select
                            style=" margin-right: 10px;" 
                            v-model="searchForm.status" 
                            placeholder="Filter Status" 
                            size="small">
                                <el-option
                                    v-for="(status,index) in $store.state.request_status"
                                    :key="index"
                                    :label="status.label"
                                    :value="status.id">
                                </el-option>
                            </el-select>

                            <el-button
                            style=" margin-right: 3px;"
                            type="primary"
                            @click="onSearch"
                            size="small"
                            :loading="loading"
                            icon="el-icon-search">
                                Search
                            </el-button>

                            <el-button
                            style=" margin-right: 10px;"
                            type="info"
                            @click="clear"
                            size="small"
                            icon="el-icon-delete"
                            :loading="loading">
                                Clear filter
                            </el-button>

                            <el-tag type="info">Total: <b>{{ meta.total }}</b></el-tag>
                            
                            <div style="float: right;">
                                <el-button
                                style=" "
                                type="success"
                                @click="refresh"
                                size="small"
                                icon="el-icon-refresh"
                                :loading="loading">
                                    Refresh
                                </el-button>
                            </div>
                        </el-col>
                        
                    </el-form>
                </el-row>
            </div>
            <el-row :gutter="20" style="padding:10px">
                <el-table :data="transactions" stripe border style="width: 100%" size="mini" v-loading="loading">
                    <el-table-column label="ID" width="50">
                        <template slot-scope="scope">
                            <div>
                                <span>
                                    {{ scope.row.id  }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Request Code" >
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.recipient_rqst_code  }} </span><br>
                                <span v-if="scope.row.is_expedite" style="font-size: 10px; padding:2px; background-color: #007a00; color: #fff;"><strong>Expedite</strong></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date of Initial Request" >
                        <template slot-scope="scope">
                            <div style="margin-top: -5px;">
                                <span>
                                    {{ scope.row.date_filed }}
                                </span>
                            </div>
                            <div style="margin-top: -10px; margin-bottom: -8px">
                                <span style="font-size: 10px; color: rgb(134, 134, 134);">
                                    {{ scope.row.date_filed_time }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date Info/ID Verified" >
                        <template slot-scope="scope">
                            <div style="margin-top: -5px;">
                                <span>
                                    {{ scope.row.date_info_verified }}
                                </span>
                            </div>
                            <div style="margin-top: -10px; margin-bottom: -8px">
                                <span style="font-size: 10px; color: rgb(134, 134, 134);">
                                    {{ scope.row.date_info_verified_time }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Request(s)" width="280px">
                        <template slot-scope="scope">
                            <div v-for="(document_requests, index) in scope.row.document_requests" :key="index">
                                <span style="font-size: 10px;" v-if="document_requests.request_type.doc_type != 'EXPEDITE_FEE' "><li>  {{ document_requests.request_type.request_name }} <span v-if="document_requests.others_request_description">: {{ document_requests.others_request_description }}</span> ({{ document_requests.no_copy }})</li></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Email" >
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.email }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Recipient Name" >
                        <template slot-scope="scope">
                            <div>
                                <span style="font-size: 10px;" v-if="scope.row.last_name">{{ scope.row.last_name }}, {{ scope.row.first_name }} {{ scope.row.middle_name }} {{ scope.row.ext_name }}</span>
                                <span v-if="!scope.row.last_name">--</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Status" >
                        <template slot-scope="scope">
                            ({{ scope.row.step }}) {{ scope.row.step_label }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Course" >
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.course">{{ scope.row.course.code }} {{ !scope.row.course.major ? "" : "major in " + scope.row.course.major }}</span>
                                <span v-if="!scope.row.course">--</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Total Bill"
                    width="140px"
                    >
                        <template slot-scope="scope">
                            <div style="margin-top: -5px;">
                                <span>
                                    {{ formatCurrency(parseFloat(scope.row.recipient_payment.amount)) }}
                                </span>
                            </div>
                            <div style="margin-top: -8px;">
                                <b>
                                    {{ scope.row.recipient_payment.payment_method ? scope.row.recipient_payment.payment_method : "--"  }}
                                </b>
                            </div>
                            <div style="margin-top: -10px; margin-bottom: -8px">
                                <span style="font-size: 10px; color: rgb(134, 134, 134);">
                                    OR: {{ scope.row.recipient_payment.or_number ? scope.row.recipient_payment.or_number : "---"}}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80">
                        <template slot-scope="scope">
                            <el-button style="width: 100%; padding: 4px" @click="handleClick(scope.row)" type="warning" size="small">View</el-button>
                        </template>
                    </el-table-column>
                    
                </el-table>
                <div style="justify-content: center; align-items: center;display: flex;">
                    <el-pagination
                        class="margin-top-20"
                        background
                        center
                        layout="prev, pager, next"
                        :total="meta.total"
                        :page-size="meta.per_page"
                        @current-change="pageChanged"
                        :current-page="meta.page">
                    </el-pagination>
                </div>
            </el-row>
            <el-dialog title="Request Details" width="75%"  :visible.sync="showDialog" :destroy-on-close.sync="destroyOnClose" v-if="showDialog">
                <view-request-component @update="refresh()" :selected="selected"></view-request-component>
            </el-dialog>
        </el-card>
       
    </div>
</template>

<script>

import * as moment from 'moment';
import ViewRequestComponent from '@/views/private/pages/dashboard/ViewRequestComponent.vue';
import { Recipient } from '@/api_services/recipient';

export default {
  name: 'Transactions',
  components: {
    ViewRequestComponent,
  },
  data() {
    return {
        transactions: null,
        count: 0,
        meta: {
          page: 1,
          pages: 1,
          total: 211,
          per_page: 15,
        },
        showDialog: null,
        loading: false,
        destroyOnClose: true,
        searchForm: {}
    }
    
  },
  created: function() {
    this.loading = true
    this.allrequest()
  },
  methods: {
    refresh(){
        this.loading = true
        this.allrequest()
    },
    formatCurrency(value) {
        return value.toLocaleString('en-PH', {
            style: 'currency',
            currency: 'PHP',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    },
    allrequest() {
        // this.meta.page = 1;
        if(this.searchForm.date){
            this.searchForm.date = moment(this.searchForm.date).format("YYYY-MM-DD");
        }

        // console.log(this.searchForm)
        Recipient.get({
            params: { 
                searchAll: 1, 
                page: this.meta.page, 
                // per_page: this.meta.per_page, 
                term: this.searchForm.term, 
                date: this.searchForm.date,
                step: this.searchForm.status
            },
        })
          .then((result) => {
            this.transactions = result.data.data;

            this.transactions.forEach(function(recipient){
                // recipient.date_filed = moment(recipient.created_at).format("MMMM D, YYYY")
                // recipient.date_filed_time = moment(recipient.created_at).format('hh:mm A')

                recipient.filed_track = recipient.request_tracks.filter(item => item.status == 0)[0];
                recipient.date_filed = recipient.filed_track ? moment(recipient.filed_track.created_at).format("MMMM D, YYYY") : '--'
                recipient.date_filed_time = recipient.filed_track ? moment(recipient.filed_track.created_at).format("hh:mm A") : '--'

                recipient.info_verified_track = recipient.request_tracks.filter(item => item.status == 1)[0];
                recipient.date_info_verified = recipient.info_verified_track ? moment(recipient.info_verified_track.created_at).format("MMMM D, YYYY") : '--'
                recipient.date_info_verified_time = recipient.info_verified_track ? moment(recipient.info_verified_track.created_at).format("hh:mm A") : '--'

            })
            this.count = this.transactions.length
            // console.log(this.transactions)
            this.meta = {
              page: result.data.meta.current_page,
              total: result.data.meta.total,
              per_page: result.data.meta.per_page,
              pages: result.data.meta.pages,
            };
            this.loading = false;
            // console.log(this.meta)
            // console.log(this.recipients);
          })
          .catch((error) => {
            this.loading = false;
            this.recipients = [];
          });
      },
    pageChanged(val){
        this.loading = true;
        this.meta.page = val;
        this.allrequest();
    },
    handleClick(recipient){
        this.showDialog = true
        this.selected = Object.assign({}, recipient)
    },
    onSearch(){
        this.meta.page = 1;
        this.loading = true;
        this.allrequest()
    },
    clear(){
        this.searchForm = {
            name: null,
            date: null,
            status: null
        }
        this.loading = true
        this.allrequest()
    }
  }
}
</script>

<style scoped>
  .dashboard-icon {
        position: absolute;
    top: 15px;
    right: 15px;
    font-size: 80px;
    opacity: .6;
  }
  .demo-input-label {
    display: inline-block;
    width: 130px!important;
  }
</style>
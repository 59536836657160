<template>
  <div>
    <el-form ref="form" 
    :model="form"
    :rules="rules"
    @submit.native.prevent="submit('form')"
    >

    <el-row :gutter="5">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="Document Request Name" prop="request_name">
          <el-input :disabled="form.id ? true : false" style="width: 100%;" placeholder="" v-model="form.request_name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="Description" prop="desciption">
          <el-input style="width: 100%;" placeholder="" v-model="form.description"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-card shadow="none">
          <el-form-item label="Default Fees Breakdown:">
            <span v-if="!form.default_fees_breakdown" style="color: #ababab;">not applied</span>
            <fees-breakdown-component :breakdown.sync="form.default_fees_breakdown"></fees-breakdown-component>
          </el-form-item>
        </el-card>
      </el-col>
      <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="Type:" prop="doc_type">
          <el-radio v-model="form.doc_type" v-for="doc_type in $store.state.doc_types" :label="doc_type" :key="doc_type">
            {{ doc_type }}
          </el-radio> 
        </el-form-item>
      </el-col> -->
      <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="With Doc. Stamp?" prop="is_with_doc_stamp">
          <el-radio v-model="form.is_with_doc_stamp" v-for="is_with_doc_stamp in $store.state.is_with_doc_stamp_choices" :label="is_with_doc_stamp" :key="is_with_doc_stamp">
            {{ is_with_doc_stamp }}
          </el-radio> 
        </el-form-item>
      </el-col> -->
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="Fixed Rate:" prop="reg_fee">
          <el-input type="number" style="width: 100%;" v-model="form.reg_fee" placeholder="amount" :step="0.01" min="0"></el-input>
        </el-form-item>
      </el-col>
      <b style="color: #ff5555;">Note:</b> <i style="font-size: small;">If breakdown of fees is applied, the total sum of those fees will be its fixed rate.</i>
      <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="Per Page Fee:" prop="per_page_fee">
          <el-input type="number" style="width: 100%;" placeholder="" v-model="form.per_page_fee"></el-input>
        </el-form-item>
      </el-col> -->
      
    </el-row>
    <div style="text-align:center;margin-top: 20px;">
      <el-form-item>
        <el-button
          class="login-button"
          type="success"
          native-type="submit"
          block
          
        >{{form.id ? "Update" : "Save" }}</el-button>
      </el-form-item>
    </div>

    <el-dialog
      title="Confirmation"
      :visible.sync="submitDialog"
      width="40%"
      :close-on-click-modal="false"
      class="responsive-dialog"
      append-to-body
      >
      <span>Confirm changes. Proceed?</span>
      <span slot="footer" class="dialog-footer">
        <el-button  @click="submitDialog = false">Back</el-button>
        <el-button v-if="form.id" type="primary" @click="confirmSubmit" >Yes</el-button>
        <el-button v-if="!form.id" type="primary" @click="confirmSave" >Yes</el-button>
      </span>
    </el-dialog>

    </el-form>
  </div>
</template>

<script>
import { RequestType } from "@/api_services/requesttype";
import FeesBreakdownComponent from '@/views/private/pages/settings/conf/FeesBreakdownComponent.vue'

export default {
  name: "EditDocfeeComponent",
  props: ["selectedDoc"],
  components: { FeesBreakdownComponent },
  data() {
    return {
      form: {},
      rules: {
      },
      loading: false,
      submitDialog: false
    };
  },
  created() {
    this.form = this.selectedDoc;
    // console.log(this.form)
  },
  methods: {
    async submit() {

      const valid = await this.$refs.form.validate();
      this.submitDialog = true
      
    },
    confirmSubmit() {
      let form = Object.assign({}, this.form);
      console.log(form)
      RequestType.update(form.id, form)
      .then(result => {
        console.log(result)
        if (result) {
          this.form = result.data.data
          this.loading = false
          this.$message({
            message: "Document Saved",
            type: 'success',
            duration: 1500
          });
          this.$emit("update");
          this.submitDialog = false
          // this.verify()
        } 
      })
      .catch( error => {
        console.log(error)
        this.loading = false
      })
    },
    confirmSave() {
      let form = Object.assign({}, this.form);
      console.log(form)
      RequestType.create(form)
      .then(result => {
        console.log(result)
        if (result) {
          this.loading = false
          this.$message({
            message: "Document Added",
            type: 'success',
            duration: 1500
          });
          this.$emit("update");
          this.submitDialog = false
          // this.verify()
        } 
      })
      .catch( error => {
        console.log(error)
        this.loading = false
      })
    },
  },
};
</script>

export const LocalStorageService = {
  getToken: function() {
    return localStorage.getItem('token');
  },
  setToken: function(token: any) {
    localStorage.setItem('token', token);
  },
  getUser: function() {
    let user = localStorage.getItem('user')
    if (user) user = JSON.parse(user);
    return user;
  },
  setUser: function(user: any) {
    localStorage.setItem('user', user ? JSON.stringify(user) : '');
  },
  getRequest: function() {
    let requestInfo = localStorage.getItem('requestInfo')
    if (requestInfo) requestInfo = JSON.parse(requestInfo);
    return requestInfo;
  },
  setRequest: function(requestInfo: any) {
    localStorage.setItem('requestInfo', requestInfo ? JSON.stringify(requestInfo) : '');
  }
}
<template>
    <div>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div>
                    <span>
                        Status <b>({{ recipient.step }} - {{ recipient.step_label }})</b> 
                    </span>
                    <span v-if=" recipient.step <= 3 && recipient.revert_remarks != null" style="background-color: rgb(231, 150, 0); color: #f5f5f5;">
                        Reverted:<i> {{ recipient.revert_remarks }} *</i>
                    </span>
                    <span v-if=" recipient.step == 99 && recipient.reject_remarks != null" style="background-color: rgb(221, 0, 0); color: #f5f5f5;">
                        Rejected:<i> {{ recipient.reject_remarks }} *</i>
                    </span>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div v-if="recipient.step == 0 || recipient.step == 2 || recipient.step == 4 || recipient.step == 6 || recipient.step == 7 || recipient.step == 99" style="text-align: right; margin-bottom: 5px;">
                    <el-button @click="resendEmail()" icon="el-icon-s-promotion" type="info" style="padding: 5px;">Resend Email Notif 
                        <span v-if="recipient.step != 99" style="color: #ffcc00;">{{ $store.state.request_status[recipient.step].label }}</span>
                        <span v-else style="color: #ffcc00;">Rejected</span>
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-card shadow="never" style="border-left: solid 3px #007a00!important;">
            <el-row>
                <el-col :span="24">
                    <el-table
                    class="progressTable"
                    border
                    :data="tableData"
                    style="width: 100%; margin-bottom: 20px;" size="mini">
                        <el-table-column
                            label="Info & ID Submitted">
                            <template slot-scope="scope">
                                <div style="vertical-align: 1cm;">
                                    <div>
                                        <b style="color:#007a00">{{  scope.row.request_filed }}</b>
                                    </div>
                                    <div style="margin-top: -10px; ">
                                        <span style="color:#949494">{{  scope.row.request_filed_time }}</span>
                                    </div>
                                </div>
                                
                            </template>
                        </el-table-column>
                        
                        <el-table-column
                            label="Info & ID Verified">
                            <template slot-scope="scope">
                                <div>
                                    <b style="color:#007a00">{{  scope.row.request_verified }}</b>
                                </div>
                                <div style="margin-top: -10px; margin-bottom: -8px">
                                    <span style="color:#949494">{{  scope.row.request_verified_time }}</span>
                                </div>
                                <div v-if="scope.row.request_verified_by">Verified by:</div>
                                <div style="margin-top: -10px;">
                                    <b>{{  scope.row.request_verified_by }}</b>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                            label="Payment Submitted">
                            <template slot-scope="scope">
                                <div>
                                    <b style="color:#007a00">{{  scope.row.payment_submitted }}</b>
                                </div>
                                <div style="margin-top: -10px;">
                                    <span style="color:#949494">{{  scope.row.payment_submitted_time }}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                            label="Payment Verified">
                            <template slot-scope="scope">
                                <div>
                                    <b style="color:#007a00">{{  scope.row.payment_verified }}</b>
                                </div>
                                <div style="margin-top: -10px; margin-bottom: -8px">
                                    <span style="color:#949494">{{  scope.row.payment_verified_time }}</span>
                                </div>
                                <div v-if="scope.row.payment_verified_by">Verified by:</div>
                                <div style="margin-top: -10px;">
                                    <b>{{ scope.row.payment_verified_by }}</b>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                            label="Processing/Evaluation">
                            <template slot-scope="scope">
                                <div>
                                    <b style="color:#007a00">{{  scope.row.process }}</b>
                                </div>
                                <div style="margin-top: -10px; margin-bottom: -8px">
                                    <span style="color:#949494">{{  scope.row.process_time }}</span>
                                </div>
                                <div v-if="scope.row.process_by">Processor/Evaluator:</div>
                                <div style="margin-top: -10px;">
                                    <b>{{ scope.row.process_by }}</b>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                            label="Ready for Pick up">
                            <template slot-scope="scope">
                                <div>
                                    <b style="color:#007a00">{{  scope.row.ready_for_pickup }}</b>
                                </div>
                                <div style="margin-top: -10px; margin-bottom: -8px">
                                    <span style="color:#949494">{{  scope.row.ready_for_pickup_time }}</span>
                                </div>
                                <div v-if="scope.row.ready_for_pickup_by">Confirmed by:</div>
                                <div style="margin-top: -10px;">
                                    <b>{{ scope.row.ready_for_pickup_by }}</b>
                                </div>
                            </template>
                        </el-table-column>
                        
                        <el-table-column
                            label="Claimed">
                            <template slot-scope="scope">
                                <div>
                                    <b style="color:#007a00">{{  scope.row.claimed }}</b>
                                </div>
                                <div style="margin-top: -10px; margin-bottom: -8px">
                                    <span style="color:#949494">{{  scope.row.claimed_time }}</span>
                                </div>
                                <div v-if="scope.row.claimed_incharge">Released by:</div>
                                <div style="margin-top: -10px;">
                                    <b>{{ scope.row.claimed_incharge }}</b>
                                </div>

                                <div v-if="recipient.claimed_by">Claimed by:</div>
                                <div style="margin-top: -10px;">
                                    <b>{{ recipient.claimed_by }}</b>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <el-row :gutter="40">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-row>
                        <div v-if="!recipient.date_request_verified">
                            <el-col :span="24"><span style="padding:2px; background-color: #ee0000; color: #fff;"><strong>Unverified Request</strong></span></el-col>
                        </div>
                        <div v-if="recipient.date_request_verified">
                            <el-col :span="24"><span style="padding:2px; background-color: #007a00; color: #fff;"><strong>Verified Request</strong></span></el-col>
                        </div>
                        <div v-if="recipient.is_expedite" style="margin-top: 25px;">
                            <el-col :span="24"><span style="padding:2px; background-color: #007a00; color: #fff;"><strong>Expedite</strong></span></el-col>
                        </div>
                        <div style="margin-top: 45px;">
                            <el-col :span="24"><span>ID: <b>{{ recipient.id }}</b></span><br><a style="cursor: pointer;" @click="handleViewForm()" target="_blank"><i class="el-icon-document"></i> Open Request Form</a></el-col>
                        </div>
                    </el-row>
                    <el-row>
                        <el-col :span="24"><span>Request Code: <strong style="font-size: large;">{{ recipient.recipient_rqst_code }}</strong></span> <a style="cursor: pointer;" @click="handleViewTrack(recipient.recipient_rqst_code)" target="_blank"><i class="el-icon-search"></i>Track</a></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24"><span>Email: <strong>{{ recipient.email }}</strong></span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24"><span>Token: <strong>{{ recipient.recipient_token }}</strong></span></el-col>
                    </el-row>
                    <!-- <el-row>
                        <el-col :span="24"><span>Date and time filed: <strong>{{ recipient.created_at }} </strong></span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24"><span>Date request verified: <strong>{{ recipient.date_request_verified ? recipient.date_request_verified : "-" }} </strong></span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24"><span>Date payment verified: <strong>{{ recipient.recipient_payment.payment_date ? recipient.recipient_payment.payment_date : "-" }} </strong></span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24"><span>Date start of processing: <strong>{{ recipient.date_start_process ? recipient.date_start_process : "-" }} </strong></span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24"><span>Date ready for release: <strong>{{ recipient.date_start_process ? recipient.date_start_process : "-" }} </strong></span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24"><span>Date claimed: <strong>{{ recipient.date_start_process ? recipient.date_start_process : "-" }} </strong></span></el-col>
                    </el-row> -->
                    
                    
                    <el-row>
                        <el-col :span="24"></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <h3 style="color: #007a00; margin-bottom: 3px;">Personal information</h3>
                            <span style="padding: 8px;">Name: <strong>{{ recipient.last_name }}, {{ recipient.first_name }} {{ recipient.middle_name ? recipient.middle_name : "" }} {{ recipient.ext_name ? recipient.ext_name : "" }}</strong></span><br>
                            <span style="padding: 8px;">sex: <strong>{{ recipient.sex }}</strong></span><br>
                            <span style="padding: 8px;">Degree Program: 
                                <strong v-if="recipient.course">{{ recipient.course.code }} {{ recipient.course.major ? 'major in ' + recipient.course.major : '' }}</strong>
                                <strong v-if="!recipient.course">--</strong>
                            </span><br>
                            <span style="padding: 8px;">Contact Number: <strong>{{ recipient.contact_no }}</strong></span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <h3 style="color: #007a00; margin-bottom: 3px;">Maiden name (If married)</h3>
                            <span style="padding: 8px;">First Name: <strong>{{ recipient.sex == 'MALE' ? "N/A" : recipient.maiden_first_name}}</strong></span><br>
                            <span style="padding: 8px;">Middle Name: <strong>{{ recipient.sex == 'MALE' ? "N/A" : recipient.maiden_middle_name }}</strong></span><br>
                            <span style="padding: 8px;">Last Name: <strong>{{ recipient.sex == 'MALE' ? "N/A" : recipient.maiden_last_name }}</strong></span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <h3 style="color: #007a00; margin-bottom: 3px;">Other information</h3>
                            <span style="padding: 8px;">Student number: <strong>{{ recipient.student_no ? recipient.student_no : '-'}}</strong></span><br>
                            <span style="padding: 8px;">Presently Enrolled in VSU?: <strong>{{ recipient.is_presently_enrolled}}</strong></span><br>
                            <span style="padding: 8px;">Graduated from VSU?: <strong>{{ recipient.is_graduate }}</strong></span><br>
                            <span style="padding: 8px;">Year graduated: <strong>{{ recipient.year_graduated }}</strong></span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24"><h3 style="color: #007a00; margin-bottom: 3px;">Attachments</h3></el-col>
                        <div v-for="attachment in recipient.attachment" :key="attachment.id">
                            <el-col :span="24" v-if="attachment.type == 1"><a :href="attachment.full_path" target="_blank">Photo with valid ID</a></el-col>
                            <el-col :span="24" v-if="attachment.type == 2"><a :href="attachment.full_path" target="_blank">Photo of receipt</a></el-col>
                        </div>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24"><h3 style="color: #007a00; margin-bottom: 0px;">Notes</h3>
                            <span>*<i style="font-size: 13px;">For notes only</i></span>
                        </el-col>
                        <el-col :span="24">
                            <el-input
                                type="textarea"
                                :rows="6"
                                placeholder="Leave notes here"
                                v-model="recipient.notes">
                            </el-input>
                        </el-col>
                        <el-col :span="24">
                            <div style="text-align: right;">
                                <el-button @click="saveNotes()" icon="el-icon-upload" type="success" style="padding: 5px; margin-top: 5px;">Save Notes</el-button>
                            </div>
                        </el-col>
                    </el-row>
                    
                    
                    
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    
                    <div>
                        <h3 style="color: #007a00; margin-bottom: 3px;">Requested Document(s)</h3>
                        <el-table :show-header="false" border :data="recipient.document_requests" style="width: 100%;">
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                            <div style="float: left">
                                                <el-button v-if="recipient.step == 1" style="padding: 1px;" type="danger" icon="el-icon-delete" @click="removeRequest(scope.row)"> Remove</el-button>
                                            </div>
                                        </el-col>
                                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                            <div style="float: right">
                                                <el-button v-if="recipient.step == 1" style="padding: 1px;" type="warning" icon="el-icon-edit-outline" @click="viewBreakdown(scope.row)"></el-button>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                            <!-- <div class="custom-cell"> -->
                                                <span v-if="scope.row.request_type.doc_type != 'EXPEDITE_FEE'" style="background: #007a00; color: white; padding: 1px 5px 1px 5px; border-radius: 10px;">{{ scope.row.index }}</span>  
                                                <span v-if="scope.row.request_type.doc_type == 'EXPEDITE_FEE'"> Additional fee: </span>
                                                <span style="font-size: 13px;"> <strong><u>{{ scope.row.request_type.request_name }}</u> <u v-if="scope.row.others_request_description">: {{ scope.row.others_request_description }}</u></strong></span>
                                            <!-- </div> -->
                                        </el-col>
                                    </el-row>

                                    <el-row v-if="scope.row.request_type.doc_type != 'EXPEDITE_FEE'">
                                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                            <el-row>
                                                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                                    <span style="font-size: 12px;">Purpose: <b>{{ scope.row.request_purpose == 'OTHERS' ? scope.row.request_purpose + ": " + scope.row.request_purpose_others : scope.row.request_purpose}}</b></span>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                    </el-row>

                                    <el-row v-if="scope.row.request_type.doc_type != 'EXPEDITE_FEE'">
                                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                            <el-row>
                                                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                                    <span style="font-size: 12px;">For Local or Abroad? <b>{{ scope.row.location_use ? scope.row.location_use : "-"}} {{ scope.row.study_use ? ", " + scope.row.study_use : "" }}</b></span>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                    </el-row>

                                    <el-row v-if="scope.row.request_type.ask_sem">
                                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                            <el-row>
                                                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                                    <span style="font-size: 12px;">Semester and School Year: <b>{{ scope.row.semester_and_school_year ? scope.row.semester_and_school_year : "-"}}</b></span>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                    </el-row>

                                    <el-row v-if="scope.row.request_type.ask_subject">
                                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                                            <el-row>
                                                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                                    <span style="font-size: 12px;">Subject: <b>{{ scope.row.subject ? scope.row.subject : "-"}}</b></span>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                    </el-row>

                                    <el-row>
                                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                            <!-- <el-row>
                                                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"> -->
                                                    <span style="font-size: 12px;" >Additional Remarks:</span>
                                                    <i style="font-size: 12px; color: #949494;">{{ scope.row.request_notes }}</i>
                                                <!-- </el-col>
                                            </el-row> -->
                                        </el-col>
                                    </el-row>

                                    <el-row >
                                        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="color: #fff;">.</el-col>
                                        <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16" style="text-align: right;">
                                            
                                            <el-card shadow="none">
                                                <u v-if="!scope.row.fees_breakdown" style="color: #949494;">No Fees Breakdown Applied </u>
                                                <u v-if="scope.row.fees_breakdown" style="color: #949494;">Fees Breakdown </u>
                                                <div v-for="(value, key) in scope.row.fees_breakdown" :key="key" style="text-align: right;">
                                                    <el-row>
                                                        <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                                                            <span style="font-size: 12px;">{{ key }} - </span>
                                                        </el-col>
                                                        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                                                            <b style="font-size: 12px;">Php {{ formatCurrency(parseFloat(value)) }}</b>
                                                        </el-col>
                                                    </el-row>
                                                </div>
                                                <el-dialog title="Edit Fees Breakdown" width="25%" append-to-body :visible.sync="showBreakdown" v-if="showBreakdown">
                                                    <view-breakdown-component @updateBreakdown="updateBreakdown" :document_request.sync="document_request"></view-breakdown-component>
                                                </el-dialog>
                                            </el-card>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                            <div style="float: right; text-align: right;">
                                                
                                                <span style="color: #949494; font-size: 12px;" v-if="scope.row.request_type.doc_type != 'EXPEDITE_FEE'">Copies: <strong>{{ scope.row.no_copy }}</strong></span><br>
                                                <span style="font-size: 12px;"><strong>Php {{ formatCurrency(parseFloat(scope.row.fee)) }}</strong></span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="text-align:right; margin-top: 15px; margin-bottom: 15px;">
                        <el-card style="border-left: solid 3px #007a00!important;" shadow="none">
                            <b style="font-size: 22px;"> TOTAL: {{ formatCurrency(parseFloat(recipient.recipient_payment.amount)) }}</b><br>
                        </el-card>
                    </div>
                    <div style="text-align:right; margin-top: 15px; margin-bottom: 15px;">
                        <el-row v-if="recipient.step == 3">
                            <el-col :span="24">
                                <el-input
                                    type="text"
                                    :rows="6"
                                    placeholder="OR / Reference No."
                                    v-model="recipient.recipient_payment.or_number">
                                </el-input>
                            </el-col>
                            <el-col :span="24">
                                <div style="text-align: right;">
                                    <el-button @click="saveOR()" icon="el-icon-upload" type="success" style="padding: 5px; margin-top: 5px;">Save OR / Reference number</el-button>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row v-if="recipient.step != 3">
                            <el-col :span="24">
                                <h3><span style="color: #007a00;">OR / Reference number:</span><span> {{ recipient.recipient_payment.or_number ? recipient.recipient_payment.or_number : '---' }}</span></h3> 
                            </el-col>
                        </el-row>
                    </div>
                    <div>
                        <el-card shadow="none">
                            <el-row :gutter="20">
                                <el-col :span="14">
                                    <h3 style="color: #007a00; margin-bottom: 3px;">Payment Method</h3>
                                    <div style="margin-bottom: 5px;">
                                        <strong>{{ recipient.recipient_payment.payment_method ? recipient.recipient_payment.payment_method : "No payment method selected yet." }}</strong>
                                        <div v-for="attachment in recipient.attachment" :key="attachment.id">
                                            <el-col :span="24" v-if="attachment.type == 2"><a :href="attachment.full_path" target="_blank">Photo of receipt</a></el-col>
                                        </div>
                                    </div>
                                    <h3 style="color: #007a00; margin-bottom: 3px;">Payment status</h3>
                                    <div v-if="!recipient.recipient_payment.payment_date">
                                        <span>Status: <strong style="color:red">Unpaid</strong></span>
                                    </div>
                                    <div v-if="recipient.recipient_payment.payment_date">
                                        <span>Status: <strong style="color:green">Paid</strong></span>
                                    </div>
                                    <div>
                                        <span>Payment date: <strong>{{ recipient.recipient_payment.payment_date ? recipient.recipient_payment.payment_date : "Unverified" }}</strong></span>
                                    </div>
                                </el-col>
                                <el-col :span="10">
                                    <div v-if="recipient.step == 1">
                                        <h3 style="color: #007a00; margin-bottom: 3px;">Rush request?</h3>
                                        <div style="text-align: left;">
                                            <span v-if="recipient.is_expedite">(expedite fee applied)</span><br>
                                            <span v-if="recipient.is_expedite" style="font-size: 12px;color: #5a5a5a;">Expedite by: <b>{{ recipient.expediteby.name }}</b></span>
                                            <el-button :disabled="isDisabled()" v-if="!recipient.is_expedite" @click="handleExpedite()" icon="el-icon-magic-stick" type="success" style="padding: 5px;">Apply expedite fee</el-button>
                                        </div>
                                        <span style="font-size: 12px;color: #5a5a5a;"><i>1-6 transactions until 12:00 noon only</i></span>
                                    </div>
                                    <div v-if="recipient.step > 1">
                                        <h3 style="color: #007a00;">Rush request?</h3>
                                        <span v-if="recipient.is_expedite">(expedite fee applied)</span><br>
                                        <span v-if="recipient.is_expedite" style="font-size: 12px;color: #5a5a5a;">Expedite by: <b>{{ recipient.expediteby.name }}</b></span>
                                        <span v-if="!recipient.is_expedite">(expedite fee not applied)</span>
                                    </div>
                                    
                                </el-col>
                            </el-row>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-card>
    </div>
  </template>
  
  <style>
    .el-table td{
        vertical-align: super!important;
    }

  </style>
  
  <script>

    import { Recipient } from '@/api_services/recipient';
    import { DocumentRequest } from '@/api_services/documentrequest';
    import * as moment from 'moment';
    import ViewBreakdownComponent from '@/views/private/pages/dashboard/ViewBreakdownComponent.vue'

    export default {
        name: "ViewRequestComponent",
        props: ["selected"],
        components: { ViewBreakdownComponent },
        data() {
            return {
                recipient: this.selected,
                created_at_time: null,
                tableData: [],
                showBreakdown: false,
            };
        },
        created() {
            this.recipient = this.selected
            console.log(this.recipient)
            this.loadDetails()
        },
        methods: {
            removeRequest(request){
                let form = Object.assign({}, request)

                if(this.recipient.document_requests.length == 1){
                    this.$alert('Must have at least 1 request', 'Action not allowed', {
                        confirmButtonText: 'OK',
                    });
                    return
                }

                this.$confirm("<strong>Confirm removing this request: <span style='color:red;'>"+ request.request_type.request_name +"</span>?</strong><br><br> <i>Please take note that removing a request is irrevestable.</i>", {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    // console.log(form)
                    DocumentRequest.removeRequest(form)
                    .then(result => {
                        if (result) {
                            this.recipient = result.data.data
                            this.loadDetails()
                            this.$emit("update");
                            this.$message({
                                message: "Request removed successfully.",
                                type: 'success',
                                duration: 1500
                            });
                        } 
                    })
                        .catch( error => {
                        console.log(error)
                    })
                })
                .catch(_ => {

                });
            },
            updateBreakdown(value){
                this.recipient = value
                this.loadDetails()
                this.$emit("update")
                this.showBreakdown = false
            },
            loadDetails(){
                

                this.recipient.document_requests.forEach(function(doc,index){
                    doc.index = index + 1
                })

                this.recipient.filed_track = this.recipient.request_tracks.filter(item => item.status == 1)[0];
                this.recipient.verified_track = this.recipient.request_tracks.filter(item => item.status == 2)[0];
                this.recipient.payment_track = this.recipient.request_tracks.filter(item => item.status == 3)[0];
                this.recipient.verified_payment_track = this.recipient.request_tracks.filter(item => item.status == 4)[0]; 
                this.recipient.process_track = this.recipient.request_tracks.filter(item => item.status == 5)[0];
                this.recipient.pickup_track = this.recipient.request_tracks.filter(item => item.status == 6)[0];
                this.recipient.claimed = this.recipient.request_tracks.filter(item => item.status == 7)[0];

                this.tableData = [{
                    request_filed: this.recipient.filed_track ? moment(this.recipient.filed_track.created_at).format("MMMM D, YYYY") : '--',
                    request_filed_time: this.recipient.filed_track ? moment(this.recipient.filed_track.created_at).format("hh:mm A") : '--',
                    request_verified: this.recipient.verified_track ? moment(this.recipient.verified_track.created_at).format("MMMM D, YYYY") : '--',
                    request_verified_time: this.recipient.verified_track ? moment(this.recipient.verified_track.created_at).format("hh:mm A") : '--',
                    request_verified_by: this.recipient.verified_track ? this.recipient.verified_track.activityBy.name : '',
                    payment_submitted: this.recipient.payment_track ? moment(this.recipient.payment_track.created_at).format("MMMM D, YYYY") : '--',
                    payment_submitted_time: this.recipient.payment_track ? moment(this.recipient.payment_track.created_at).format("hh:mm A") : '--',
                    payment_verified: this.recipient.verified_payment_track ? moment(this.recipient.verified_payment_track.created_at).format("MMMM D, YYYY") : '--',
                    payment_verified_time: this.recipient.verified_payment_track ? moment(this.recipient.verified_payment_track.created_at).format("hh:mm A") : '--',
                    payment_verified_by: this.recipient.verified_payment_track ? this.recipient.verified_payment_track.activityBy.name : '',
                    process: this.recipient.process_track ? moment(this.recipient.process_track.created_at).format("MMMM D, YYYY") : '--',
                    process_time: this.recipient.process_track ? moment(this.recipient.process_track.created_at).format("hh:mm A") : '--',
                    process_by: this.recipient.process_track ? this.recipient.process_track.activityBy.name : '',
                    ready_for_pickup: this.recipient.pickup_track ? moment(this.recipient.pickup_track.created_at).format("MMMM D, YYYY") : '--',
                    ready_for_pickup_time: this.recipient.pickup_track ? moment(this.recipient.pickup_track.created_at).format("hh:mm A") : '--',
                    ready_for_pickup_by: this.recipient.pickup_track ? this.recipient.pickup_track.activityBy.name : '',
                    claimed: this.recipient.claimed ? moment(this.recipient.pickup_track.created_at).format("MMMM D, YYYY") : '--',
                    claimed_time: this.recipient.claimed ? moment(this.recipient.pickup_track.created_at).format("hh:mm A") : '--',
                    claimed_incharge: this.recipient.claimed ? this.recipient.claimed.activityBy.name : '',
                }]

                if(this.recipient.recipient_payment.payment_date){
                    this.recipient.recipient_payment.payment_date = moment(this.recipient.recipient_payment.payment_date).format("MMMM D, YYYY")
                }
                // if(this.recipient.created_at){
                //     this.tableData[0]['request_filed'] = moment(recipient.created_at).format("MMMM D, YYYY")
                //     this.tableData[0]['request_filed_time'] = moment(recipient.created_at).format('hh:mm A')
                // }



                this.recipient.document_requests.forEach(function(doc,index){
                    doc.index = index + 1
                })
                
                this.recipient.document_requests.forEach(function(doc,index){
                    doc.index = index + 1
                })

            },
            isDisabled() {
                const currentTime = new Date();
                const currentHour = currentTime.getHours();

                return currentHour >= 12;
            },
            handleViewTrack(code) {
                const query = { recipient_rqst_code: code };
                let routeData = this.$router.resolve({ path: '/track', query });
                window.open(routeData.href, '_blank')
            },
            handleViewForm() {
                const query = { email: this.recipient.email, recipient_rqst_code: this.recipient.recipient_rqst_code, recipient_token: this.recipient.recipient_token };
                let routeData = this.$router.resolve({ path: '/recipient-form', query });
                window.open(routeData.href, '_blank')
            },
            isPdf(url) {
                return url.includes('pdf')
            },
            formatCurrency(value) {
                return value.toLocaleString('en-PH', {
                    style: 'currency',
                    currency: 'PHP',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            },
            handleEdit(row){
                // this.selected_doc = Object.assign({}, row)
                // console.log(row)

                this.$prompt('Please number of pages produced', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                }).then(({ value }) => {
                
                let form = Object.assign({}, this.recipient);

                Recipient.updatePagesProduced(row.id)
                .then(result => {
                    // console.log(result)
                    if (result) {
                        // this.loading = false
                        this.$message({
                            type: 'success',
                            message: 'Pages produced updated: ' + value
                        });
                        this.recipient = result.data.data
                    } 
                })
                    .catch( error => {
                    console.log(error)
                    // this.loading = false
                })

                
                // this.getRecipients()
                }).catch(() => {

                });
            },
            handleExpedite(){
                let form = Object.assign({}, this.recipient);
                form.user_id = this.$store.state.savedUser.id
                this.$confirm('Confirm applying expedite fee for ' + this.recipient.last_name +", "+ this.recipient.first_name + "?")
                .then(_ => {
                    console.log(form)
                    Recipient.updateExpedite(form)
                    .then(result => {
                        if (result) {
                            this.recipient = result.data.data
                            this.loadDetails()
                            this.$emit("update");
                            this.$message({
                            message: "Expedite Request.",
                            type: 'success',
                            duration: 1500
                            });
                        } 
                    })
                    .catch( error => {
                    console.log(error)
                    })
                })
                .catch(_ => {

                });
            },
            saveNotes(){
                let form = Object.assign({}, this.recipient);
                Recipient.updateNotes(form)
                    .then(result => {
                        if (result) {
                            this.recipient = result.data.data
                            this.loadDetails()
                            this.$emit("update");
                            this.$message({
                            message: "Notes saved",
                            type: 'success',
                            duration: 1500
                            });
                        } 
                    })
                    .catch( error => {
                    console.log(error)
                    })
            },
            saveOR(){

                if(!this.recipient.recipient_payment.or_number){
                    this.$alert('Please input OR / Reference number', 'Required OR / Reference Number', {
                        confirmButtonText: 'OK',
                    });
                    this.recipient.recipient_payment.or_number = this.selected.recipient_payment.or_number
                    return
                }

                let form = Object.assign({}, this.recipient);
                Recipient.updateOR(form)
                    .then(result => {
                        if (result) {
                            this.recipient = result.data.data
                            this.loadDetails()
                            this.$emit("update");
                            this.$message({
                            message: "OR saved",
                            type: 'success',
                            duration: 1500
                            });
                        } 
                    })
                    .catch( error => {
                    console.log(error)
                    })
            },
            viewBreakdown(document){
                this.showBreakdown = true
                this.document_request = Object.assign({}, document)
                console.log("test")
            },
            resendEmail(){
                // console.log(this.recipient.step)
                let form = Object.assign({}, this.recipient);
                Recipient.resendEmailNotif(form)
                    .then(result => {
                        if (result) {
                            this.$message({
                                message: "Email Notification Sent!",
                                type: 'success',
                                duration: 1500
                            });
                        } 
                    })
                    .catch( error => {
                        console.log(error)
                    })
            }
        },
    };
  </script>
  
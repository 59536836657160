<template>
    <div>
        <el-card>
          <span style="color: #009d00; font-weight: bolder;">Incoming Requests / Recipient Verification</span><br>
          <div style="margin-bottom: 10px;">
            <el-row :gutter="20">
              <el-form style="margin-top: 30px;" @submit.native.prevent="onSearch" :model="searchForm">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                    style="width: 20%; margin-right: 10px;"
                    v-model="searchForm.term"
                    placeholder="Filter Request Code / Name / Email"
                    size="small"
                    ></el-input>

                    <el-button
                    style=" margin-right: 3px;"
                    type="primary"
                    @click="onSearch"
                    size="small"
                    :loading="loading"
                    icon="el-icon-search">
                        Search
                    </el-button>

                    <el-button
                      style=" margin-right: 10px;"
                      type="info"
                      @click="clear"
                      size="small"
                      icon="el-icon-delete"
                      :loading="loading">
                        Clear filter
                    </el-button>

                    <el-tag type="info">Total: <b>{{ meta.total }}</b></el-tag>
                    <el-button style="float: right;" @click="refresh()" type="success" size="small" :loading="loading"><i v-if="!loading" class="el-icon-refresh"></i> Refresh</el-button>
                </el-col>
                  
              </el-form>
            </el-row>
          </div>
          <el-table v-if="activeTab === 'first'" :data="recipients" :empty-text="empty" stripe border style="width: 100%" size="mini" v-loading="loading">
            <el-table-column
              label="ID"
              fixed
              width="60px"
              >
              <template slot-scope="scope">
                <span>{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed
              label="Request Code"
              >
              <template slot-scope="scope">
                <b style="font-size: 15px;">{{ scope.row.recipient_rqst_code }} </b><span style="padding: 1px 4px 1px 4px; background-color: green; color: white;" v-if="scope.row.is_expedite">expedite</span><br>
                <div style="color:#909399; margin-top: -5px">Date Filed: <br></div><div style="color:#909399;margin-top: -10px"><b>{{ scope.row.filed_track_date }}</b> ({{ scope.row.filed_track_time}})</div>
              </template>
              
            </el-table-column>
            <el-table-column
              label="Request(s)"
              >

              <template slot-scope="scope">
                <div v-for="(document_requests, index) in scope.row.document_requests" :key="index">
                  <span v-if="document_requests.request_type.doc_type != 'EXPEDITE_FEE' "><li>  {{ document_requests.request_type.request_name }} <span v-if="document_requests.others_request_description">: {{ document_requests.others_request_description }}</span> ({{ document_requests.no_copy }})</li></span>
                </div>
              </template>
              
            </el-table-column>
            
            <!-- <el-table-column
              prop="student_no"
              label="Student Number"
              width="150">
            </el-table-column> -->
            <el-table-column
              label="Name"
              >
              <template slot-scope="scope">
                <span><b>{{ scope.row.last_name }}, {{ scope.row.first_name }} {{ scope.row.middle_name }} {{ scope.row.ext_name }}</b></span>
              </template>
            </el-table-column>
            <el-table-column
              label="Degree Program"
              >
              <template slot-scope="scope">
                <span v-if="scope.row.course">{{ scope.row.course.code }} {{ !scope.row.course.major ? "" : "major in " + scope.row.course.major }}</span>
                <span v-if="!scope.row.course">No course selected</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Total Bill"
              width="120px"
              >
              <template slot-scope="scope">
                <span>
                  {{ formatCurrency(parseFloat(scope.row.recipient_payment.amount)) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="Operations"
              width="100px">
              <template slot-scope="scope">
                <el-button style="width: 100%; padding: 4px; margin-bottom: 5px;" @click="handleClick(scope.row)" type="warning" size="small"><i class="el-icon-view"></i> View</el-button><br>
                <el-button style="width: 100%; padding: 4px; margin-bottom: 5px;" @click.native.prevent="verify(scope.row)" type="success" size="small" :loading="scope.row.loading_verify"><i class="el-icon-check" v-if="!scope.row.loading_verify"></i> Verify</el-button><br>
                <el-button style="width: 100%; padding: 4px; margin-bottom: 5px;" @click.native.prevent="revert(scope.row)" type="info" size="small" :loading="scope.row.loading_revert"><i class="el-icon-caret-left" v-if="!scope.row.loading_revert"></i> Revert</el-button><br>
                <el-button style="width: 100%; padding: 4px; margin-bottom: 5px;" @click.native.prevent="reject(scope.row)" type="danger" size="small" :loading="scope.row.loading_reject"><i class="el-icon-delete" v-if="!scope.row.loading_reject"></i> Reject</el-button><br>
              </template>
            </el-table-column>
          </el-table>
          <div style="justify-content: center; align-items: center;display: flex;">
            <el-pagination
              class="margin-top-20"
              background
              center
              layout="prev, pager, next"
              :total="meta.total"
              :page-size="meta.per_page"
              @current-change="pageChanged"
              :current-page="meta.page"
            >
            </el-pagination>
          </div>
          
          <el-dialog title="Request Details" width="75%"  :visible.sync="showDialog" :destroy-on-close.sync="destroyOnClose" v-if="showDialog">
            <view-request-component @update="refresh()" :selected="selected"></view-request-component>
          </el-dialog>

        </el-card>
    </div>
</template>
  
  <script>
  import ViewRequestComponent from '@/views/private/pages/dashboard/ViewRequestComponent.vue';
  import { Recipient } from '@/api_services/recipient';
  import moment from "moment";
  
  export default {
    props: {
      activeTab: String,
    },
    name: 'Incomming',
    components: {
      ViewRequestComponent
    },
    data() {
      return {
        recipients: null,
        meta: {
          page: 1,
          pages: 1,
          total: 211,
          per_page: 15,
        },
        form:{},
        showDialog: false,
        selected: null,
        loading: false,
        destroyOnClose: true,
        empty: "No available document request(s) in queue.",
        searchForm: {}
      };
    },
    created: function() {
      this.getRecipients()
    },
    watch: {
      activeTab(newTab, oldTab) {
        if (newTab === 'first') {
          this.loading = true
          this.meta.page = 1
          this.clear();
        }
      },
    },
    methods: {
      refresh(){
        this.loading = true
        this.getRecipients();
      },
      formatCurrency(value) {
        return value.toLocaleString('en-PH', {
          style: 'currency',
          currency: 'PHP',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      verify(recipient){
        // console.log(recipient.id)
        this.form.id = recipient.id
        this.form.step = 1
        this.form.amount = 0
        this.form.user_id = this.$store.state.savedUser.id
        let form = Object.assign({}, this.form);
        // console.log(recipient)

        let tot = recipient.recipient_payment.amount
        // console.log(tot)

        this.$confirm('<strong style="color:green;">Verify</strong> <strong>'+ recipient.first_name + ' ' + recipient.last_name +'\'s</strong> information and request(s)?', {
          confirmButtonText: 'Confirm Verification',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          type: 'success'
        }).then(() => {
          this.$confirm('Total payment required is <strong>Php' + tot + '</strong>. Confirm? If no, Please review document fees.', {
            confirmButtonText: 'Proceed verification',
            cancelButtonText: 'Cancel',
            dangerouslyUseHTMLString: true,
            type: 'success'
          }).then((  ) => {
              this.form.amount = tot;
            Recipient.updateDetails(this.form)
            .then(result => {
              if (result) {
                this.$message({
                  message: "Recipient details verified",
                  type: 'success',
                  duration: 1500
                });
              } 
            })
            .catch( error => {
              console.log(error)
            })
            this.getRecipients()

          }).catch(() => {

          });

        }).catch(() => {

        });
      },
      revert(recipient){
        // console.log(id)
        this.form.id = recipient.id
        this.form.step = 98
        this.form.user_id = this.$store.state.savedUser.id
        let form = Object.assign({}, this.form);

        this.$prompt('Please input reason for <strong style="color:#e79500;">revert</strong> for:<br> <strong>'+ recipient.first_name + ' ' + recipient.last_name +'</strong>', {
          confirmButtonText: 'Confirm Revert',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(({ value }) => {
          if(!value){
            this.$message({
              type: 'error',
              message: 'Remarks required'
            });
            return
          }
          this.$message({
            type: 'success',
            message: 'Document request reverted'
          });
          form.revert_remarks = value
          Recipient.updateDetails(form)
          .then(result => {
            if (result) {
              this.$message({
                message: "Recipient Reverted",
                type: 'success',
                duration: 1500
              });
            } 
          })
          .catch( error => {
            console.log(error)
          })
          this.getRecipients()
        }).catch(() => {

        });
      },
      reject(recipient){
        // console.log(id)
        this.form.id = recipient.id
        this.form.step = 99
        this.form.user_id = this.$store.state.savedUser.id
        let form = Object.assign({}, this.form);

        this.$prompt('Please input reason for <strong style="color:red;">rejection</strong> for:<br> <strong>'+ recipient.first_name + ' ' + recipient.last_name +'</strong>', {
          confirmButtonText: 'Confirm Reject',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          type: 'error'
        }).then(({ value }) => {
          if(!value){
            this.$message({
              type: 'error',
              message: 'Remarks required'
            });
            return
          }
          this.$message({
            type: 'success',
            message: 'Document request rejected'
          });
          form.reject_remarks = value
          Recipient.updateDetails(form)
          .then(result => {
            if (result) {
              this.$message({
                message: "Recipient Rejected",
                type: 'success',
                duration: 1500
              });
            } 
          })
          .catch( error => {
            console.log(error)
          })
          this.getRecipients()
        }).catch(() => {

        });
      },
      recipientVerifiedMsg() {
        this.$message({
          message: 'Student information verified. Notification sent through email.',
          type: 'success'
        });
      },
      getRecipients() {
        // this.meta.page = 1;
        Recipient.get({
          params: { 
            step: 1, 
            page: this.meta.page,
            term: this.searchForm.term,
          },
        })
          .then((result) => {
            this.recipients = result.data.data;
            // console.log(this.recipients);

            this.recipients.forEach(function(recipient){
              recipient.loading_verify = false
              recipient.loading_revert = false
              recipient.loading_reject = false
              recipient.created_at_time = moment(recipient.created_at).format('hh:mm A')
              recipient.created_at_label = moment(recipient.created_at).format("MMMM D, YYYY")

              if(recipient.date_request_verified){
                recipient.date_request_verified_time = moment(recipient.date_request_verified).format('hh:mm A')
                recipient.date_request_verified = moment(recipient.date_request_verified).format("MMMM D, YYYY")
              } else {
                recipient.date_request_verified = null
              }

              if(recipient.recipient_payment.payment_date){
                recipient.recipient_payment.payment_date_time = moment(recipient.recipient_payment.payment_date).format("hh:mm A")
                recipient.recipient_payment.payment_date = moment(recipient.recipient_payment.payment_date).format("MMMM D, YYYY")
              } else {
                recipient.recipient_payment.payment_date = null
              }

              recipient.filed_track = recipient.request_tracks.filter(item => item.status == 0)[0];
              recipient.filed_track_date = recipient.filed_track ? moment(recipient.filed_track.created_at).format("MMMM D, YYYY") : '--'
              recipient.filed_track_time = recipient.filed_track ? moment(recipient.filed_track.created_at).format("hh:mm A") : '--'
              
            })

            this.recipients = this.recipients.filter(recipient => recipient.step == 1);

            this.meta = {
              page: result.data.meta.current_page,
              total: result.data.meta.total,
              per_page: result.data.meta.per_page,
              pages: result.data.meta.last_page,
            };
            this.loading = false;
            // console.log(this.recipients);
          })
          .catch((error) => {
            this.loading = false;
            this.recipients = [];
          });
      },
      handleClick(recipient){
        this.showDialog = true
        this.selected = Object.assign({}, recipient)
      },
      pageChanged(val){
        this.loading = true;
        this.meta.page = val;
        this.getRecipients();
      },
      onSearch(){
        this.meta.page = 1;
        this.loading = true;
        this.getRecipients();
      },
      clear(){
        this.searchForm = {}
        this.meta.page = 1;
        this.loading = true;
        this.getRecipients();
      }

    }
  }
  </script>
  
  <style scoped>
  
  </style>
  
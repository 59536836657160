import api from './api'

const API = '/api/documentrequests'

export const DocumentRequest = {
  get: function (params) {
    return api.get(API, params)
  },
  create: function(data) {
    return api.create(API, data);
  },
  update: function (id, params) {
    return api.update(API + '/' + id, params)
  },
  delete: function (id, params) {
    return api.delete(API + '/' + id, params)
  },
  removeRequest: function(params) {
    return api.create(API + '/remove-request/'+params.id, params)
  },
  reselectRequests: function(params) {
    return api.create(API + '/reselect-requests', params)
},
}

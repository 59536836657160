<template>
    <div v-loading="loading">
        <div v-if="status == 2">
            <navbar-component :secured="true"></navbar-component>
            <el-container>
                <sidebar-component ></sidebar-component>
                <el-main>
                    <breadcrumb-component></breadcrumb-component>
                    <hr>
                    <router-view/>
                </el-main>
            </el-container>
        </div>
        <div v-if="status == 1" style="padding: 20px">
            Please wait ....
        </div>
        <div v-if="status == 3" style="padding: 20px">
            Not authenticated. Redirecting to login ...
        </div>
    </div>
</template>

<script>
import NavbarComponent from '@/components/common/NavbarComponent.vue'
import BreadcrumbComponent from '@/components/common/BreadcrumbComponent.vue'
import SidebarComponent from '@/components/common/SidebarComponent.vue'

export default {
  name: 'Main',
  components: { NavbarComponent, SidebarComponent, BreadcrumbComponent },
  data() {
    return {
        status: 0,
        loading: false,
        drawer: false,
    }
  },
    created: function() {
      this.loading = true
        if (!this.$store.state.savedUser) {
            this.status = 3
            this.redirect()
        } else {
            this.status = 2
        }
        this.loading = false
    },
    methods: {
        async redirect() {
            await this.timeOut();
            this.$router.push('login')
        },
        timeOut() {
            return new Promise(resolve => {
                setTimeout(resolve, 2000);
            });
        },
    }
}
</script>
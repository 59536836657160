<template>
  <div>
    <navbar-component></navbar-component>
    <div v-if="is_close" class="request">
      <el-card>
        <div class="brand" style="text-align: center; margin-top: 20px;">
          <img style="width: 50%; float:center;" :src="require('@/assets/images/close-window.png')" alt="verification image">
        </div>
        <div style="text-align:center; background-color: #ffecea; padding: 20px; margin-top: 20px;">
          <h1 style="color: #000000; margin: 0;">Request portal unavailable!</h1>
          <span v-if="is_holiday_close == 1" style="font-size: 18px; color: #000000; margin-top: 10px;">The request portal is closed on holidays.</span><br>
          <span v-if="is_holiday_close != 1" style="font-size: 18px; color: #000000; margin-top: 10px;">{{ is_on_maintenance == '1' ? is_maintenance_text : is_unavailable_text }}</span>
        </div>
        <div style="text-align: center; margin-top: 30px; margin-bottom: 20px;">
          <a type="primary" style="background-color: #606266; color: #ffffff; width: 100%; cursor: pointer; border: solid 1px #606266; padding: 8px; border-radius: 8px;" @click="goTo('/')"><i class="el-icon-caret-left"></i>Go Back</a>
        </div>
      </el-card>
    </div>
    <div v-else class="request">
      <div>
        <el-card v-loading="loading_card">
          <div  v-loading.fullscreen.lock="status == 'loading'" style="padding: 10px;min-height: 50px;"
            element-loading-text="Generating Request Code..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">

            <el-form
              class="request-form"
              :model="form"
              :rules="rules"
              ref="form"
              @submit.native.prevent="submit('form')">
        
              <div>
                <el-steps space="200px" simple status="success" :active="0">
                  <el-step :title="!smallerWidth ? 'Submit Form' : blankTitle" icon="el-icon-s-order"></el-step>
                  <el-step :title="!smallerWidth ? 'Verification' : blankTitle" icon="el-icon-s-claim"></el-step>
                  <el-step :title="!smallerWidth ? 'Payment' : blankTitle" icon="el-icon-upload"></el-step>
                </el-steps>
              </div>
              <hr>
            
              <h3 style="color: #007a00;">Enter your active email address</h3>
              <div style="max-width: 750px;">
                <el-alert
                  type="info"
                  show-icon
                  :closable=false>
                  <span style="font-size: small;">Ensure that the email address you provide is active, as this will be your primary point of contact 
                  throughout the entire document request process. An active email is essential for receiving important 
                  updates, notifications, and instructions related to your request.</span>
                </el-alert>
              </div>
              
              <br>
              <el-form-item prop="email">
                <el-input v-model="form.email" placeholder="juandelacruz@vsu.edu.ph" type="email">
                <template slot="prepend">Email: </template></el-input>
              </el-form-item>

              <requests-lists-component :form.sync="form" @updateMergedSelection="handleMergedSelectionUpdate"></requests-lists-component>
              <br>
              <el-alert
                type="info"
                show-icon
                :closable=false>
                <span style="font-size: small;">Check the checkbox to proceed.</span>
              </el-alert>
              <template>
                <p style="font-size: medium;"><el-checkbox v-model="checked"> </el-checkbox> I have read and agree to Visayas State University's 
                  <a @click="dialogVisible = true" style="cursor: pointer">Privacy Notice</a></p>
              </template>

              

              <el-form-item>
                <el-button
                  :loading="loading"
                  class="request-button"
                  type="primary"
                  native-type="submit"
                  block
                  :disabled="!checked"
                >Proceed</el-button>
              </el-form-item>

              <el-dialog
                :visible.sync="submitDialog"
                width="40%"
                :close-on-click-modal="false"
                class="responsive-dialog"
                >
                <span>The overall fees will be computed at a later stage. Do you confirm your document request?</span>
                <span slot="footer" class="dialog-footer">
                  <el-button  @click="submitDialog = false">Back</el-button>
                  <el-button type="primary" @click="confirmSubmit" :loading="loading">Yes</el-button>
                </span>
              </el-dialog>

            </el-form>
          </div>
        </el-card>
      </div>
      
      <!-- <request-component v-if="check_request_url"></request-component> -->
      <el-dialog
        title="Visayas State University's Privacy Policy"
        :visible.sync="dialogVisible"
        width="70%"
        center>
        <terms-and-condition-component></terms-and-condition-component>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">Close</el-button>
        </span>
      </el-dialog>
    </div>
    </div>
  </template>
  
  <script>

  import { Setting } from '@/api_services/setting'
  import { Recipient } from '@/api_services/recipient'
  import TermsAndConditionComponent from '@/components/common/TermsAndConditionComponent.vue';
  import NavbarComponent from '@/components/common/NavbarComponent.vue'
  import RequestsListsComponent from '@/views/form/RequestsListsComponent.vue'
  
  export default {
    components: { TermsAndConditionComponent, NavbarComponent, RequestsListsComponent },
    name: 'Request',
    data() {
      return {
        submitDialog: false,
        dialogVisible: false,
        valid: false,
        check_request_url: false,
        checked: false,
        loading: false,
        submitted: false,
        submitting: false,
        form: {
          email: null,
          all_selected: null,
        },
        selectedRows: [],
        rules: {
          email: [{required: true, message: "Email is required", trigger: "blur"}],
        },
        count: 0,
        status: 'unload',
        loading_card: false,
        mergedSelection: [],
        smallerWidth: false,
        blankTitle: "",
        is_close: null,
        is_unavailable_text: "",
        is_holiday_close: 0,
        yearly_non_woking_date: null
      };
    },
    mounted() {
      // Add a listener for window resize event
      window.addEventListener("resize", this.handleResize);
      // Initial check for the window size
      this.handleResize();
    },
    created: function() {
      this.checkRoute()
      this.getSettings()
    },
    methods: {
      getSettings(){
        Setting.getIsUnavailableSetting()
        .then((result) => {
          console.log(result.data);
          // console.log(this.is_unavailable)
          this.is_close = result.data.is_close
          this.is_unavailable_text = result.data.is_unavailable_text
          this.is_on_maintenance = result.data.is_on_maintenance
          this.is_maintenance_text = result.data.is_maintenance_text
          this.is_holiday_close = result.data.is_holiday_close
          this.yearly_non_woking_date = result.data.yearly_non_woking_date
        }).catch((error) => {
          console.log(error);
        });
      },
      handleResize() {
        // Update the smallerWidth property based on the window width
        this.smallerWidth = window.innerWidth <= 600; 
      },
      checkRoute(){
        // console.log(this.$route.query);
        if(this.$route.query.email === null || !this.$route.query.recipient_rqst_code  === null){
          this.status='unload'
        }
        
      },
      async submit() {
        const valid = await this.$refs.form.validate();
        this.form.all_selected = this.mergedSelection
        // console.log(this.form)

        if(this.form.all_selected == 0){
          this.$alert('Please Select a Document to Request', 'Selection Required', {
            confirmButtonText: 'OK',
          });
        } else {
          this.valid = true;
          this.submitDialog = true
        }
        // console.log(this.form);
      },
      confirmSubmit() {
        // console.log(this.form);
        this.status='loading'
        
        if (this.submitting) { return; }
        this.submitting = true;
        Recipient.storeRecipient(this.form)
        .then(result => {
          this.submitted = true
          // console.log(result)
          const newQueryParameters = {
            email: result.data.data.email,
            recipient_rqst_code: result.data.data.recipient_rqst_code,
            recipient_token: null,
          };

          // Use this.$router.push to navigate with the updated query parameters
          this.$router.push({ path: '/recipient-form', query: newQueryParameters });
          location.reload();
        })
        .catch( error => {
          this.status = 'unload'
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
              duration: 3000
            });
          }
        }).then(() => {
          this.submitting = false;
        });
        if (!this.submitted) {this.submitting = false;}
      },
      handleMergedSelectionUpdate(mergedSelection) {
        this.mergedSelection = mergedSelection
        // console.log(this.mergedSelection);
      },
      goTo(path) {
        this.$router.push({path: path})
      },
    }
    
  }
  </script>
  
  <style scoped>
  body {
    background-color: #ffffff!important;
  }
  .request {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 70px;
  }
  .request-button {
    width: 100%;
    margin-top: 20px;
  }
  .copies-label{
    margin-right: 10px;
  }
  .copies-input{
    width: 95%;
    display: flex;
    align-items: center;
  }
  .el-button--primary.is-disabled{
    /* color: #FFF;
    background-color: #a0cfff;
    border-color: #a0cfff; */
    background-color: #ffcc004a !important;
    border-color: #ffcc004a !important;
    color: #0933007d !important;
  }
  .el-button--primary{
    background-color: #ffcc00 !important;
    border-color: #ffcc00 !important;
    color: #093300 !important;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #ffcc00!important;
    border-color: #ffcc00!important;
  }
  /* .request-form {
    width: 50%;
  } */
  </style>
  
  
  
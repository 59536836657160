import Vue from 'vue'
import Vuex from 'vuex'
import { LocalStorageService } from '@/services/LocalStorageService'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {},
  state: {
    roles: [
      {id: 'admin', value: 'Admin'},
      {id: 'accounting', value: 'Accounting'},
      {id: 'staff', value: 'Staff'},
      {id: 'registrar', value: 'Registrar'},
      {id: 'course in-charge', value: 'Course In-charge'}
    ],
    payment_method:[
      'VSU CASHIER', 'LANDBANK', 'PALAWAN EXPRESS'
    ],
    request_purpose_types:[
      {id: 1, value: 'FOR EMPLOYMENT'},
      {id: 2, value: 'FOR BOARD EXAM'},
      {id: 3, value: 'FOR SCHOLARSHIP'},
      {id: 4, value: 'FOR FURTHER STUDIES'},
      {id: 5, value: 'FOR TRANSFER SCHOOL'},
      {id: 6, value: 'PRC LICENSURE WITHOUT EXAM'},
      {id: 7, value: 'OTHERS'}
    ],
    request_purpose_types_2:[
      {id: 1, value: 'FOR EMPLOYMENT'},
      {id: 2, value: 'FOR BOARD EXAM'},
      {id: 3, value: 'FOR SCHOLARSHIP'},
      {id: 4, value: 'FOR FURTHER STUDIES'},
      {id: 5, value: 'FOR TRANSFER SCHOOL'},
      {id: 6, value: 'PRC LICENSURE WITHOUT EXAM'},
      {id: 7, value: 'WES ABROAD'},
      {id: 8, value: 'SPANTRAN ABROAD'},
      {id: 9, value: 'NCLEX ABROAD'},
      {id: 10, value: 'OTHERS'}
    ],
    request_status:[
      {id: 0, value: 'INITIAL_STATUS', label: "(0) Initial Status"},
      {id: 1, value: 'REQUEST_DETAILS_SUBMITTED', label: "(1) Request Details Submitted"},
      {id: 2, value: 'REQUEST_VERIFIED', label: "(2) Request Verified" },
      {id: 3, value: 'PAYMENT_SUBMITTED', label: "(3) Payment Submitted" },
      {id: 4, value: 'PAYMENT_VERIFIED', label: "(4) Payment Verified" },
      {id: 5, value: 'PROCESSING_PERIOD', label: "(5) Processing Period" },
      {id: 6, value: 'READY_FOR_RELEASE', label: "(6) Ready for Release" },
      {id: 7, value: 'DOCUMENT_CLAIMED', label: "(7) Document Claimed" },
      {id: 98, value: 'DOCUMENT_REVERTED', label: "(98) Document Reverted" },
      {id: 99, value: 'DOCUMENT_REJECTED', label: "(99) Document Rejected"}
    ],

    location_use_types:[
      {id: 1, value: 'LOCAL'},
      {id: 2, value: 'ABROAD'}
    ],
    study_use_types:[
      {id: 1, value: 'OUTSIDE VSU'},
      {id: 2, value: 'WITHIN VSU'}
    ],
    sexes:[
      'MALE', 'FEMALE'
    ],
    is_presently_enrolled_choices:[
      'YES', 'NO'
    ],
    is_graduate_choices:[
      'YES', 'NO'
    ],
    doc_types:[
      'DOC', 'CERT', 'STAMP'
    ],
    is_with_doc_stamp_choices:[
      'YES', 'NO'
    ],
    meta: { page: 1, total: 0, pages: 1 },
    savedUser: LocalStorageService.getUser(),
    savedToken: LocalStorageService.getToken(),
    requestInfo: LocalStorageService.getRequest(),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+LocalStorageService.getToken()
    },
    formDataHeaders: {
      'Content-Type': 'multipart/form-data',
      'Authorization' : 'Bearer '+LocalStorageService.getToken()
    }
  },
  mutations: {
    saveUser: (state, user) => {
      LocalStorageService.setUser(user)
      state.savedUser = user;
    },
    saveMeta: (state, meta) => {
      state.meta = meta
    },
    saveRequest: (state, requestInfo) => {
      state.requestInfo = requestInfo;
      LocalStorageService.setRequest(requestInfo)
    },
    saveToken: (state, token) => {
      LocalStorageService.setToken(token)
      state.savedToken = token
      state.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer '+token
      };
      state.formDataHeaders = {
        'Content-Type': 'multipart/form-data',
        'Authorization' : 'Bearer '+token
      };
    }
  }
})

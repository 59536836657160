<template>
    <el-form ref="form" :model="form" :rules="rules" style="padding-left: 15px; padding-right: 15px;">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
          <el-form-item>
            <div class="custom-cell">
              <span style="background: #007a00; color: white; padding: 2px 7px 2px 7px; border-radius: 10px;">{{ form.index }}.</span>  
              <span style="font-size: 18px;"> <strong><u>{{ form.request_type.request_name }}</u></strong></span>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 10px;">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-row v-if="form.request_type.request_name == 'Others'">
            <el-col :class="{ 'warning': !form.others_request_description }" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <span style="color: red;">* </span><span>Request:</span><br>
              <!-- <span style="color: #afafaf; font-size: 11px; font-style: italic;">ex. 1ST SEMESTER, 2024-2025</span> -->
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <!-- <el-select style="width: 100%;" v-model="form.location_use" placeholder="Select">
                <el-option :label="location_use.value" :value="location_use.value" v-for="location_use in $store.state.location_use_types" :key="location_use.id"></el-option>
              </el-select>  -->
              <el-input style="width: 100%;" placeholder="Indicate name of document to request" v-model="form.others_request_description" 
              @input="(val) => (form.others_request_description = form.others_request_description.toUpperCase())"></el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-row>
            <el-col :class="{ 'warning': !form.request_purpose }" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <span style="color: red;">* </span><span>Purpose:</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-select style="width: 100%;" v-model="form.request_purpose" placeholder="Select">
                <el-option
                  v-for="item in request_purpose_types_op"
                  :key="item.id"
                  :label="item.value"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-row v-if="form.request_purpose == 'OTHERS'">
            <el-col :class="{ 'warning': !form.request_purpose_others }" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <span style="color: red;">* </span><span>Indicate purpose:</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-input style="width: 100%;" placeholder="Indicate purpose" v-model="form.request_purpose_others" @input="(val) => (form.request_purpose_others = form.request_purpose_others.toUpperCase())"></el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top: 12px;">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-row>
            <el-col :class="{ 'warning': !form.location_use }" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <span style="color: red;">* </span><span>For Local or Abroad?</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-select style="width: 100%;" v-model="form.location_use" placeholder="Select">
                <el-option :label="location_use.value" :value="location_use.value" v-for="location_use in $store.state.location_use_types" :key="location_use.id"></el-option>
              </el-select> 
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-row v-if="form.location_use == 'LOCAL'" >
            <el-col :class="{ 'warning': !form.study_use }" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <span style="color: red;">* </span><span>For Local:</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-select style="width: 100%;" v-model="form.study_use" placeholder="Select">
                <el-option :label="study_use.value" :value="study_use.value" v-for="study_use in $store.state.study_use_types" :key="study_use.id"></el-option>
              </el-select> 
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top: 12px;">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-row v-if="form.request_type.ask_sem">
            <el-col :class="{ 'warning': !form.semester_and_school_year }" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <span style="color: red;">* </span><span>Semester and School Year:</span><br>
              <!-- <span style="color: #afafaf; font-size: 11px; font-style: italic;">ex. 1ST SEMESTER, 2024-2025</span> -->
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <!-- <el-select style="width: 100%;" v-model="form.location_use" placeholder="Select">
                <el-option :label="location_use.value" :value="location_use.value" v-for="location_use in $store.state.location_use_types" :key="location_use.id"></el-option>
              </el-select>  -->
              <el-input style="width: 100%;" placeholder="ex. 1ST SEMESTER, 2024-2025" v-model="form.semester_and_school_year" 
              @input="(val) => (form.semester_and_school_year = form.semester_and_school_year.toUpperCase())"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-row v-if="form.request_type.ask_subject">
            <el-col :class="{ 'warning': !form.subject }" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <span style="color: red;">* </span><span>Subject:</span><br>
              <!-- <span style="color: #afafaf; font-size: 11px; font-style: italic;">ex. MATHEMATICS</span> -->
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <!-- <el-select style="width: 100%;" v-model="form.study_use" placeholder="Select">
                <el-option :label="study_use.value" :value="study_use.value" v-for="study_use in $store.state.study_use_types" :key="study_use.id"></el-option>
              </el-select>  -->
              <el-input style="width: 100%;" placeholder="ex. MATHEMATICS" v-model="form.subject" 
              @input="(val) => (form.subject = form.subject.toUpperCase())"></el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row style="margin-top: 20px;">
        <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
          <!-- <span style="color: #fff;">.</span> -->
          <span style="color:#949494">Additional Remarks:</span>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="You can leave additional remarks / other details  for your request here..."
            v-model="form.request_notes"
            maxlength="120"
            show-word-limit
            >
          </el-input>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="2" :xl="2">
          <span style="color: #fff;">.</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
          <div>
            <span style="color: #fff;">.</span>
          </div>
          <div style="text-align:right;">
            <span style="color: #949494;">Copies: <strong>{{ form.no_copy }}</strong></span><br>
            <span style="color: black; font-size: 20px;">*</span><strong>Php {{ formatCurrency(parseFloat(form.tot_fee)) }}</strong>
          </div>
        </el-col>
      </el-row>
  </el-form>
</template>

<script>

export default {
  name: 'DocumentForm',
  props: ['document'],
  data() {
    return {
      form: this.document,
      request_purpose_types_op: null,
      rules: {
        request_purpose: [
                { required: true, message: 'Purpose is required', trigger: 'blur' },
            ]
        }
      
    }
  },
  watch:{
    document: {
        deep: true,
        handler() {
          this.validateForm()
        }
    }
  },
  created: function() {
      this.form.valid = false
      // console.log(this.form)
      this.$emit('update:document', this.form)
      this.document.request_type_id == 5 ? this.request_purpose_types_op = this.$store.state.request_purpose_types_2 : this.request_purpose_types_op = this.$store.state.request_purpose_types
      // console.log(this.document)
      this.validateForm()
  },
  methods: {
    formatCurrency(value) {
        return value.toLocaleString('en-PH', {
            style: 'currency',
            currency: 'PHP',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    },
    timeOut(time) {
        return new Promise(resolve => {
            setTimeout(resolve, time);
        });
    },
    async validateForm() {
      if(this.form.request_purpose != 'OTHERS'){
        this.form.request_purpose_others = null
      }

      // if(this.form.request_purpose != 'FOR EMPLOYMENT' && this.form.request_purpose != 'FOR FURTHER STUDIES'){
      //   this.form.location_use = null
      //   this.form.study_use = null
      // }

      // if(this.form.request_type_id == 4){
      //   this.form.location_use = null
      //   this.form.study_use = null
      // }

      if(this.form.location_use != 'LOCAL'){
        this.form.study_use = null
      }

      await this.timeOut(100);
      this.form.valid = false
      this.$emit('update:document', this.form)
      let valid = await (this.form.request_purpose == null ? false : true)
      let others_valid = true
      let others_desc_valid = true

      if(this.form.request_type.request_name == 'Others'){
        others_valid = this.form.others_request_description == null || this.form.others_request_description == "" ? false : true
      }

      if(this.form.request_type.ask_sem){
        valid = this.form.semester_and_school_year == null ? false : true
      }

      if(this.form.request_type.ask_subject){
        valid = this.form.subject == null ? false : true
      }

      valid = this.form.location_use == null ? false : true

      if(this.form.location_use == 'LOCAL'){
        valid = this.form.study_use == null ? false : true
      }

      if(this.form.request_purpose == 'OTHERS'){
        others_desc_valid = this.form.request_purpose_others == null || this.form.request_purpose_others == "" ? false : true
      }

      if(others_valid == false || others_desc_valid == false){ valid = false }
      
      this.form.valid = valid
      this.$emit('update:document', this.form)
    },
  }
}
</script>

<style>
.warning {
  color: #ff0000;
  background-color: #ffecec;
  border-radius: 5px;
  padding-left: 5px;
}
</style>
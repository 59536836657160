<template>
     <div class="terms">
      <div style="text-align: center;">
        <h1>General Privacy Notice</h1>
        <span>Visayas State University</span><br>
        <b>General Privacy Notice</b>
      </div>
      

      <p>This is VISAYAS STATE UNIVERSITY (VSU) general statement on its data processing activities to notify data subjects of categories
       of personal data processed and the purpose and extent of processing. VSU is committed to comply with the Philippine Data Privacy 
       Act of 2012 (DPA) <a href="http://www.officialgazette.gov.ph/2012/08/15/republic-act-no-10173/">http://www.officialgazette.gov.ph/2012/08/15/republic-act-no-10173/</a> in order to protect your right to data 
       privacy.</p>

      <p>
        This is not a consent form but an announcement how VISAYAS STATE UNIVERSITY processes personal data. VISAYAS STATE UNIVERSITY 
        Policies on data privacy are on the VSU Portal at (<a href="vsu.edu.ph/privacy..">
        vsu.edu.ph/privacy..</a>)
      </p>
      

      <b>Acts of Processing</b>

      <p>Visayas State University processes Personal Data to:</p>

      
      <ol style="font-size: 20px;">
        <li>
          Perform its obligations, exercise its rights, and conduct its associated functions as:
          <ol type="a">
            <li>
              an instrumentality of the government;
            </li>
            <li>
              a higher education institution.
            </li>
          </ol>
        </li>
        <li>
          Pursue its purposes and mandates under Republic Act 9158 as amended by Republic Act 9437 as a “the state university”.
        </li>
        <li>
          For each particular unit of VSU, conduct all acts reasonably foreseeable from and customarily performed by similar bodies;
        </li>
        <li>
          Decide and act for the holistic welfare of its students, their parents and guardians, faculty, staff, researchers, alumni, and 
      VSU community; and
        </li>
        <li>
          Manage and administer its internal and external affairs as an academic institution, as an instrumentality of the government, 
      and as a juridical entity with its own rights and interests.
        </li>
      </ol>


      <p>The  Visayas State University Website uses cookies to prevent security risks, recognize that the user is logged in, customize 
      the user’s browsing experience, store authorization tokens, permit social media sharing, troubleshoot issues, and monitor 
      anonymized or aggregated statistics.</p>

      <b>Personal Data Collected</b>

      <p>Visayas State University collects including but not limited to the following Personal Data:</p>

      <ul>
        <li>Personal details such as name, birth, gender, civil status and affiliations;</li>
        <li>Contact information such as address, email, mobile and telephone numbers;</li>
        <li>Academic information such as grades, course and academic standing;</li>
        <li>Employment information such as government-issued numbers, position and functions;</li>
        <li>Applicant information such as academic background and previous employments;</li>
        <li>Medical information such as physical, psychiatric and psychological information.</li>
      </ul>
      
      <b>Collection Method</b>

      <p>Visayas State University collects Personal Data physically through printed forms, attachments, and other documents 
      required by the University, its academic units, and its administrative offices.</p>

      <p>Visayas State University collects Personal Data electronically through electronic forms, via email, or inputting 
      of information directly by the data subject or by the concerned faculty and administrative staff.</p>
      

      <b>Timing of Collection</b>

      <p>Visayas State University generally collects Personal Data on the onset of the relationship with the VSU clients such as 
      upon application of the student, faculty, staff or other VSU Staff; or before the commencement of the engagement of the student, 
      faculty and staff.</p>
      
      <p>Visayas State University also collects information specific to an action or decision that the VSU Person will conduct with 
      respect to VSU or with respect to other VSU People.</p>
      

      <b>Purpose of Collected Personal Data</b>

      <p>Visayas State University collects and processes Personal Data for the following purposes:</p>
      
      <ol>
        <li>Purposes necessary for VSU to perform its obligations, exercise its rights, and conduct its associated functions as an 
      instrumentality of the government and as a higher education institution;</li>
        <li>Purposes to pursue VSU’s mandates under existing laws and regulations;</li>
        <li>Purposes to perform acts and decisions necessary for VSU to manage and administer its internal and external affairs
       as a juridical entity with its own rights and interests;</li>
          <li>
            Compliance with legal, regulatory, administrative or judicial requirements including audit, reporting and transparency 
      requirements;
          </li>
          <li>Records and account purposes such as:</li>
          <li>Creation and update of record entries and accounts;</li>
          <li>Creation and maintenance of student, faculty or staff records and accounts, electronic or otherwise;</li>
          <li>Security and community affairs purposes such as;</li>
          <li>Maintenance of safety, security, peace and order in and around VSU campuses as well as venues which VSU has 
      presence or activities;</li>
          <li>Prevention of crimes and damages to persons or property within or outside the premises of VSU.</li>
      </ol>

      <b>Storage, Location, Transmission and Transfer of Personal Data</b>

      <p>Personal Data are stored in physical and electronic data processing systems managed by various units and offices of VSU. 
      Physical records are generally stored in folders or envelopes in drawers or shelves. Electronic records are generally 
      stored in servers in the possession or control of VSU or in cloud storage controlled by VSU.</p>

      <p>Personal Data are transmitted and transferred in accordance with Chapter III of the Data Privacy Act of 2012 and Rule V of its 
      Implementing Rules and Regulations.</p>

      <b>Method of Use</b>

      <p>Visayas State University uses Personal Data proportionately as necessary for its legitimate purposes under the VSU Privacy Policy. 
      Personal Data are used in accordance with the Data Privacy Act of 2012, issuances of the National Privacy Commission, the National 
      Archives of the Philippines Act of 2007, issuances of the National Archives of the Philippines, and policies, rules and guidelines
       of the Visayas State University.</p>


      <b>Retention Period</b>

      <p>Visayas State University retains data in accordance with the Records and Archives Office (RAO) Quality Procedure on 
      Disposition of Records. In the absence of an applicable rule of retention, Personal Data shall be retained in accordance 
      with the practices of government bodies with analogous functions.</p>

      <b>Participation of VSU Faculty, Staff and Students</b>

      <p>VSU Faculty, Staff and Students have the following rights:</p>

      <ol>
        <li>Right to be informed; (this right entitles you to know whether personal data pertaining to you shall be, are being, or 
        have been processed, including the existence of automated decision-making and profiling.)</li>
        <li>Right to object (this right entitles you to prevent the processing of your personal data, including processing for direct 
        marketing, automated processing or profiling. ) subject to VSU’s possible consequent failure to conduct academic, administrative 
        and other functions or services;</li>
        <li>Right to access; (this right entitles you to know whether we hold personal data about you and, if we do, to obtain 
        information on and a copy of that personal data.)</li>
        <li>Right to rectification; (this right entitles you to have your personal data corrected if it is found to be outdated, 
        inaccurate, or incomplete.)</li>
        <li>Right to erasure or blocking of Personal Data (this right entitles you to request the erasure of your personal data, 
        such as in cases where your personal data is no longer necessary to achieve the legitimate business purpose of its use 
        or processing.) subject to those personal data which are not part of VSU’s public records as an instrumentality of the 
        government or as a state university; and</li>
        <li>Right to damages (this right entitles you to be indemnified for any damages sustained due to such inaccurate, incomplete, 
        outdated, false, unlawfully obtained or unauthorized use of personal data, taking into account any violation of your rights 
        and freedoms as data subject.) but which is subordinate to the non-liability of Visayas State University arising from the 
        incidental damages due to VSU’s pursuance of its mandates or compliance with its legal obligations.</li>
        <li>Right to data portability (this right entitles you to receive a copy of personal data that you have provided to us 
        (in a structured, commonly used and machine-readable format).</li>
      </ol>

      <p>To exercise these rights, you may get in touch with our Data Protection Officer through the contact details provided below. 
      In some instances, we may request for supporting documents or proof before we effect any requested changes to your personal data.</p>

      <p>VSU Faculty, Staff and Students have the following responsibilities:</p>

      <ol>
        <li>Respect the data privacy rights of others;</li>
        <li>Report any suspected Security Incident or Personal Data Breach to the VSU Data Protection Office;</li>
        <li>Provide the Visayas State University true and accurate Personal Data and other information;</li>
        <li>Not disclose to any unauthorized party any non-public confidential, sensitive or personal information obtained or learned in 
        confidence from VSU; and</li>
        <li>Abide by the policies, guidelines and rules of Visayas State University on data privacy, information security, records management, 
        research and ethical conduct.</li>
      </ol>

      <b>Effectivity.</b>

      <p>The VSU Data Protection Officer may promulgate policies, guidelines and rules which are not 
      inconsistent with this Policy.</p>

      <p>If any law or regulation cited in this Policy is amended or superseded, then it shall be considered that this Policy is 
      referring to such amending or superseding law or regulation, without prejudice to a person’s right against retroactive effect 
      of laws.</p>

      <p>If any part of this Policy is declared null and void, then the other unaffected parts shall remain in full force and
       effect.</p>

      <b>Data Protection Officer</b>

      <p>Data Protection Officer, reporting to the VSU President, is tasked to protect the privacy of personal information to, in, 
      and from VSU with the following functions:</p>

      <p>(1) Comply with data privacy laws and regulations including implementing data protection measures, submitting 
      regulatory requirements, and managing privacy incidents.</p>
      
      <p>(2) Provide units of the University support services including formulating policies, training people, and 
      conducting audits with remediation solutions.</p>
      
      <p>(3) Prevent legal, financial, and operational risks by improving current and future forms, contracts, processes, 
      and I.T. systems to secure against leakage of information.</p>
      
      <p>(4) Develop in the University a culture of respect for privacy by formulating policies and establishing practices 
      at par with domestic and international standards.</p>
      

      <b>Inquiries</b>

      <p>Inquiries and concerns on data privacy may be directed to the VSU Data Protection Office:</p>

      <p>Address:  Office of the Data Protection Officer</p>

      <p>GF Administration Building</p>

      <p>Visayas State University</p>

      <p>Brgy. Pangasugan, Visca</p>

      <p>Baybay City, Leyte</p>

      <ul>
        <li>Phone: (053) 563 7643</li>
        <li>Trunkline: (053)565-0600</li>
        <li>Email: dpo@vsu.edu.ph</li>
      </ul>
      
      <p>
        Visayas State Univeristy Privacy resources are on the VSU Privacy Portal at (<a href="vsu.edu.ph/privacy">vsu.edu.ph/privacy</a>.)
      </p>
      
    </div>
</template>

<script>

export default {
  name: 'TermsAndComponent'
}
</script>

<style scoped>

.red {
  color: #f44336 !important
}

.yellow {
  color: #ffcc00 !important;
}

.blue {
  color: #1658ea !important;
}

.terms{
  font-family: monospace;
}

p {
  text-align: justify !important;
  padding-top: 10px !important;
}

.text-large{
  font-size: 20px;
}

p, b, span, ul li, ol li {
  font-size: 20px !important;
}
</style>
<template>
  <div>
    <navbar-component></navbar-component>
    <div class="track" >
      <el-card style="max-width: 600px;" v-loading="loading">
        <div style="text-align: right; background-color: #003300; padding: 10px 20px 3px 20px; margin-bottom: 15px; border-radius: 5px;">
          <a type="primary" style="color: #ffffff; width: 100%; cursor: pointer;" @click="goTo('/')"><i icon="el-icon-caret-left"></i>Go Back</a>
          <br>
          <br>
          <el-form
              class="track-form"
              :model="form"
              :rules="rules"
              ref="form"
              @submit.native.prevent="submit('form')"
            >
            <el-form-item prop="request_code">
              <el-input placeholder="Please enter Request Code here " class="input" v-model="form.request_code" @input="onInputChange">
                
                <el-button type="primary" native-type="submit" slot="append" icon="el-icon-place" :loading="loading_track"> Track</el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <div v-if="recipient" v-loading="loading_card">
          <div v-if="recipient.step == 6 || recipient.step == 7" style="margin-bottom: 15px;">
            <el-card style="border-left: solid 3px #ffcc00!important; background-color: #fffae7;" shadow="none">
              <div style="max-width: 680px;">
                <p>
                  If you havent filled out and submitted the Citizen/Client Satisfaction form:<br><br>
                  Your feedback on our services is important to us to assist you better and improve our processes and services. Please let us know your experience by clicking the button below and filing out the Citizen/Client Satisfaction form.<br><br>
                  <a style="cursor: pointer;" class="link_button" target="_blank" @click="goToCS('/cs-rate')">Citizen/Client Satisfaction Form </a><br><br>
                  Thank you!
                </p>
              </div>
            </el-card>
          </div>
        
          <el-card v-if="recipient.step > 0">
            <div class="req-details">
              <span style=" color: #007a00;"><b>Recipient's Details</b></span>
            </div>
            <div class="req-details">
              <span style=" color: rgb(43, 74, 74); margin-top: 20px;">Request Code: <b>{{ recipient.recipient_rqst_code }}</b></span>
            </div>
            <div class="req-details">
              <span style=" color: rgb(43, 74, 74);">Name: <b>{{ recipient.first_name }} {{ recipient.middle_name }} {{ recipient.last_name }} {{ recipient.ext_name }}</b> </span>
            </div>
            <div class="req-details">
              <span style=" color: rgb(43, 74, 74);">Date Filed: <b>{{ recipient.created_at }}</b></span>
            </div>
            <div class="req-details">
              <span style=" color: rgb(43, 74, 74);">Date of payment verified: <b>{{ recipient.recipient_payment.payment_date ? recipient.recipient_payment.payment_date : "Payment unverified" }}</b></span>
            </div>
            <div class="req-details">
              <span style=" color: rgb(43, 74, 74);">Date start of process: <b >{{ recipient.date_start_process ? recipient.date_start_proc  : "Not yet on queue" }}</b></span>
            </div>
            <div class="req-details">
              <span style=" color: rgb(43, 74, 74);"><span>{{ recipient.pickup_track ? "Total Day(s) of Processing:" : "Day(s) in process:" }}</span> 
              <b :style="{ 'color': '#ffffff', 'border-radius': '10px', 'padding': '2px 6px 2px 6px', 'background-color': recipient.date_start_process ? getBackgroundColor(recipient.date_start_process) : '#666666' }">
                {{ recipient.date_start_process ? calculateDaysInQueue(recipient.date_start_process)  : "Not yet on queue" }}
              </b></span>
            </div>
            <div style="margin-top: 10px;">
              <el-collapse accordion>
                <el-collapse-item name="1">
                  <template slot="title">
                    <i class="el-icon-goods" size="large"></i> Requested Documents 
                  </template>
                  <el-table :show-header="false" border :data="recipient.document_requests" style="width: 100%;">
                    <el-table-column>
                        <template slot-scope="scope">
                            <el-row :gutter="10">
                                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"> 
                                    <span v-if="scope.row.request_type.doc_type == 'EXPEDITE_FEE'"> Additional fee: </span>
                                    <span style="font-size: 13px;"> <strong><u>{{ scope.row.request_type.request_name }}</u> <u v-if="scope.row.others_request_description">: {{ scope.row.others_request_description }}</u></strong></span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                    <div style="float: right; text-align: right;">
                                        
                                        <span style="color: #949494; font-size: 12px;" v-if="scope.row.request_type.doc_type != 'EXPEDITE_FEE'">Copies: <strong>{{ scope.row.no_copy }}</strong></span><br>
                                        <span style="font-size: 12px;"><strong>Php {{ formatCurrency(parseFloat(scope.row.fee)) }}</strong></span>
                                    </div>
                                </el-col>
                            </el-row>
                            
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align:right; margin-top: 15px; margin-bottom: 15px;">
                  <el-card style="border-left: solid 3px #007a00!important;" shadow="none">
                    <b style="font-size: 18px;"> <span style="color: black; font-size: 20px;" v-if="recipient.step < 2">*</span> TOTAL: {{ formatCurrency(parseFloat(recipient.recipient_payment.amount)) }}</b><br>
                  </el-card>
                  <div v-if="recipient.step < 2">
                    <span style="color: black; font-size: 20px;" >*</span><span style="font-size: 12px; color: #949494;"> <i>Estimated amount. The final bill will be reflected after verification of your request(s).</i></span>
                  </div>
                </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <!-- <el-alert v-if="recipient.step != 99" style="margin-top: 10px;"
              title="Requested docs will be available within 7-10 working days from the date of payment has been verified."
              type="info"
              :closable="false"
              show-icon>
            </el-alert> -->
            <el-alert v-if="recipient.step == 99" style="margin-top: 5px;"
              title="Document request rejected"
              description="Check your email for details"
              type="warning"
              :closable="false"
              show-icon>
              <template slot="description">
                {{recipient.reject_remarks}}
              </template>
            </el-alert>
            
          </el-card>
          <el-card v-if="recipient.step == 0" >
            <div v-loading="true" style="padding-top: 20px;"></div>
            <div style="margin-top: 20px;">
              <span ><b>Oops! looks like you are not done yet with your request...</b></span><br>
              <span >Please click the button bellow to continue</span><br>
              <el-button type="primary" style="width: 100%" @click="redirectForm()">Go to form</el-button>
            </div>
          </el-card>

          <!-- <el-steps :space="500" :active="3" finish-status="success">
            <el-step title="Created"></el-step>
            <el-step title="Verified"></el-step>
            <el-step title="Payment"></el-step>
            <el-step title="Releasing"></el-step>
            <el-step title="Claimed"></el-step>
          </el-steps> -->
          <el-card style="margin-top: 15px; padding: 2px 0px 2px 0px">
            <div v-if="recipient.step != 99">
              <el-steps :active=" i = recipient.step == 4 || recipient.step == 5 ? i = 3: recipient.step == 6 ? i = 3 : recipient.step" align-center>
                <el-step description="Created" 
                :icon="recipient.step == 0 ? 'el-icon-s-claim bounce-animation' : 'el-icon-s-claim'"></el-step>
                <el-step description="Verification"
                :icon="recipient.step == 1 ? 'el-icon-s-flag bounce-animation' : 'el-icon-s-flag'"
                ></el-step>
                <el-step description="Payment"
                :icon="recipient.step == 2 ? 'el-icon-s-finance bounce-animation' : 'el-icon-s-finance'"
                ></el-step>
                <el-step description="Releasing"
                :icon="recipient.step == 6 ? 'el-icon-s-goods bounce-animation' : 'el-icon-s-goods'"
                ></el-step>
                <el-step description="Claimed"
                :icon="recipient.step == 7 ? 'el-icon-s-cooperation bounce-animation' : 'el-icon-s-cooperation'"
                ></el-step>
              </el-steps>
            </div>
          </el-card>
          
          

          <el-card style="margin-top: 15px">
            <span style=" color: #007a00;"><b>Request progress</b></span><br><hr><br>
            <el-timeline :reverse="reverse">
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :color="activity.color"
                :size="activity.size"
                >
                <div v-if="activity.is_last" > 
                  <span style="font-weight: 800; color: #007a00;">{{ activity.status_heading }}</span><br>
                  <div style="margin-top: 0px;">
                    <span style="color: #000000;">{{activity.track_logs}}</span><b> {{ activity.comment_status }}</b><br>
                  </div>
                  <div style="margin-top: 4px;">
                    <span style="color: #707070; font-size: 13px;">{{activity.date}}</span><br>
                  </div>
                  <div style="margin-top: 0px;">
                    <span style="color: #707070; font-size: 13px;">{{ activity.time }}</span>
                  </div>
                </div>
                <div v-if="!activity.is_last" style="color:#bdbdbd;">
                  <span style="font-weight: 800; color: #bdbdbd;">{{ activity.status_heading }}</span><br>
                  <div style="margin-top: 0px;">
                    <span style="color: #bdbdbd;">{{activity.track_logs}}</span><b> {{ activity.comment_status }}</b><br>
                  </div>
                  <div style="margin-top: 4px;">
                    <span style="color: #bdbdbd; font-size: 13px;">{{activity.date}}</span><br>
                  </div>
                  <div style="margin-top: 0px;">
                    <span style="color: #bdbdbd; font-size: 13px;">{{ activity.time }}</span>
                  </div>
                  
                  
                </div>
              </el-timeline-item>
            </el-timeline>
          </el-card>
          
          
          <!-- <div v-if="recipient.step > 1" style="text-align: right;">
            <br>
            <a style="cursor: pointer;" @click="handleClick(recipient)">View my Request Details</a>
          </div> -->
        </div>
        <div v-if="!recipient">
          <el-alert
            title="NO RECORD FOUND"
            type="warning"
            description="The request code you entered cannot be found or is not valid."
            :closable=false
            show-icon>
          </el-alert>
        </div>

        <el-dialog title="Request Details" width="75%" :visible.sync="showDialog" v-if="showDialog">
          <view-request-component :selected="selected"></view-request-component>
        </el-dialog>
    </el-card>
  </div>
</div>
</template>

<script>
import ViewRequestComponent from '../private/pages/dashboard/ViewRequestComponent.vue';
import { Recipient } from '@/api_services/recipient'
import { ExcludedDate } from '@/api_services/excludeddate'
import moment from "moment";
import NavbarComponent from '@/components/common/NavbarComponent.vue'

export default {
  name: 'TrackRequestComponent',
  components: {
    ViewRequestComponent, NavbarComponent
  },
  data() {
    return {
      loading_card: false,
      activities: null,
      showDialog: false,
      recipient_rqst_code: null,
      recipient: null,
      invalid: true,
      request_table: null,
      selected_id: null,
      reverse: true,
      loading: true,
      selected: null,
      status_check: 0,
      form:{ 
        request_code: null
      },
      rules: {
        request_code: [{required: true, message: "Enter request code", trigger: "blur"}],
      },
      loading_track: false,
      excluded_dates: null,
      holidays: []
    };
  },
  created: function() {
    this.getRequest()
    this.getExcludedDates()
    this.form.request_code = this.$router.currentRoute.query.recipient_rqst_code
  },
  methods: {
    // calculateDaysInQueue(datePlaced) {
    //   let currentDate = new Date();
    //   if(this.recipient.pickup_track){
    //     currentDate = new Date(moment(this.recipient.pickup_track.created_at).format("MMMM D, YYYY"))
    //   } 
    //   const placedDate = new Date(datePlaced);
    //   let timeDifference = currentDate - placedDate;

    //   if(timeDifference < 1)(
    //     timeDifference = 0
    //   )
    //   const daysInQueue = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    //   return daysInQueue;
    // },
    calculateDaysInQueue(datePlaced) {
      let currentDate = new Date();
      if (this.recipient.pickup_track) {
        currentDate = new Date(moment(this.recipient.pickup_track.created_at).format("MMMM D, YYYY"));
      }
      const placedDate = new Date(datePlaced);
      
      // Calculate the time difference in milliseconds
      let timeDifference = currentDate - placedDate;

      // Adjust for weekends and holidays
      const millisecondsInDay = 1000 * 60 * 60 * 24;
      let excludedDays = 0;
      let tempDate = new Date(placedDate);

      while (tempDate < currentDate) {
        // Skip weekends (Saturday and Sunday)
        if (tempDate.getDay() === 0 || tempDate.getDay() === 6) {
          excludedDays++;
        }

        // Move to the next day
        tempDate.setTime(tempDate.getTime() + millisecondsInDay);
      }

      // Subtract the excluded days from the total time difference
      timeDifference -= excludedDays * millisecondsInDay;

      // Ensure the time difference is not negative
      if (timeDifference < 0) {
        timeDifference = 0;
      }

      // Convert the time difference to days
      let daysInQueue = Math.floor(timeDifference / millisecondsInDay);

      // exclude holidays from excluded_days table in DB
      let holidays = this.holidays
      // console.log(holidays)
      holidays.forEach(holiday => {
        const holidayTime = holiday.getTime();
        if (placedDate.getTime() <= holidayTime && holidayTime <= currentDate.getTime()) {
          daysInQueue--;
        }
      });

      // if in between weekend (friday and monday), defaults to 1 
      let s_date = moment(currentDate).format("MMMM D, YYYY");
      let l_date = moment(placedDate).format("MMMM D, YYYY");
      if(daysInQueue == 0 && s_date !== l_date){
        daysInQueue = 1;
      }

      return daysInQueue;
    },
    getBackgroundColor(datePlaced) {
      const daysInQueue = this.calculateDaysInQueue(datePlaced);
      // console.log(daysInQueue);
      if(daysInQueue >= 12){
        return '#ff5252';
      } else if(daysInQueue >= 7){
        return '#ffae00';
      } else {
        return '#67c23a';
      }
      // return daysInQueue >= 12 ? '#ffae00' : '#67c23a';
    },
    getExcludedDates(){
      ExcludedDate.get({
        params:{ },
      })
      .then((result) => {
          this.excluded_dates = result.data.data
          const holidays = []
          this.excluded_dates.forEach(function(dates){
            holidays.push(new Date(moment(dates.date).format("Y-MM-D")));
          })
          this.holidays = holidays
          // console.log(this.holidays)
      })
      .catch((error) => {
      });
    },
    redirectForm(){
      this.$router.push({path: '/recipient-form', query: { email: this.recipient.email, recipient_rqst_code: this.recipient.recipient_rqst_code, recipient_token: this.recipient.recipient_token}})
    },
    goTo(path) {
      this.$router.push({path: path})
    },
    goToCS(path) {
      this.$router.push({path: path, query: { recipient_rqst_code: this.recipient.recipient_rqst_code }})
    },
    handleClick(recipient){
      this.selected = Object.assign({}, recipient);
      this.showDialog = true
    },
    getRequest(){
        this.loading_card = false
      Recipient.findRecipientCode({recipient_rqst_code: this.$router.currentRoute.query.recipient_rqst_code}).then(result => {
        this.loading = false
        this.loading_track = false
        if(result.data.data) {this.invalid = false}
        // console.log(result.data.data)
        this.recipient = result.data.data
        this.recipient.pickup_track = this.recipient.request_tracks.filter(item => item.status == 6)[0];
        this.activities = this.recipient.request_tracks
        this.selected_id = this.recipient.id
        this.recipient.created_at = moment(this.recipient.created_at).format("MMMM D, YYYY")
        this.recipient.recipient_payment.payment_date = this.recipient.recipient_payment.payment_date ? moment(this.recipient.recipient_payment.payment_date).format("MMMM D, YYYY") : null
        this.recipient.date_start_proc = moment(this.recipient.date_start_process).format("MMMM D, YYYY")
        // if (this.recipient.step == 2) this.status_check = 2
        
        this.activities.forEach(function(activity){
          switch (activity.status) {
            case 0:
              activity.status_heading = "Request Created";
              activity.icon = 'el-icon-document';
              break;
            case 1:
            activity.status_heading = "Request Details Submitted";
            activity.icon = 'el-icon-document';
              break;
            case 2:
              activity.status_heading = "Request Verified";
              break;
            case 3:
              activity.status_heading = "Payment Submitted";
              break;
            case 4:
              activity.status_heading = "Payment Verified";
              break;
            case 5:
              activity.status_heading = "Document Process on Queue";
              break;
            case 6:
              activity.status_heading = "Ready for Release";
              activity.icon = 'el-icon-goods';
              break;
            case 7:
              activity.status_heading = "Document(s) Claimed";
              activity.icon = 'el-icon-check';
              break;
            case 98:
              activity.status_heading = "Request Reverted";
              break;
            case 99:
              activity.status_heading = "Request Rejected";
              break;
            default:
            activity.status_heading = "-";
          }

          activity.type = 'default'
          activity.size = 'large'
          activity.time = moment(activity.created_at).format('hh:mm A')
          activity.timestamp = moment(activity.created_at).format("YYYY-MM-DD")
          activity.date = moment(activity.created_at).format("MMMM D, YYYY")
          activity.is_last = false
        })

        const timeline = this.activities;
        const last = timeline[timeline.length - 1];
        const first = timeline[0];
        last.color = '#67c23a';
        last.is_last = true;
        first.comment_status = this.recipient.recipient_rqst_code;
        // console.log(this.recipient)
      })
      .catch( error => {
        this.loading_track = false
        this.loading = false
        this.recipient = null
        console.log(error)
      })
    },
    formatCurrency(value) {
        return value.toLocaleString('en-PH', {
            style: 'currency',
            currency: 'PHP',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    },
    async submit() {
      const valid = await this.$refs.form.validate();
      this.loading_track = true;
      this.loading_card = true;
      if(!valid){return}
      if(this.form.request_code !== this.$router.currentRoute.query.recipient_rqst_code){
        this.$router.push({query: { recipient_rqst_code: this.form.request_code }})
        this.getRequest()
      } else {
        this.loading_track = false;
        this.loading_card = false;
      }
    },
    onInputChange() {
      this.form.request_code = this.form.request_code.toUpperCase();
    }
    
  }
}
</script>

<style scoped>
.el-timeline {
    padding: 0;
}

.req-details{
 margin-top: 3px;
}

.track{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 70px;
}

.link_button {
  color: #000000;
  background-color: #ffcc00;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
}
</style>

<template>
    <div>
        <br>
        <el-row :gutter="10" v-for="(value, key) in data" :key="key" style="margin-bottom: 10px;">
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" style="text-align: right;">
                <label>{{ key }}:</label>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" >
                <el-input type="number" v-model="data[key]" placeholder="amount" :step="0.01" min="0"></el-input>
                
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="2" :xl="2" >
                <el-button type="danger" icon="el-icon-delete" style="width: 100%; padding: 5px 5px 5px 5px;" @click="deleteBreakdownFee(key)"></el-button>
            </el-col>
        </el-row>

        <el-collapse>
            <el-collapse-item name="1">
                <template slot="title">
                  <span class="custom-title" style="color: #ababab;">Add new breakdown of fee(s):</span>
                </template>
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                        <el-input v-model="newKey" @input="(val) => (newKey = newKey.toUpperCase())" placeholder="New Key"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                        <el-input type="number" v-model="newVal" placeholder="New Value" :step="0.01" min="0"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="2" :xl="2" style="text-align: left;">
                        
                        <el-button type="success" icon="el-icon-plus" style="width: 100%; padding: 5px 5px 5px 5px;" :disabled="!newKey || !newVal" @click="addBreakdownFee(newKey, newVal)"></el-button>
                    </el-col>
                </el-row>
            </el-collapse-item>
        </el-collapse>

    </div>
  </template>
  
  <script>
  
  export default {
    name: "FeesBreakdownComponent",
    props: ["breakdown"],
    data() {
      return {
        data: this.breakdown,
        newKey: '',
        newVal: ''
      };
    },
    created() {
       console.log(this.data)
    },
    methods: {
        addBreakdownFee(newKey, value) {
            if(this.data){
                
                this.$set(this.data, newKey, value);
            } else {
                this.data = {'null':0}
                this.$set(this.data, newKey, value);
                this.$delete(this.data, 'null');
            }
            
            this.newKey = null;
            this.newVal = null;
            this.$emit('update:breakdown', this.data)
            console.log(this.data)
        },
        deleteBreakdownFee(key) {
            // Delete the item from data
            this.$delete(this.data, key);
        },
        toUppercase(val){
            val = val.toUpperCase()
        },
    },
  };
  </script>
  
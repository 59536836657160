<template>
  <div class="login">
      <el-card>
        <div>
          <img :src="require('@/assets/images/vsu_logo_green.png')"  style="margin-bottom:20px;width: 100%;">
        </div>
        <h2 style="text-align: center;">OUR - DOCUMENT REQUEST AND TRACKING SYSTEM</h2>
        <el-form
          class="login-form"
          :model="form"
          :rules="rules"
          ref="form"
          @submit.native.prevent="login"
        >
          <el-form-item prop="email">
            <el-input v-model="form.email" placeholder="Username">
            <template slot="prepend"><i class="el-icon-s-custom"></i></template></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
            
              v-model="form.password"
              placeholder="Password"
              type="password"
            ><template slot="prepend"><i class="el-icon-key"></i></template></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              class="login-button"
              type="primary"
              native-type="submit"
              block
            >Login</el-button>
          </el-form-item>
          <!-- <a class="forgot-password" href="login">Forgot password ?</a> -->
        </el-form>
        <!-- <back-to-home /> -->
      </el-card>
    </div>
  </template>
  
  <script>
  
  import { Session } from '@/api_services/session'
  
  export default {
    name: 'Login',
    data() {
      return {
        validCredentials: {
          username: "lightscope",
          password: "lightscope"
        },
        form: {
          email: "",
          password: ""
        },
        loading: false,
        rules: {
          email: [
            {
              required: true,
              message: "Username/Email is required",
              trigger: "blur"
            },
            {
              min: 4,
              message: "Username/Email length should be at least 5 characters",
              trigger: "blur"
            }
          ],
          password: [
            { required: true, message: "Password is required", trigger: "blur" },
            {
              min: 5,
              message: "Password length should be at least 5 characters",
              trigger: "blur"
            }
          ]
        }
      };
    },
    methods: {
      timeOut() {
          return new Promise(resolve => {
              setTimeout(resolve, 1000);
          });
      },
      async login() {    
           this.loading = false
           const valid = await this.$refs.form.validate();
          if (!valid) return;
  
          this.loading = true;
          this.submitForm()
      },
      async submitForm() {
        let self = this
        Session.login(this.form, function(success, message){
          self.loading = false
          if( !success) {
            self.$message({
              message: message,
              type: 'error',
              duration: 1500
            });
          } else {
            self.$message({
              message: "Logged in...",
              type: 'success',
              duration: 1500
            });
            self.redirect()
          }
        })
      },
      async redirect() {
        await this.timeOut();
        this.loading = false;
        this.$router.push('Main')
      }
    }
  }
  </script>
  
  <style scoped>
  body {
    background-color: #003300;
  }
  .login {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .login-button {
    width: 100%;
    margin-top: 40px;
  }
  .login-form {
    width: 290px;
  }
  .forgot-password {
    margin-top: 10px;
  }
  </style>
  
  <style lang="scss">
  $teal: rgb(0, 124, 137);
  .el-button--primary {
    background: $teal;
    border-color: $teal;
  
    &:hover,
    &.active,
    &:focus {
      background: lighten($teal, 7);
      border-color: lighten($teal, 7);
    }
  }
  .login .el-input__inner:hover {
    border-color: $teal;
  }
  .login .el-input__prefix {
    background: rgb(238, 237, 234);
    left: 0;
    height: calc(100% - 2px);
    left: 1px;
    top: 1px;
    border-radius: 3px;
    .el-input__icon {
      width: 30px;
    }
  }
  .login .el-input input {
    padding-left: 35px;
  }
  .login .el-card {
    padding-top: 0;
    padding-bottom: 30px;
  }
  h2 {
    font-family: "Open Sans";
    letter-spacing: 1px;
    font-family: Roboto, sans-serif;
    padding-bottom: 20px;
  }
  a {
    color: $teal;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      color: lighten($teal, 7);
    }
  }
  .login .el-card {
    width: 340px;
    display: flex;
    justify-content: center;
  }
  .vsu-button, .el-button--primary, .el-button--primary:after, .el-button--primary:active {
    background-color: #ffcc00 !important;
    border-color: #ffcc00 !important;
    color: #093300 !important;
  } 
  </style>
  
  
  
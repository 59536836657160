<template>
    <div>
        <br>
        <el-row :gutter="10" v-for="(value, key) in fees_breakdown" :key="key" style="margin-bottom: 10px;">
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" style="text-align: right;">
                <label>{{ key }}:</label>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" >
                <el-input size="mini" type="number" v-model="fees_breakdown[key]" placeholder="amount" :step="0.01" min="0"></el-input>
                
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" style="text-align: right;">
                <el-button size="mini" type="danger" icon="el-icon-delete" style="width: 100%; padding: 5px 5px 5px 5px;" @click="deleteBreakdownFee(key)"></el-button>
            </el-col>
        </el-row>

        <el-collapse style="padding: 10px">   
            <el-collapse-item name="1">
                <template slot="title">
                  <span class="custom-title" style="color: #ababab;">Add new breakdown of fee(s):</span>
                </template>
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                        <el-input size="mini" v-model="newKey" @input="(val) => (newKey = newKey.toUpperCase())" placeholder="New Key"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                        <el-input size="mini" type="number" v-model="newVal" placeholder="New Value" :step="0.01" min="0"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4" style="text-align: left;">
                        
                        <el-button size="mini" type="success" icon="el-icon-plus" style="width: 100%; padding: 5px 5px 5px 5px;" :disabled="!newKey || !newVal" @click="addBreakdownFee(newKey, newVal)"></el-button>
                    </el-col>
                </el-row>
            </el-collapse-item>
        </el-collapse>
        <div style="margin-top: 20px;">
            <h3>Fee:</h3>
            <el-input type="number" v-model="fee" placeholder="amount" :step="0.01" min="0"></el-input>
        </div>
        <div style="margin-top: 10px;">
            <b style="color: #ff5555;">Note:</b> <i style="font-size: small;">If breakdown of fees is applied, the total sum of those fees will be its fee.</i>
        </div>
        <div style="margin-top: 10px; text-align: right;">
            <el-button type="success" icon="el-icon-tickets" @click="saveChanges">Save</el-button>
        </div>

    </div>
  </template>
  
  <script>

import { DocumentRequest } from '@/api_services/documentrequest';
  
  export default {
    name: "ViewBreakdownComponent",
    props: ["document_request"],
    data() {
      return {
        data: this.document_request,
        fees_breakdown: null,
        fee: this.document_request.fee,
        newKey: '',
        newVal: ''
      };
    },
    created() {
       console.log(this.data)
       this.fees_breakdown = this.document_request.fees_breakdown
       this.fee = this.document_request.fee
    },
    methods: {
        addBreakdownFee(newKey, value) {
            if(this.fees_breakdown){
                
                this.$set(this.fees_breakdown, newKey, value);
            } else {
                this.fees_breakdown = {'null':0}
                this.$set(this.fees_breakdown, newKey, value);
                this.$delete(this.fees_breakdown, 'null');
            }
            
            this.newKey = null;
            this.newVal = null;
            this.$emit('update', this.document_request)
            
            console.log(this.fees_breakdown)
        },
        deleteBreakdownFee(key) {
            // Delete the item from data
            this.$delete(this.fees_breakdown, key);
        },
        toUppercase(val){
            val = val.toUpperCase()
        },
        saveChanges(){
            let form = Object.assign({}, this.form);
            form.fees_breakdown = this.fees_breakdown
            form.id = this.data.id
            form.fee = this.fee

            console.log(form)



            this.$confirm('Apply changes?')
                .then(_ => {
                    DocumentRequest.update(form.id, form)
                    .then(result => {
                        if (result) {
                            this.$emit("updateBreakdown", result.data.data);
                            this.$emit("update");
                            this.$message({
                                message: "Saved changes",
                                type: 'success',
                                duration: 1500
                            });
                        } 
                    })
                    .catch( error => {
                        console.log(error)
                    })
                })
                .catch(_ => {

                });

            
        }
    },
  };
  </script>
  
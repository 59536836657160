<template>
    <div style="text-align:center" >
      <!-- <img src="@/assets/images/admin.jpg" alt="" style="    
      position: absolute;
      background-color: rgb(107, 135, 164);
      height: 150%;
      width: 100%;
      top: 59px;
      left: 0px;
      opacity: 0.2;"> -->
      <navbar-component></navbar-component>
      <div class="landing" >
        <el-card>
            <h1 style="color:#000000">University Registrar</h1>
            <h3 style="color:black">DOCUMENT REQUEST AND TRACKING SYSTEM</h3><br><br><br><br>
            <p style="font-size: small; color: #003300; font-style: italic;">Track your requested document(s) or file a request:</p>
            <el-row>
            
            <el-form
              class="track-form"
              :model="form"
              :rules="rules"
              ref="form"
              @submit.native.prevent="submit('form')"
            >
            <el-form-item prop="request_code">
              <el-input placeholder="Please enter Request Code here " class="input" v-model="form.request_code" @input="onInputChange">
                <template slot="prepend"><i class="el-icon-search"></i></template>
                <el-button type="primary" native-type="submit" slot="append" icon="el-icon-place" :loading="loading_track" style="font-weight: bold; font-size: 15px;"> Track</el-button>
              </el-input>
            </el-form-item>
            </el-form>
            

            </el-row>
            <el-row>
              <hr>
            </el-row>
            <el-row>
              <el-button href="login" type="primary" icon="el-icon-tickets" style="width: 100%; color: #003300; font-weight: bold; font-size: 15px;" @click="goTo('/request')"> File a Request</el-button>
            </el-row>
            <!-- <track-request-component :request_code="request_code"></track-request-component> -->
            
        </el-card>
      </div>
    </div>
</template>

<script>
import NavbarComponent from '@/components/common/NavbarComponent.vue'


export default {
  name: 'Landing',
  components: { NavbarComponent },
  data() {
    return {
      centerDialogVisible: false,
      centerDialogVisible_notice: false,
      loading_track: false,
      form:{ 
        request_code: null
      },
      rules: {
        request_code: [{required: true, message: "Enter request code", trigger: "blur"}],
      },
    }
  },
  methods: {
    goTo(name) {
      this.$router.push({path: name})
    },
    async submit() {
      // this.loading = true
      const valid = await this.$refs.form.validate();
      this.loading_track = true;
      if(valid){
        this.$router.push({path: '/track', query: { recipient_rqst_code: this.form.request_code }})
      }
      // console.log(this.form);
      
    },
    onInputChange() {
      this.form.request_code = this.form.request_code.toUpperCase();
    }
  },
}
</script>


<style scoped>
.landing {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.el-button--primary, .el-button--primary:after, .el-button--primary:active {
    background-color: #ffcc00 !important;
    border-color: #ffcc00 !important;
    color: #093300 !important;
}
</style>
<template>
  <div class="schedule">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="120px"
      class="demo-ruleForm"
    >

      <el-form-item label="Description" prop="description">
        <el-input type="text" v-model="form.description"></el-input>
      </el-form-item>

      <el-form-item label="Date" prop="date">
        <el-date-picker type="date" v-model="form.date"></el-date-picker>
      </el-form-item>

      <el-form-item label="Type" prop="type">
        <el-input v-model="form.type"></el-input>
      </el-form-item>

      <el-form-item label="Is Yearly?" prop="is_yearly">
        <el-switch v-model="form.is_yearly">
        </el-switch>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm"> {{ !form.id ? 'Create' : 'Update' }}</el-button>
        <el-button @click="resetForm">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ExcludedDate } from '@/api_services/excludeddate';
import moment from "moment";
export default {
  name: "ExcludedDate",
  props: ["selected"],

  data() {
    return {
      form: {
        description: "",
        date: "",
        type: "",
        is_yearly: false
      },
      rules: {
        description: [
          { required: true, message: 'Name is required', trigger: 'blur' },
        ],
        date: [
          { required: true, message: 'Date is required', trigger: 'blur' },
        ],
        type: [
          { required: true, message: 'Type is required', trigger: 'blur' },
        ]
      }
    }
  },
  created() {
    this.form = Object.assign({}, this.selected);
    if (this.selected) {
      this.form = this.selected
      this.form.is_yearly = this.selected.is_yearly == 1 ? true : false
    }
    // console.log(this.selected)
  },
  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    },
    async submitForm() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;
        if (this.selected && this.selected.id) {
          this.$confirm('Confirm Update?', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            dangerouslyUseHTMLString: true,
            type: 'success'
          }).then(() =>{
            this.update();
          })
          .catch((error) => {
            console.log(error)
          });
        } else {
          this.$confirm('Confirm Saving?', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            dangerouslyUseHTMLString: true,
            type: 'success'
          }).then(() =>{
            this.create();
          })
          .catch((error) => {
            console.log(error)
          });
        }
    },
    create() {
      ExcludedDate.create(this.form)
      .then((response) => {
        this.$message({
          message: "Date Saved",
          type: 'success',
          duration: 1500
        });
        this.$emit("saved", response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
    },
    update() {
      // console.log(moment(this.form.date).format("yyyy-D-MM"))
      ExcludedDate.update(this.selected.id, this.form)
      .then((response) => {
        this.$message({
          message: "Date Updated",
          type: 'success',
          duration: 1500
        });
        this.$emit("saved", response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
    }
  }
};
</script>

<template>
    <div>
        <el-card>
          <span style="color: #009d00; font-weight: bolder;">Payment Verification</span><br>
          <el-button style="float: right;" @click="refresh()" type="success" size="small" :loading="loading"><i v-if="!loading" class="el-icon-refresh"></i> Refresh</el-button><br><br>

          <el-table v-if="activeTab === 'second'" :empty-text="empty" :data="recipients" stripe border style="width: 100%" size="mini" v-loading="loading">
            <el-table-column
              label="ID"
              fixed
              width="60px"
              >
              <template slot-scope="scope">
                <span>{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed
              label="Request Code">
              <template slot-scope="scope">
                <b style="font-size: 15px;">{{ scope.row.recipient_rqst_code }}</b> <span style="padding: 1px 4px 1px 4px; background-color: green; color: white;" v-if="scope.row.is_expedite">expedite</span><br>
                <div style="color:#909399; margin-top: -5px">Payment Attachment Submitted: <br></div><div style="color:#909399;margin-top: -10px"><b>{{ scope.row.payment_track_date }}</b> ({{ scope.row.payment_track_time}})</div>
                <div style="color:#909399; margin-top: -5px">Request Verified: <br></div><div style="color:#909399;margin-top: -10px"><b>{{ scope.row.request_verified_date }}</b> ({{ scope.row.request_verified_time}})</div>
              </template>
              
            </el-table-column>
            <el-table-column
              label="Request(s)">

              <template slot-scope="scope">
                <div v-for="(document_requests, index) in scope.row.document_requests" :key="index">
                  <span v-if="document_requests.request_type.doc_type != 'EXPEDITE_FEE' "><li>  {{ document_requests.request_type.request_name }} <span v-if="document_requests.others_request_description">: {{ document_requests.others_request_description }}</span> ({{ document_requests.no_copy }})</li></span>
                </div>
              </template>
              
            </el-table-column>
            
            <!-- <el-table-column
              prop="student_no"
              label="Student Number"
              width="150">
            </el-table-column> -->
            <el-table-column
              label="Name">
              <template slot-scope="scope">
                <b>{{ scope.row.last_name }}, {{ scope.row.first_name }} {{ scope.row.middle_name }} {{ scope.row.ext_name }}</b>
              </template>
            </el-table-column>
            <el-table-column
              label="Degree Program">
              <template slot-scope="scope">
                <span v-if="scope.row.course">{{ scope.row.course.code }} {{ !scope.row.course.major ? "" : "major in " + scope.row.course.major }}</span>
                <span v-if="!scope.row.course">No course selected</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Total Bill"
              width="140px"
              >
              <template slot-scope="scope">
                <span>{{ formatCurrency(parseFloat(scope.row.recipient_payment.amount)) }}</span><br>
                <b>{{ scope.row.recipient_payment.payment_method ? scope.row.recipient_payment.payment_method : "Not selected yet"  }}</b><br>
                <span style="color: #909399; font-size: 10px;">OR: {{ scope.row.recipient_payment.or_number ? scope.row.recipient_payment.or_number : "---"}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="Operations"
              width="140">
              <template slot-scope="scope">
                <el-button v-if="!scope.row.show" style="width: 100%; padding: 4px; margin-bottom: 5px;" @click="handleClick(scope.row)" type="warning" size="small"><i class="el-icon-view"></i> View</el-button><br>
                <el-button v-if="scope.row.show_verify_btn" style="width: 100%; padding: 4px; margin-bottom: 5px;" @click.native.prevent="verify(scope.row)" type="success" size="small"><i class="el-icon-check"></i> Verify Payment</el-button><br>
                <el-button v-if="!scope.row.show" style="width: 100%; padding: 4px; margin-bottom: 5px;" @click.native.prevent="revert(scope.row)" type="info" size="small"><i class="el-icon-caret-left"></i> Revert</el-button>
                <!-- <el-button style="padding: 3px" type="danger" size="small">Reject</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <div style="justify-content: center; align-items: center;display: flex;">
            <el-pagination
              class="margin-top-20"
              background
              center
              layout="prev, pager, next"
              :total="meta.total"
              :page-size="meta.per_page"
              @current-change="pageChanged"
              :current-page="meta.page"
            >
            </el-pagination>
          </div>
          
          <el-dialog title="Request Details" width="75%" :visible.sync="showDialog" :destroy-on-close.sync="destroyOnClose" v-if="showDialog">
            <view-request-component @update="refresh()" :selected="selected"></view-request-component>
          </el-dialog>

        </el-card>
    </div>
</template>
  
  <script>
  import ViewRequestComponent from '@/views/private/pages/dashboard/ViewRequestComponent.vue';
  import { Recipient } from '@/api_services/recipient';
  import moment from "moment";
  
  export default {
    props: {
      activeTab: String,
    },
    name: 'Incomming',
    components: {
      ViewRequestComponent
    },
    data() {
      return {
        recipients: null,
        meta: {
          page: 1,
          pages: 1,
          total: 211,
          per_page: 15,
        },
        form:{},
        showDialog: false,
        selected: null,
        loading: false,
        destroyOnClose: true,
        empty: "No available payment verification(s) in queue.",
        user_type: null
      };
    },
    created: function() {
      if(this.$store.state.savedUser.role === 'accounting'){
        this.getRecipients()
      }
      
    },
    watch: {
      activeTab(newTab, oldTab) {
        if (newTab === 'second') {
          this.loading = true
          this.meta.page = 1
          this.getRecipients();
        }
      },
    },
    methods: {
      refresh(){
        this.loading = true
        this.getRecipients();
      },
      formatCurrency(value) {
        return value.toLocaleString('en-PH', {
          style: 'currency',
          currency: 'PHP',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      verify(recipient){

        if(!recipient.recipient_payment.or_number){
          this.$alert('<strong>Missing</strong> <strong style="color:red;">OR / Reference number</strong>', 'Please input OR / Reference Number', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              type: 'error'
          });
          return
        }

        console.log(recipient.id)
        this.form.id = recipient.id
        this.form.step = 3
        this.form.user_id = this.$store.state.savedUser.id
        let form = Object.assign({}, this.form);
        
        this.$confirm('Confirm <strong style="color:green;">payment verification</strong> for <strong>'+ recipient.first_name + ' ' + recipient.last_name +'\'s</strong> payment of <strong>P'+ recipient.recipient_payment.amount +'</strong>?', {
          confirmButtonText: 'Confirm Payment Verification',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          type: 'success'
        }).then(() =>{
          Recipient.updateDetails(form)
          .then(result => {
            if (result) {
              this.$message({
                message: "Payment verified.",
                type: 'success',
                duration: 1500
              });
            } 
          })
          .catch( error => {
            console.log(error)
          })
          this.getRecipients()
        })
        .catch(_ => {

        });
      },
      getRecipients() {
        if(this.$store.state.savedUser.role === 'accounting'){
          this.user_type = 2;
        } else {
          this.user_type = 1;
        }
        
        Recipient.get({
          params: { step: 3, page: this.meta.page},
        })
          .then((result) => {
            this.recipients = result.data.data;
            const userType = this.user_type
            this.recipients.forEach(function(recipient){
              recipient.show_verify_btn = true
              recipient.created_at_time = moment(recipient.created_at).format('hh:mm A')
              recipient.created_at_label = moment(recipient.created_at).format("MMMM D, YYYY")
              // recipient.created_at = moment(recipient.created_at).format("MMMM D, YYYY")

              if(recipient.date_request_verified){
                recipient.date_request_verified = moment(recipient.date_request_verified).format("MMMM D, YYYY")
              } else {
                recipient.date_request_verified = null
              }

              if(recipient.recipient_payment.payment_date){
                recipient.recipient_payment.payment_date = moment(recipient.recipient_payment.payment_date).format("MMMM D, YYYY")
              } else {
                recipient.recipient_payment.payment_date = null
              }
              
              if(recipient.recipient_payment.payment_method == 'VSU CASHIER' || recipient.recipient_payment.payment_method == null){
                recipient.ptype = 1
              } else {
                recipient.ptype = 2
              }

              if(userType == 1 && recipient.ptype != 1){
                recipient.show_verify_btn = false
                recipient.show = false
              } 

              if(userType == 2 && recipient.ptype != 2){
                recipient.show_verify_btn = false
                recipient.show = true
              } 

              recipient.payment_track = recipient.request_tracks.filter(item => item.status == 3)[0];
              recipient.payment_track_date = recipient.payment_track ? moment(recipient.payment_track.created_at).format("MMMM D, YYYY") : '--'
              recipient.payment_track_time = recipient.payment_track ? moment(recipient.payment_track.created_at).format("hh:mm A") : '--'

              recipient.request_verified = recipient.request_tracks.filter(item => item.status == 2)[0];
              recipient.request_verified_date = recipient.request_verified ? moment(recipient.request_verified.created_at).format("MMMM D, YYYY") : '--'
              recipient.request_verified_time = recipient.request_verified ? moment(recipient.request_verified.created_at).format("hh:mm A") : '--'
              
            })
            
            // console.log(this.recipients);
            this.meta = {
              page: result.data.meta.current_page,
              total: result.data.meta.total,
              per_page: result.data.meta.per_page,
              pages: result.data.meta.last_page,
            };
            this.loading = false;
            // console.log(this.recipients)
          })
          .catch((error) => {
            this.loading = false;
            this.recipients = [];
          });
      },
      revert(recipient){
        // console.log(id)
        this.form.id = recipient.id
        this.form.step = 98
        this.form.user_id = this.$store.state.savedUser.id
        let form = Object.assign({}, this.form);

        this.$prompt('Please input reason for <strong style="color:#e79500;">revert</strong> for:<br> <strong>'+ recipient.first_name + ' ' + recipient.last_name +'</strong>', {
          confirmButtonText: 'Confirm Revert',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(({ value }) => {
          if(!value){
            this.$message({
              type: 'error',
              message: 'Remarks required'
            });
            return
          }
          this.$message({
            type: 'success',
            message: 'Document request reverted'
          });
          form.revert_remarks = value
          Recipient.updateDetails(form)
          .then(result => {
            if (result) {
              this.$message({
                message: "Recipient Reverted",
                type: 'success',
                duration: 1500
              });
            } 
          })
          .catch( error => {
            console.log(error)
          })
          this.getRecipients()
        }).catch(() => {

        });
      },
      handleClick(recipient){
        this.showDialog = true
        this.selected = Object.assign({}, recipient)
      },
      pageChanged(val){
        this.meta.page = val
        this.getRecipients()
      },

    }
  }
  </script>
  
  <style scoped>
  
  </style>
  
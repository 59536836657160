<template>
  <div>
    <el-card shadow="none">
      <h2>Excluded Dates / Holidays</h2>
      <div class="add-btn right">
        <el-button type="success" size="medium" @click="addForm()">Add Date</el-button>
      </div>
      <!-- <el-calendar v-model="value">
        <template
          slot="dateCell"
          slot-scope="{date, data}">
          <p :class="data.isSelected ? 'is-selected' : ''">
            {{ new Date(date).getDate() }} 
          </p>
        </template>
      </el-calendar> -->
      <el-table
        v-loading="loading"
        :data="excluded_dates"
        style="width: 100%" size="mini">
        <!-- <el-table-column label="ID" prop="id" width="60px"></el-table-column> -->
        <el-table-column label="Description" prop="description"></el-table-column>
        <el-table-column label="Date">
          <template slot-scope="scope" >
            {{ dateFormat(scope.row.date) }}
          </template>
        </el-table-column>
        <el-table-column label="Type" prop="type"></el-table-column>
        <el-table-column label="Is Yearly?" prop="is_yearly">
          <template slot-scope="scope" >
            <b v-if="scope.row.is_yearly == true" style="color:#67C23A">YES</b>
            <b v-else style="color:#F56C6C">NO</b>
          </template>
        </el-table-column>
        <el-table-column label="Added by / Last modified by" prop="added_by_name"></el-table-column>
        <el-table-column
          fixed="right"
          label="Operations"
          width="120">
          <template slot-scope="scope">
            <el-button style="width: 80%; padding: 4px" @click="editDate(scope.row)" type="warning" size="small"><i class="el-icon-edit"></i> Edit</el-button><br>
            <!-- <el-button style="width: 80%; padding: 4px" v-if="scope.row.role != 'admin'" @click="deleteUser(scope.row)" type="danger" size="small"><i class="el-icon-delete"></i> Delete</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <div style="justify-content: center; align-items: center;display: flex;">
        <el-pagination
          class="margin-top-20"
          background
          layout="prev, pager, next"
          :total="meta.total"
          :page-size="meta.per_page"
          @current-change="pageChanged"
          :current-page="meta.page">
        </el-pagination>
      </div>

      <el-dialog title="Excluded Date / Holiday" width="40%" :visible.sync="showDialog" v-if="showDialog" :destroy-on-close="true" class="responsive-dialog">
        <excluded-date-component :selected="selected" @saved="dateSaved"></excluded-date-component>
      </el-dialog>
    </el-card>
  </div>

</template>
  
  <script>

  import { ExcludedDate } from '@/api_services/excludeddate'
  import ExcludedDateComponent from './ExcludedDateComponent.vue';
  import moment from "moment";

  export default {
    components: { ExcludedDateComponent },
    name: 'ExcludedDates',
    data() {
      return {
        excluded_dates: null,
        loading: false,
        meta: {
          page: 1,
          pages: 1,
          total: 211,
          per_page: 15,
        },
        showDialog: false,
        value: new Date(),
        calendar_exclueded_dates: null
      }
    },
    
    created() {
      this.getExcludedDates()
    },
    methods: {
      addForm() {
        this.showDialog = true;
        this.selected = null;
      },
      editDate(excluded_date) {
        this.selected = Object.assign({}, excluded_date);
        this.showDialog = true;
      },
      pageChanged(val) {
        this.meta.page = val;
        this.getExcludedDates();
      },
      getExcludedDates(){
        this.loading = true;
        ExcludedDate.get({
          params: { page: this.meta.page},
        })
        .then((result) => {
            this.loading = false
            this.excluded_dates = result.data.data
            // console.log(this.excluded_dates)

            // this.excluded_dates.forEach(dates => {
            //   this.calendar_exclueded_dates.push(dates.date);
            // });
            this.meta = {
              page: result.data.meta.current_page,
              total: result.data.meta.total,
              per_page: result.data.meta.per_page,
              pages: result.data.meta.last_page,
            };
        })
        .catch((error) => {
            this.loading = false;
        });
      },
      dateFormat(date){
        return moment(date).format("MMMM D, YYYY");
      },
      dateSaved() {
        this.showDialog = false;
        this.selected = null;
        this.getExcludedDates();
      },
    }
  }
  </script>
  
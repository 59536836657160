import api from './api'

const API = '/api/users'

export const User = {
  get: function (params) {
    return api.get(API, params)
  },
  create: function (params) {
    return api.create(API, params)
  },
  show: function (id, params) {
    return api.get(API + '/' + id, params)
  },
  update: function (id, params) {
    return api.update(API + '/' + id, params)
  },
  getBy: function (id, params) {
    return api.get(API + '/id/' + id, params)
  },
  delete: function (id, params) {
    return api.delete(API + '/' + id, params)
  },
}

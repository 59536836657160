import api from './api'

const API = '/api/settings'

export const Setting = {
  get: function (params) {
    return api.get(API, params)
  },
  create: function(data) {
    return api.create(API, data);
  },
  update: function (id, params) {
    return api.update(API + '/' + id, params)
  },
  delete: function (id, params) {
    return api.delete(API + '/' + id, params)
  },
  getIsUnavailableSetting: function(params) {
    return api.get(API + '/is_unavailable_setting', params)
  },
  getAllSetting: function(params) {
    return api.get(API + '/all_setting', params)
  },
  updateSettings: function (params) {
    return api.create(API + '/update_settings', params)
  },
}

<template>
  <div class="schedule">
    <el-form
      :model="userForm"
      :rules="rules"
      ref="userForm"
      label-width="120px"
      class="demo-ruleForm"
    >
  
      <el-form-item label="Name" prop="name">
        <el-input v-model="userForm.name"></el-input>
      </el-form-item>

      <el-form-item label="Email" prop="email">
        <el-input v-model="userForm.email"></el-input>
      </el-form-item>

      <el-form-item label="Password" prop="password">
        <el-input v-model="userForm.password" placeholder="Will generate default password if leave as blank" show-password></el-input>
      </el-form-item>

      <el-form-item label="Role" prop="role">
        <el-select v-model="userForm.role" placeholder="Role" size="large" style="width: 100%;">
          <el-option :label="role.value" :value="role.id" v-for="role in $store.state.roles" :key="role.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Is Active?" prop="is_active">
        <el-switch v-model="userForm.is_active">
        </el-switch>
      </el-form-item>

      <el-form-item label="Receive Notifs?" prop="receive_email_notifications">
        <el-switch v-model="userForm.receive_email_notifications">
        </el-switch>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('userForm')">Save</el-button>
        <el-button @click="resetForm('userForm')">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { User } from '@/api_services/user';
export default {
  name: "UserComponent",
  props: ["selected"],

  data() {
    return {
      userForm: {
          date: '',
          slots: 250
      },
      rules: {
        name: [
          { required: true, message: 'Name is required', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Email address is required', trigger: 'blur' },
          { type: 'email', message: 'Invalid email address', trigger: ['blur', 'change'] }
        ],
        // campus: [
        //   { required: true, message: 'Campus is required', trigger: 'blur' },
        // ],
        role: [
          { required: true, message: 'Role is required', trigger: 'blur' },
        ]
      }
    }
  },
  created() {
    this.userForm = Object.assign({}, this.selected);
    if (!this.userForm) this.userForm = {}
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async submitForm(formName) {
      const valid = await this.$refs.userForm.validate();
      if (!valid) return;

      if (this.userForm.id) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      User.create(this.userForm).then(response => {
        this.userForm = {}
        this.$emit('saved', response.data.data);
      }).catch((error) => {
        if (error.response.data.message) {
          this.$message({
            message: error.response.data.message,
            type: 'error',
            duration: 3000
          });
        }
      });
    },
    update() {

      if(this.userForm.is_active == false){
        this.userForm.receive_email_notifications = false
      }

      User.update(this.selected.id, this.userForm).then(response => {
        this.userForm = {}
        this.$emit('saved', response.data.data);
        this.$message({
          message: "User updated",
          type: 'success',
          duration: 3000
        });
      }).catch((error) => {
        let message = 'An error occured while submitting your request'
        if (error.response && error.response.data.message) {
          message = error.response.data.message
        }
        this.$message({
          message: message,
          type: 'error',
          duration: 3000
        });
      });
    }
  }
};
</script>

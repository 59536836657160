<template>
    <div> 
        <el-row :gutter="20" style="padding:10px">
          <el-card shadow="none">
            <el-tabs v-model="activeName" @tab-click="handleClickTab" type="border-card">
                <el-tab-pane label="Completed Requests" name="first" active>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <completed-requests-component :active-tab="activeName"></completed-requests-component>
                  </el-col>
                </el-tab-pane>
                <el-tab-pane label="Rejected Requests" name="second">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <rejected-requests-component :active-tab="activeName"></rejected-requests-component>
                  </el-col>
                </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-row>
        <el-dialog title="Request Details" width="75%" :visible.sync="showDialog">
          <view-request-component ></view-request-component>
        </el-dialog>
    </div>
</template>

<script>

import * as moment from 'moment';
import ViewRequestComponent from '@/views/private/pages/dashboard/ViewRequestComponent.vue';
import CompletedRequestsComponent from './CompletedRequestsComponent.vue';
import RejectedRequestsComponent from './RejectedRequestsComponent.vue';


export default {
  name: 'Completed',
  components: {
    ViewRequestComponent, CompletedRequestsComponent, RejectedRequestsComponent
  },
  data() {
    return {
        showDialog: false,
        activeName: 'first', 
        input1: null,
        input2: null,
    }
    
  },
  created: function() {
    // this.getSchedules()
    // this.getAnalytics()
  },
  methods: {
    resendRow() {
      this.$confirm('Confirm resend details through email?')
        .then(_ => {
          this.$message({
            message: 'Details sent to email!',
            type: 'success'
          })
          done();
        })
        .catch(_ => {});
        
      },
    deleteRow(index, rows) {
      this.$confirm('Confirm verification?')
        .then(_ => {
          rows.splice(index, 1);
          this.recipientVerifiedMsg();
          done();
        })
        .catch(_ => {});
        
      },
      paymentRow(index, rows) {
      this.$confirm('Confirm Payment?')
        .then(_ => {
          rows.splice(index, 1);
          this.recipientPaymentMsg();
          done();
        })
        .catch(_ => {});
        
      },
      assessedRow(index, rows) {
      this.$confirm('Confirm Assessment and ready for release?')
        .then(_ => {
          rows.splice(index, 1);
          this.recipientAssessmentMsg();
          done();
        })
        .catch(_ => {});
        
      },
      releasedRow(index, rows) {
      this.$confirm('Confirm release of document/s?')
        .then(_ => {
          rows.splice(index, 1);
          this.recipientReleasedMsg();
          done();
        })
        .catch(_ => {});
        
      },
      recipientVerifiedMsg() {
        this.$message({
          message: 'Request/s verified. Tracking logs and details was sent through email.',
          type: 'success'
        });
      },
      recipientPaymentMsg() {
        this.$message({
          message: 'Payment verified. Tracking logs and details was sent through email.',
          type: 'success'
        });
      },
      recipientAssessmentMsg() {
        this.$message({
          message: 'Ready for Release. Tracking logs and details was sent through email.',
          type: 'success'
        });
      },
      recipientReleasedMsg() {
        this.$message({
          message: 'Document claimed. Tracking logs and details was sent through email.',
          type: 'success'
        });
      },
      handleClick(){
        this.showDialog = true
      },
      handleClickTab(tab){
        // console.log(tab)
      }
    
  }
}
</script>

<style scoped>
  .dashboard-icon {
        position: absolute;
    top: 15px;
    right: 15px;
    font-size: 80px;
    opacity: .6;
  }
  .demo-input-label {
    display: inline-block;
    width: 130px!important;
  }
</style>
<template>
    <div>
      
      <div class="profile">
        <el-card style="max-width: 600px; min-width: 400px;">
          <h2>Update Profile</h2>
          <el-form
            :model="userForm"
            :rules="rules"
            ref="userForm"
            class="demo-ruleForm"
            v-loading="loading"
          >
        
            <el-form-item label="Name" prop="name">
              <el-input v-model="userForm.name"></el-input>
            </el-form-item>

            <el-form-item label="Email" prop="email">
              <el-input v-model="userForm.email"></el-input>
            </el-form-item>

            <el-form-item label="Password" prop="password">
              <el-input v-model="userForm.password" placeholder="Will override the existing password if filled" show-password></el-input>
            </el-form-item>

            <el-form-item>
              <div style="text-align: center; margin-top: 20px;">
                <el-button type="primary" @click="submitForm('userForm')">Update</el-button>
              </div>
            </el-form-item>
          </el-form>
          <b style="color: #ff5555;">Note:</b> <i style="font-size: small;">Changes require logging out and logging back in to take effect.</i><br /><br />
        </el-card>
      </div>
    </div>
  </template>
    
    <script>
    import { User } from '@/api_services/user';
    export default {
      name: 'Profile',
      data() {
        return {
          userForm: {},
          rules: {
            name: [
              { required: true, message: 'Name is required', trigger: 'blur' },
            ],
            email: [
              { required: true, message: 'Email address is required', trigger: 'blur' },
              { type: 'email', message: 'Invalid email address', trigger: ['blur', 'change'] }
            ],
            // campus: [
            //   { required: true, message: 'Campus is required', trigger: 'blur' },
            // ],
            role: [
              { required: true, message: 'Role is required', trigger: 'blur' },
            ]
          },
          loading: false
        };
      },
      mounted() {

      },
      created: function() {
        this.getUser()
      },
      methods: {
        async submitForm(formName) {
          const valid = await this.$refs.userForm.validate();
          if (!valid) return;

          if (this.userForm.id) {
            this.update();
          } else {
            return
          }
        },
        getUser(){
          this.loading = true
          const userid = this.$store.state.savedUser.id
          User.show(userid)
          .then(result => {
            this.userForm = result.data.data
            this.loading = false
          })
          .catch( error => {
            console.log(error)
          })
        },
        update() {
          this.loading = true
          User.update(this.userForm.id, this.userForm).then(response => {
            this.loading = false
            this.userForm = response.data.data;
            this.$message({
              message: "User Profile Updated",
              type: 'success',
              duration: 3000
            });
          }).catch((error) => {
            let message = 'An error occured while submitting your request'
            if (error.response && error.response.data.message) {
              message = error.response.data.message
            }
            this.$message({
              message: message,
              type: 'error',
              duration: 3000
            });
          });
        }
      }
    }
    </script>
    
    <style scoped>
    .profile{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      margin-bottom: 70px;
    }
    </style>
    
    
    
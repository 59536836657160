<template>
  <el-select
    style="width: 80%"
    v-model="item"
    remote
    filterable
    placeholder="Please enter a keyword"
    :remote-method="search"
    
  >
    <el-option
      v-for="item in courses"
      :key="item.id"
      :label="getName(item)"
      :value="item"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Course } from "@/api_services/course";

export default {
  name: "SearchCourse",
  props: ["course", "check", "campus"],
  data() {
    return {
      item: this.course,
      courses: [],
    };
  },
  watch: {
    check: function () {
      this.item = this.course;
      this.addItemToCourses();
    },
    item: function () {
      if (this.item) {
        this.$emit("update:course", this.item);
        this.$emit("change", this.item);
      }
    },
    campus: function () {
      if (this.campus) {
        this.search("");
        this.item = null;
        this.$emit("update:course", null);
        this.$emit("change", null);
      }
    },
  },
  created: function () {
    this.addItemToCourses();
  },
  methods: {
    addItemToCourses() {
      if (!this.item) return;

      let found = false;
      let item = this.item;
      this.courses = [];
      this.courses.forEach(function (course) {
        if (item.id == course.id) found = true;
      });
      if (!found) this.courses.push(item);
    },
    getName(item) {
      if (item.code && item.description) {
        // let status = "Open";
        // if (!item.status) { status = "Closed"; }

        let course = item.code + " - " + item.description;

        if (item.major) { course += " major in " + item.major + ""; }

        // course += " (" + status + ") ";

        return course;
      }

      return "";
    },
    search(queryString) {
      let params = { term: queryString, per_page: 25 };
      if (this.campus) params.campus = this.campus;
      Course.get({ params: params })
        .then((result) => {
          let links = this.gatherItems(result.data.data);
          this.courses = links;
        })
        .catch((error) => {
          console.log(error);
          this.courses = [];
        });
    },
    gatherItems(items) {
      const self = this;
      items.forEach((item) => {
        item.value = self.getName(item);
      });
      return items;
    },
  },
};
</script>
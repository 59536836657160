<template>
  <div>
    <el-card>
      <h2>Users</h2>
      <div class="add-btn right">
        <el-button type="success" size="medium" @click="addForm()">Add User</el-button>
      </div>

      <el-table :data="users" stripe border style="width: 100%" size="small">
        <el-table-column prop="id" label="ID" width="70"></el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="email" label="Email"></el-table-column>
        <!-- <el-table-column prop="campus" label="Campus"></el-table-column> -->
        <el-table-column prop="role" label="Role"></el-table-column>
        <el-table-column prop="is_active" label="Is Active?">
          <template slot-scope="scope" >
            <b v-if="scope.row.is_active == true" style="color:#67C23A">YES</b>
            <b v-if="scope.row.is_active == false" style="color:#F56C6C">NO</b>
          </template>
        </el-table-column>
        <el-table-column prop="receive_email_notifications" label="Receive Email Notifications?">
          <template slot-scope="scope" >
            <b v-if="scope.row.receive_email_notifications == true" style="color:#67C23A">YES</b>
            <b v-if="scope.row.receive_email_notifications == false" style="color:#F56C6C">NO</b>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="Operations"
          width="120">
          <template slot-scope="scope">
            <el-button style="width: 80%; padding: 4px" @click="editUser(scope.row)" type="warning" size="small"><i class="el-icon-edit"></i> Edit</el-button><br>
            <!-- <el-button style="width: 80%; padding: 4px" v-if="scope.row.role != 'admin'" @click="deleteUser(scope.row)" type="danger" size="small"><i class="el-icon-delete"></i> Delete</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <div style="justify-content: center; align-items: center;display: flex;">
        <el-pagination
          class="margin-top-20"
          background
          layout="prev, pager, next"
          :total="meta.total"
          :page-size="meta.per_page"
          @current-change="pageChanged"
          :current-page="meta.page">
        </el-pagination>
      </div>
      

      <el-dialog title="User" width="40%" :visible.sync="showDialog" v-if="showDialog" :destroy-on-close="true" class="responsive-dialog">
        <user-component :selected="selected" @saved="userSaved($event)"></user-component>
      </el-dialog>
    </el-card>
  </div>

</template>

<style>
  .right {
    text-align: right;
  }

  .add-btn {
    margin-bottom: 1rem;
  }
</style>

<script>
import { User } from "@/api_services/user";
import UserComponent from './UserComponent.vue';

export default {
  components: { UserComponent },
  name: 'Users',
  data() {
    return {
      users: [],
      meta: {
        page: 1,
        pages: 1,
        total: 211,
        per_page: 15,
      },
      loading: false,
      showDialog: false,
      selected: null
    }
  },
  created() {
    this.getUsers();
  },
  methods: {
    pageChanged(val) {
      this.meta.page = val;
      this.getUsers();
    },
    editUser(user) {
      this.selected = Object.assign({}, user);
      this.showDialog = true;
    },
    deleteUser(user) {
      if (user.entries > 0) { return; }
      if (confirm('Are you sure you want to delete this user?')) {

        User.delete(user.id, {}).then((response) => {
          this.getUsers();
        }).catch((error) => {
          if (error.response.data.message) {
            this.$message({
              message: error.response.data.message,
              type: "error",
              duration: 3000,
            });
          }
        });
      }
    },
    addForm() {
      this.showDialog = true;
      this.selected = null;
    },
    userSaved(data) {
      this.showDialog = false;
      this.getUsers();
    },
    getUsers() {
      this.selected = null
      User.get({
        params: { page: this.meta.page, term: '' },
      }).then((result) => {
        this.users = result.data.data;

        this.users.forEach(user => {
          user.is_active = user.is_active == 1 ? true : false
          user.receive_email_notifications = user.receive_email_notifications == 1 ? true : false
        });

        console.log(this.users)
        this.meta = {
          page: result.data.meta.current_page,
          total: result.data.meta.total,
          per_page: result.data.meta.per_page,
          pages: result.data.meta.last_page,
        };
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.users = [];
      });
    }
  }
}
</script>

<template>
  <div id="app" >
    <div v-if="not_found" style="text-align: center;display: flex;
  justify-content: center;">
      <div  style="padding-top:50px;width: 320px; margin: 0 auto;">
        <div >
          <i class="el-icon-warning" style="font-size: 100px"></i>
        </div>
        <p style="font-size: 30px">Page not found</p>
        <back-to-home />
      </div>
    </div>
    <router-view> 

    </router-view>
<!-- 
    <app-footer></app-footer> -->
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {},
   data() {
    return {
      not_found: false
    }
  },
  watch: {
    $route (to, from){
      this.checkRoute()
    }
  },
  created: function() {
    this.checkRoute()
  },
  methods: {
    checkRoute() {
      if (!this.$router.currentRoute.name) {
        this.not_found = true
      }
    }
  }
}
</script>
<style scoped >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  position: relative;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

  @import './assets/styles/theme.css';

</style>

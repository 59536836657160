<template>
  <el-drawer
      :visible.sync="open"
      :with-header="true"
      direction="ltr"
      size="300px"
      class="dark-green-drawer"
      text-color="#031a02"
    >
      <el-menu
          mode="vertical"
        :default-active="activeIndex"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#ffffff"
        text-color="#031a02"
        active-text-color="#031a02">

        <el-menu-item index="transactions" @click="goToPage('transactions')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
          <i style="color:black" class="el-icon-tickets"></i>
          <span>All Transactions</span>
        </el-menu-item>
        <el-menu-item index="completed" @click="goToPage('completed')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
          <i style="color:black" class="el-icon-s-order"></i>
          <span>Completed & Rejected Requests</span>
        </el-menu-item>
        <!-- <el-menu-item index="fees" @click="goToPage('fees')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
          <i style="color:black" class="el-icon-s-cooperation"></i>
          <span>Documents & Fees</span>
        </el-menu-item>
        <el-menu-item index="courses" @click="goToPage('courses')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
          <i style="color:black" class="el-icon-office-building"></i>
          <span>Course & Course In-charge</span>
        </el-menu-item> -->
        <!-- <el-menu-item index="excludeddates" @click="goToPage('excludeddates')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
          <i style="color:black" class="el-icon-date"></i>
          <span>Excluded Dates</span>
        </el-menu-item> -->
        <!-- <el-menu-item index="users" @click="goToPage('users')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
          <i style="color:black" class="el-icon-user-solid"></i>
          <span>Users</span>
        </el-menu-item> -->
        <el-submenu index="results" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
          <template slot="title">
            <i style="color:black" class="el-icon-s-tools"></i>
            <span>Settings</span>
          </template>
            <el-menu-item index="fees" @click="goToPage('fees')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
              <i style="color:black" class="el-icon-money"></i>
              <span>Documents & Fees</span>
            </el-menu-item>
            <el-menu-item index="courses" @click="goToPage('courses')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
              <i style="color:black" class="el-icon-office-building"></i>
              <span>Course & Course In-charge</span>
            </el-menu-item>
            <el-menu-item index="users" @click="goToPage('users')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
              <i style="color:black" class="el-icon-user"></i>
              <span>Users</span>
            </el-menu-item>
            <el-menu-item index="excludeddates" @click="goToPage('excludeddates')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
              <i style="color:black" class="el-icon-date"></i>
              <span>Excluded Dates / Holidays</span>
            </el-menu-item>
            <el-menu-item index="portalconfiguration" @click="goToPage('portalconfiguration')" v-if="$store.state.savedUser && $store.state.savedUser.role === 'admin'">
              <i style="color:black" class="el-icon-setting"></i>
              <span>Portal Configuration</span>
            </el-menu-item>
        </el-submenu>

      </el-menu>
  </el-drawer>
  </template>
  
  <script>
  
  export default {
    name: 'Sidebar',
    props:['drawer'],
    data() {
      return {
          activeIndex: '',
      }
    },
    computed: {
      open: {
        get(){
            return this.drawer
        },
        set(value){
            this.$emit('update', value)
            return value
        } 
      }
    },
    watch: {
      $route (to, from){
        this.activeIndex = to.name
      }
    },
    created: function() {
        this.activeIndex = this.$route.name
    },
    methods: {
      checkRoute(name) {
  
      },
        handleOpen(key, keyPath) {
          // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
          // console.log(key, keyPath);
        },
        goToPage(name) {
          const routes = this.$router.options.routes
          const selectedRoute = this.checkRoutes(routes, name)
          if (selectedRoute && this.$router.currentRoute.name !== name) {
            this.$router.push({name: name})
          }
          this.open = false
        },
        checkRoutes(routes, name) {
            const self = this
            let selectedRoute = null
              routes.forEach(function(route){
                  if (route.name == name) {
                      selectedRoute = route
                  } else {
                      if (route.children) {
                          selectedRoute = self.checkRoutes(route.children, name)
                      }
                  }
              })
  
              return selectedRoute
        }
    }
  }
  </script>
  
  <style scoped>
    .el-drawer__header {
      color: #031a02 !important;
    }
    .is-active {
          background-color: #9d9d9d !important;
    }
  </style>
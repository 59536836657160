<template>
  <div class="course">
    <el-form
      :model="courseForm"
      :rules="rules"
      ref="courseForm"
      label-width="200px"
      class="demo-ruleForm"
    >
      <el-form-item label="Degree Program Code" prop="code">
        <el-input v-model="courseForm.code"></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input v-model="courseForm.description"></el-input>
      </el-form-item>
      <el-form-item label="Major" prop="major">
        <el-input v-model="courseForm.major"></el-input>
      </el-form-item>
      <el-form-item label="In-charge" prop="incharge">
        <el-input v-model="courseForm.incharge"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm">Save</el-button>
        <el-button @click="resetForm">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Course } from "@/api_services/course";
export default {
  name: "CourseComponent",
  props: ["selected"],

  data() {
    return {
      courseForm: {
        code: "",
        description: "",
        // campuses: "",
        major: "",
        status: "",
        quota: ""
      },
      // campuses: this.$store.state.campuses,
      timeStartOpt: {},
      rules: {
        code: [
          { required: true, message: "Please input code", trigger: "blur" },
        ],
        description: [
          {
            required: true,
            message: "Please input description",
            trigger: "blur",
          },
        ],
        // campuses: [
        //   {
        //     required: true,
        //     message: "Please select campuses",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  created() {
    console.log('hree', this.selected);
    if (this.selected) {

      this.courseForm = this.selected;
      // this.courseForm.campuses = this.selected.campuses.split(',');
    }
  },
  methods: {
    resetForm() {
      this.$refs.courseForm.resetFields();
    },
    async submitForm() {
      const valid = await this.$refs.courseForm.validate();
      if (!valid) return;

      // this.courseForm.campuses = this.courseForm.campuses.join(',').toLowerCase();

      
          if (this.selected && this.selected.id) {
           
            this.$confirm('Confirm Update?', {
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              dangerouslyUseHTMLString: true,
              type: 'success'
            }).then(() =>{
               this.update();
            })
            .catch(_ => {

            });
          } else {
            
            this.$confirm('Confirm Saving?', {
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              dangerouslyUseHTMLString: true,
              type: 'success'
            }).then(() =>{
              this.create();
            })
            .catch(_ => {

            });
          }

          
        
    },
    create() {
      Course.create(this.courseForm)
        .then((response) => {
          this.$message({
            message: "Course Saved",
            type: 'success',
            duration: 1500
          });
          this.$emit("saved", response.data.data);
        })
        .catch((error) => {
          console.log(error)
        });
    },
    update() {
      Course.update(this.selected.id, this.courseForm)
        .then((response) => {
          this.$message({
            message: "Course Updated",
            type: 'success',
            duration: 1500
          });
          this.$emit("saved", response.data.data);
        })
        .catch((error) => {
          console.log(error)
        });
    }
  },
};
</script>

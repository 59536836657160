<template>
    <div style="text-align:center">
        <h3> Logging out...</h3>
    </div>
</template>

<script>

import { LocalStorageService } from '@/services/LocalStorageService'


export default {
  name: 'Logout',
  data() {
    return {
     
    }
  },
  created: function() {
    let self = this
    setTimeout(function(){ self.logout() }, 1000);
  },
  methods: {
    logout() {
          this.$store.commit('saveUser',null);
          LocalStorageService.setToken(null)
          LocalStorageService.setUser(null)
         this.$router.push('/login')
    }
  }
  // methods: {
  //   timeOut() {
  //       const self = this
  //        setTimeout(function(res){
  //           self.$router.push('/')
  //        }, 2000);
  //   },
    
  // }
}
</script>

<template>
  <div>   
          
          <h3 style="color: #007a00;">Choose the document(s) to request and specify the number of copies you need.</h3>

          <el-alert
            type="info"
            show-icon
            :closable=false>
            <span style="font-size: small;">The total fees will be calculated based on the quantity of copies and the number of pages produced.</span>
          </el-alert><br>
          <el-form-item prop="selected_docs">
              
            <el-table
              ref="multipleTable"
              border
              :data="request_types"
              style="width: 98%"
              @select="handleSelect"
              :show-checkbox="false"
              size="medium"
              class="hide-select-all-checkbox"
              v-loading="loading">
              <el-table-column
                type="selection"
                >
              </el-table-column>
              <el-table-column
                label="Document"
                >
                <template slot-scope="scope">
                  <div class="custom-cell">
                    {{ scope.row.request_name }}<br>
                    <i v-if="scope.row.description" style="color: #909399; font-size: 13px;"> {{  scope.row.description }} </i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="copies"
                width="110px">
                <template slot-scope="scope">
                  <el-input-number
                    :disabled="!scope.row.is_per_copy || !scope.row.isSelected"
                    v-model="scope.row.count"
                    class="copies-input"
                    controls-position="right"
                    :min="1"
                    :max="10"
                  ></el-input-number>
                </template>
              </el-table-column>
            </el-table>
            <h3 style="color: #007a00;">For certifications, click on the button below and make your selection.</h3>
            <el-collapse class="cert-accordion" accordion>
              <el-collapse-item name="1">
                <template slot="title">
                  <span class="custom-title" style="font-weight: bold;
                  background-color: #909399;
                  padding: 1px 20px 1px 20px;
                  border-radius: 5px;
                  margin: 0px 5px 0px 5px;
                  color: white;">CERTIFICATIONS</span>
                </template>
                <br>
                <el-table
                  ref="multipleTable2"
                  :data="certification_types"
                  style="width: 98%"
                  @select="handleSelect"
                  border
                  size="medium"
                  class="hide-select-all-checkbox">
                  <el-table-column
                    type="selection">
                  </el-table-column>
                  <el-table-column
                    label="Certifications"
                    >
                    <template slot-scope="scope">
                    <div class="custom-cell">
                      {{ scope.row.request_name }}
                    </div>
                  </template>
                  </el-table-column>
                  <el-table-column
                    label="copies"
                    width="110px">
                    <template slot-scope="scope">
                      <el-input-number
                        :disabled="!scope.row.is_per_copy || !scope.row.isSelected"
                        v-model="scope.row.count"
                        class="copies-input"
                        controls-position="right"
                        :min="1"
                        :max="10"
                      ></el-input-number>
                    </template>
                  </el-table-column>
                </el-table>

              </el-collapse-item>
            </el-collapse>

          </el-form-item>
    </div>
  </template>
  
  <script>
  import { RequestType } from '@/api_services/requesttype'

  export default {
    name: 'RequestsListsComponent',
    props: ['form'],
    data() {
      return {
        selectedRowsTable1: [],
        selectedRowsTable2: [],
        mergedSelection: [],
        request_types: [],
        certification_types: [],
        loading: false
      };
    },
    created: function() {
      this.getRequestTypes()
      this.getCertificationTypes()
      // console.log(this.form)
    },
    methods: {
      getRequestTypes() {
        this.loading = true
        RequestType.get({
          params: { doc_type: 'DOC'},
        })
          .then((result) => {
            this.loading = false;
            this.setRequestTypes(result.data.data);
          })
          .catch((error) => {
            this.loading = false;
          });
      },
      getCertificationTypes() {
        this.loading = true
        RequestType.get({
          params: { doc_type: 'CERT'},
        })
          .then((result) => {
            this.loading = false;
            this.setCertTypes(result.data.data);
          })
          .catch((error) => {
            this.loading = false;
          });
      },
      setRequestTypes(request_types){
        this.request_types = request_types;
        if (this.form.request_type){
          this.form.request_type_id = this.form.request_type.id;
        }
        this.request_types.forEach(function(request_types){
          request_types.type = 'docs'
          // request_types.count = 1
          request_types.isSelected = false
        })

        // console.log(this.request_types)
      },
      setCertTypes(cert_types){
        this.certification_types = cert_types;
        if (this.form.request_type){
          this.form.request_type_id = this.form.request_type.id;
        }
        this.certification_types.forEach(function(cert_types){
          cert_types.type = 'cert'
          // cert_types.count = 1
          cert_types.isSelected = false
        })
      },
      handleSelect(selection, row){
        row.isSelected = !row.isSelected;

        if (row.type === 'docs') {
          this.selectedRowsTable1 = this.$refs.multipleTable.selection;
        } else if (row.type === 'cert') {
          this.selectedRowsTable2 = this.$refs.multipleTable2.selection;
        }

        this.mergedSelection = [...this.selectedRowsTable1, ...this.selectedRowsTable2];

        // console.log(this.mergedSelection)

        this.$emit('updateMergedSelection', this.mergedSelection);

        row.count = 1;

        // console.log(row)
      },
    }
    
  }
  </script>
  
  <style scoped>
  body {
    background-color: #ffffff!important;
  }
  .request {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 70px;
  }
  .request-button {
    width: 100%;
    margin-top: 20px;
  }
  .copies-label{
    margin-right: 10px;
  }
  .copies-input{
    width: 95%;
    display: flex;
    align-items: center;
  }
  .el-button--primary.is-disabled{
    /* color: #FFF;
    background-color: #a0cfff;
    border-color: #a0cfff; */
    background-color: #ffcc004a !important;
    border-color: #ffcc004a !important;
    color: #0933007d !important;
  }
  .el-button--primary{
    background-color: #ffcc00 !important;
    border-color: #ffcc00 !important;
    color: #093300 !important;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #ffcc00!important;
    border-color: #ffcc00!important;
  }
  /* .request-form {
    width: 50%;
  } */
  </style>
  
  
  